import {operatorIdWidget} from "../../components/FormWidgets";

export let planModalSchema = {
    type: "object",
    required: [
        "planName",
        "operatorName"
    ],
    properties: {
        planId: {
            type: "string",
            title: "Plan ID",
            pattern: "^[0-9a-zA-Z-]*$",
            default: "",
            readOnly: true,
        },
        planName: {
            type: "string",
            title: "Plan Name",
            default: "",
        },

        operatorName: {
            type: "string",
            title: "Operator Name",
            default: "",
        },

        operatorId: {
            type: "string",
            title: "Operator ID",
            default: "",
            readOnly: true,
        },

        planDescription: {
            type: "string",
            title: "Plan Description",
            default: "",
        },

        coverage: {
            type: "string",
            title: "Coverage",
            default: "SELF",
            enum: ["SELF", "WORLDWIDE"],
            readOnly: true,
        },

        datausage: {
            type: "string",
            title: "Coverage",
            default: "UNLIMITED",
            enum: ["UNLIMITED", "LIMITED"],
            readOnly: true,
        },

        price: {
            type: "integer",
            title: "Price ($)",
            default: 0,
            maximum: 100,
        },
        status: {
            type: "string",
            title: "Status",
            enum: ["INACTIVE", "ACTIVE"],
            default: "INACTIVE",
        },
    },
};

export let planModalSuperUISchema = {
    planId: {"ui:options": { 'widget': 'hidden'} },
    operatorName: operatorIdWidget(false)
};

export let planModalSuperEditUISchema = {
    operatorName: operatorIdWidget(true)
};

export let planModalSuperSchema = planModalSchema

export let planModalUISchema = {
    planId: {"ui:options": { 'widget': 'hidden'} },
};
