import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { downloadModalSchema } from "../forms";
import ApiHelper, { customFetch } from "../../../util/ApiHelper";
import validator from "@rjsf/validator-ajv8";
import { ethers, Signature } from "ethers";
import { toast } from "react-toastify";

class DownloadModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    dsimId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    subscribers: PropTypes.array.isRequired,
  };

  state = {
    formData: undefined,
    isTransferring: false,
    rerenderCounter: 0,
    connected: false,
    address: "",
  };

  uiSchema = downloadModalSchema;

  componentDidUpdate(prevProps, prevState, snapshot) {}

  extractData(inputStr) {
    const partBeforeUnderscores = inputStr.split("____")[0];
    return partBeforeUnderscores.replace(/-/g, "");
  }

  getData = (subscriberName) => {
    const regex =
      /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/;
    const matches = subscriberName.match(regex);

    // Check if any match is found and print the first one
    if (matches) {
      return matches[0];
    } else {
      console.log("No UUID found in the string.");
      return "";
    }
  };

  async updateFormData(newData) {
    // Workaround for bug: https://github.com/rjsf-team/react-jsonschema-form/issues/758
    await this.setState({ rerenderCounter: this.state.rerenderCounter + 1 });
    await this.setState({
      rerenderCounter: this.state.rerenderCounter + 1,
      formData: newData,
    });
  }

  async onSubmitAsync(formData) {
    const signature = await this.signMessage(
      this.props.imsi,
      formData.subscriber,
    );

    // Check if signature was rejected or failed
    if (!signature) {
      return; // Exit if the signature was not obtained
    }

    const subscriberId = this.getData(formData.subscriber);

    if (subscriberId === "") {
      alert("Invalid subscriber");
      return;
    }

    await this.setState({ isTransferring: true });

    const response = await customFetch(
      `/downloadtoken/${this.props.dsimId}/${subscriberId}`,
      "PUT",
    );

    await this.setState({ isTransferring: false });

    if (response.status !== 200) {
      alert("Error: " + response.statusText);
      return;
    }

    this.props.setOpen(false);
    alert("Token successfully downloaded");
  }

  async signMessage(imsi, subscriber) {
    const deploymentInfo = await customFetch(
      `/deployment-info?contractName=${"BloxtelManager"}`,
    );
    if (!deploymentInfo) {
      toast.error(
        "Error: Could not fetch deployment info. Please try again later.",
      );
    }

    const BLOXTEL_MANAGER_ADDRESS = deploymentInfo.address;
    const CHAIN_ID = deploymentInfo.chainId;

    // Define the types for EIP712 signing
    const types = {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      MintdSIMNFT: [
        { name: "imsi", type: "string" },
        { name: "subscriber", type: "string" },
        { name: "description", type: "string" },
      ],
    };

    if (!window.ethereum) {
      toast.error("Web 3 wallet not detected. Please install a Web 3 wallet.");
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      // Prepare the message to sign using the encoded function call and nonce
      const message = {
        imsi: imsi,
        subscriber: subscriber,
        description: `Authorize the transferring of 1 dSIM NFT token with ID ${imsi} to subscriber ${subscriber}`,
      };

      // Define the EIP712 domain (handled from .env)
      const domain = {
        name: "BloxtelManager",
        version: "1",
        chainId: parseInt(CHAIN_ID), // Chain ID from .env
        verifyingContract: BLOXTEL_MANAGER_ADDRESS, // EntryPoint address from .env
      };

      // Prepare the typed data for signing
      const typedData = {
        domain,
        types,
        primaryType: "MintdSIMNFT", // This is key to avoid ambiguity
        message,
      };

      const params = [await signer.getAddress(), JSON.stringify(typedData)];
      const signature = await window.ethereum.request({
        method: "eth_signTypedData_v4",
        params: params,
        from: await signer.getAddress(),
      });

      const parsedSignature = Signature.from(signature);

      // Prepare the data to send to the backend
      const requestData = {
        from: await signer.getAddress(),
        imsi: imsi,
        susbcriber: subscriber,
        v: parsedSignature.v,
        r: parsedSignature.r,
        s: parsedSignature.s,
      };

      return parsedSignature;
    } catch (error) {
      console.error("Error signing message", error);
    }
  }

  onSubmitClick(result) {
    const formData = result.formData;

    this.onSubmitAsync(formData);
  }

  async onChange(data) {}

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Transfer to a subscriber</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.isTransferring && <div className="sp loading" />}
          {this.state.rerenderCounter % 2 === 0 &&
            !this.state.isTransferring && (
              <Form
                schema={this.props.schema}
                uiSchema={this.uiSchema}
                formData={this.state.formData}
                onChange={this.onChange.bind(this)}
                onSubmit={this.onSubmitClick.bind(this)}
                validator={validator}
              />
            )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(DownloadModal));
