import { motion } from "framer-motion";

const Spinner = ({ size = "40px", color = "border-blue-500" }) => {
  return (
    <div className="flex justify-center items-center">
      <motion.div
        style={{ width: size, height: size }}
        className={`border-4 border-t-transparent rounded-full animate-spin ${color}`}
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
      />
    </div>
  );
};

export default Spinner;
