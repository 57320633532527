// Language: JavaScript with React

import React, { useEffect, useState } from "react";
import { customFetch } from "../../util/ApiHelper";

// List of lognames to display
const LOG_NAMES = [
  "amf",
  "udm",
  "ausf",
  "pcf",
  "upf",
  "smf",
  "nssf",
  "nrf",
  "udr",
  "chf",
];

// Component for each collapsible log item
const LogItem = ({ logName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [logContent, setLogContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAPStatus = async () => {
    const log = await customFetch(`/aplog/${logName}`);
    if (log) {
      setLogContent(log.messages);
    }
  };

  const toggle = async () => {
    // If opening and content hasn't been fetched yet, then fetch it
    if (!isOpen && !logContent) {
      setLoading(true);
      setError(null);
      try {
        await fetchAPStatus();
      } finally {
        setLoading(false);
      }
    }
    setIsOpen(!isOpen);
  };

  let interval;

  useEffect(() => {
    if (isOpen) {
      interval = setInterval(fetchAPStatus, 10000);
      return () => clearInterval(interval);
    } else {
      clearInterval(interval);
    }
  }, [isOpen]);

  return (
    <div
      className="log-item"
      style={{
        marginBottom: "1rem",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      <div
        className="log-header"
        onClick={toggle}
        style={{ padding: "0.5rem", cursor: "pointer" }}
      >
        <h3 style={{ margin: 0 }}>{logName.toUpperCase()} Log</h3>
      </div>
      {isOpen && (
        <div
          className="log-content"
          style={{ padding: "0.5rem", backgroundColor: "#fff" }}
        >
          {loading && <p>Loading...</p>}
          {error && <p style={{ color: "red" }}>Error: {error}</p>}
          {!loading && !error && (
            <pre style={{ whiteSpace: "pre-wrap" }}>{logContent}</pre>
          )}
        </div>
      )}
    </div>
  );
};

// Main component rendering all the log items
const APLogViewer = () => {
  const [ap, setAp] = useState("");

  useEffect(() => {
    const apid = customFetch("/apid");
    if (apid) {
      setAp(apid.APID);
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <h4>Access Logs for Access Point {ap}</h4>
              <p>
                The log messages are only displayed for the currently serving
                access point of the UI. Use the serial number of the access
                point as the hostname to get the specific log messages for that
                access point.
              </p>
            </div>
            <div className="content overview__content">
              {LOG_NAMES.map((name) => (
                <LogItem key={name} logName={name} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APLogViewer;
