import React from 'react';
import { useHistory} from 'react-router-dom';
// This is overly complex and need to be refactored or rewrote in some way
// The main issues comes from preact-router not handling relative links properly
// So we need to rebuild the path from the root ("/")
// Handling both search / browse at the same time is also challenging

export const Path = ({ fileInfo, currentDir }) => {
  const history = useHistory();
  // a Path can represent multiple sub-directories during search
  console.error("fileinfo",fileInfo.path);
  const splitPath = fileInfo.path.split("/");

  const onFileClick = () => {
    history.push(`/gateways/log_${fileInfo.path}`);
  }

  if (splitPath.length === 1) {
    // browse
    if (fileInfo.isDir) {
      return <span className='folder'>{fileInfo.path}</span>;
    }
    return <span onClick={onFileClick} className='file'>{fileInfo.path}</span>;
  }

  // search
  return (
    <>
      {splitPath
        .map((val, i) => {
          if (i === splitPath.length - 1 && !fileInfo.isDir) {
            return <span key={i}>{val}</span>;
          }
          const target =
            currentDir === ""
              ? `${splitPath.slice(0, i + 1).join("/")}/`
              : `/${currentDir}/${splitPath.slice(0, i + 1).join("/")}/`;
          return (
            <a key={i} href={target}>
              {val}
            </a>
          );
        })
        .reduce(
          (acc, val) => (acc === null ? [val] : [...acc, " / ", val]),
          null
        )}
    </>
  );
};

export const DoubleDotPath = ({ currentDir }) => {
  const split = currentDir.split("/");
  split.slice(0, split.length - 1).join("/");
  return <a>..</a>;
};
