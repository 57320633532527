import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from 'prop-types';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { operatorNewModalSchema, operatorModalSchema, operatorModalUISchema } from '../../../metadata/index'
import { CheckoutForm } from '../../Auth/CheckoutForm';
import ApiHelper from '../../../util/ApiHelper';
import { ToastContainer } from 'react-toastify';
import validator from '@rjsf/validator-ajv8';
import { UriWidget } from './UriWidget';
import "./UriWidget.css";

const widgets = {
  UriWidget: UriWidget
};

class OperatorModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    operator: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    blockchainData: {
      blockchainAddress: '',
    },
    accountBalance: 0,
    explorerAccountUrl: ''
  };

  uiSchema = operatorModalUISchema;

  async setStripePromise() {
    const res = await ApiHelper.getStripePublicKey();
    this.setState({ stripePromise: loadStripe(res?.publicKey) });
  }

  componentDidMount() {
    this.setStripePromise();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.operator });

      if (this.props.operator) {
        const operator = this.props.operator;  

        let formData = {
          operatorId: operator['operatorId'],
          businessName: operator['businessName'],
          businessAddress: operator['businessAddress'],
          businessIndustry: operator['businessIndustry'],
          status: operator['status'],
          operatorLogo: operator['operatorLogo'],
          createdDate: operator["createdDate"],
          modifiedDate: operator["modifiedDate"],
          subscriptionType: operator["subscriptionType"],
          subscriptionStatus: operator["subscriptionStatus"],
          evmAddress: operator['evmAddress']
        };

        this.updateData(formData, null, null).then();
      } else {
        let formData = {
          operatorId: "",
          operatorName: "",
          businessIndustry: "Accounting"
        };
        this.updateData(formData, {}, "").then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;

    if (lastData && lastData.operatorId === undefined)
      lastData.operatorId = "";
  }

  async updateData(formData, blockchainData, explorerAccountUrl) {
    await this.setState({
      formData: formData,
      blockchainData: blockchainData,
      explorerAccountUrl: explorerAccountUrl
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    let operatorData = {
      "operatorId": formData["operatorId"],
      "businessName": formData["businessName"],
      "businessAddress": formData["businessAddress"],
      "businessIndustry": formData["businessIndustry"],
      "email": this.props.operator["email"],
      "password": this.props.operator["password"],
      "status": formData["status"],
      // "operatorLogo": formData["operatorLogo"],
      "createdDate": formData["createdDate"],
      "modifiedDate": formData["modifiedDate"],
      "subscriptionType": formData["subscriptionType"],
      "subscriptionStatus": formData["subscriptionStatus"],
    };

    if (this.state.editMode) {
      this.props.onModify(operatorData);
    } else {
      this.props.onSubmit(operatorData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"operator-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "View Operator" : "New Operator"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ToastContainer />
              {this.state.editMode &&
                <Form schema={this.state.editMode ? operatorModalSchema : operatorNewModalSchema}
                  uiSchema={this.uiSchema}
                  formData={this.state.formData}
                  widgets={widgets}
                  onChange={this.onChange.bind(this)}
                  onSubmit={this.onSubmitClick.bind(this)}
                  validator={validator} />
              }
              {!this.state.editMode && this.state.stripePromise &&
                <div className="Login Login__admin">
                  <div className="LoginFormContent LoginFormContent__admin LoginFormContent__noBackgroundColor">
                    <div className="LoginForm LoginForm__admin">
                      <Elements stripe={this.state.stripePromise}>
                        <CheckoutForm isAdmin={true} onClose={this.props.setOpen.bind(this, false)} />
                      </Elements>
                    </div>
                  </div>
                </div>
              }
        </Modal.Body>      
      </Modal>
    );

  }
}

export default OperatorModal;
