import Serializable from "./Serializable";

export default class User extends Serializable {
  lastName = "";
  id = "";
  email = "";
  operatorId = "";
  role = "";
  status = "";
  accessToken = "";
  walletAddress = "";
  walletIsCustodial = false;
  username = ";";

  constructor(
    firstName,
    lastName,
    id,
    email,
    operatorId,
    role,
    status,
    accessToken,
    walletAddress,
    walletIsCustodial,
  ) {
    super();
    this.firstName = firstName;
    this.lastName = lastName;
    this.id = id;
    this.email = email;
    this.operatorId = operatorId;
    this.role = role;
    this.status = status;
    this.accessToken = accessToken;
    this.walletAddress = walletAddress;
    this.walletIsCustodial = walletIsCustodial;
    this.username = `${firstName} ${lastName}`;
  }
}
