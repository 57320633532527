import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { customFetch } from "../../../util/ApiHelper";
import Spinner from "../../../components/ui/Spinner";

const dSIMSyncModal = ({ open, setOpen }) => {
  const [pending, setPending] = useState(false);

  const handleSubmit = async (e) => {
    setPending(true);
    try {
      await customFetch("/dsim/sync", "POST");
      setOpen(false);
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal
      show={open}
      className={"fields__edit-modal theme-light"}
      backdrop={"static"}
      onHide={setOpen.bind(this, false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Sync with Cloud
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p style={{ marginBottom: "10px" }}>
          Do you want to sync with the cloud?
        </p>
        <Button variant="primary" disabled={pending} onClick={handleSubmit}>
          Sync
        </Button>
        {pending && (
          <div style={{ margin: "20px 0" }}>
            <Spinner size="100px" color="border-green-500" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default dSIMSyncModal;
