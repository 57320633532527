import React, { useState } from "react";
import {
  orderModalSchema,
  orderModalUISchema,
  orderModalSuperSchema,
  orderModalEditSchema,
  orderModalSuperEditSchema,
} from "../forms";
import GenericDataView from "../../../components/GenericDataView";
import { customFetch } from "../../../util/ApiHelper";
import { OperatorNameWidget } from "../../../components/IdToName";

const OrderModal = ({ open, setOpen, data, onModify, onCreate }) => {
  const [operators, setOperators] = useState([]);

  const fetchAdditional = async () => {
    const ops = await customFetch("/operator");
    if (ops) {
      setOperators(ops);
    }
  };

  const handleOnLoad = (data) => {
    data.operatorName =
      operators.filter(
        (operator) => operator.operatorId === data["operatorId"],
      )[0]?.name || "";
  };

  const handleOnChange = (newData, oldData) => {
    if (newData && oldData && oldData.operatorName !== newData.operatorName) {
      const selectedOperator = operators.filter(
        (operator) => operator.name === newData.operatorName,
      );
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
    }
  };

  return (
    <GenericDataView
      dataName="Order"
      open={open}
      onCreate={onCreate}
      onModify={onModify}
      data={data}
      setOpen={setOpen}
      superSchema={orderModalSuperSchema}
      operatorSchema={orderModalSchema}
      editOperatorSchema={orderModalEditSchema}
      editSuperSchema={orderModalSuperEditSchema}
      uiEditSuperSchema={orderModalUISchema}
      uiEditOperatorSchema={orderModalUISchema}
      uiOperatorSchema={orderModalUISchema}
      uiSuperSchema={orderModalUISchema}
      handleDataOnChange={handleOnChange}
      handleDataOnLoad={handleOnLoad}
      fetchDependencies={fetchAdditional}
      customFormFields={OperatorNameWidget}
      readOnly={true}
    />
  );
};

export default OrderModal;
