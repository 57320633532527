import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import WalletModal from "./components/WalletModal";
import ApiHelper from "../../util/ApiHelper";

class WalletOverview extends Component {
  state = {
    walletModalOpen: false,
    walletModalData: null,
  };

  componentDidMount() {
    ApiHelper.fetchWallets().then();
  }

  openAddWallet() {
    this.setState({
      walletModalOpen: true,
      walletModalData: null,
    });
  }

  /**
   * @param walletId  {string}
   */
  async openEditWallet(walletId) {
    const wallet = await ApiHelper.fetchWalletById(walletId);

    this.setState({
      walletModalOpen: true,
      walletModalData: wallet,
    });
  }

  async addWallet(walletData) {
    this.setState({ walletModalOpen: false });

    if (!await ApiHelper.createWallet(walletData)) {
      alert("Error creating new wallet");
    }
    ApiHelper.fetchWallets().then();
  }

  /**
   * @param walletData
   */
  async updateWallet(walletData) {
    this.setState({ walletModalOpen: false });

    const result = await ApiHelper.updateWallet(walletData);

    if (!result) {
      alert("Error updating wallet: " + walletData["ueId"]);
    }
    ApiHelper.fetchWallets().then();
  }

  /**
  * @param wallet  {Wallet}
   */
  async deleteWallet(wallet) {
    if (!window.confirm(`Delete Wallet ${wallet.id}?`))
      return;

    const result = await ApiHelper.deleteWallet(wallet.id);
    ApiHelper.fetchWallets().then();
    if (!result) {
      alert("Error deleting wallet: " + wallet.id);
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <h4>Wallets</h4>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th style={{ width: 400 }}>Wallet ID</th>
                      <th colSpan={2}>Operator ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.wallets.map(wallet => (
                      <tr key={wallet.id}>
                        <td>{wallet.id}</td>
                        <td><font color="blue"><u><Link to={"/users/"+wallet.id}>{wallet.name}</Link></u></font></td>
                        <td style={{ textAlign: 'center' }}>
                          <Button variant="danger" onClick={this.deleteWallet.bind(this, wallet)}>Delete</Button>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="info" onClick={this.openEditWallet.bind(this, wallet.id)}>Modify</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <WalletModal open={this.state.walletModalOpen}
          setOpen={val => this.setState({ walletModalOpen: val })}
          wallet={this.state.walletModalData}
          onModify={this.updateWallet.bind(this)}
          onSubmit={this.addWallet.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  wallets: state.wallet.wallets,
});

export default withRouter(connect(mapStateToProps)(WalletOverview));
