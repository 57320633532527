import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  LayersControl,
  Rectangle,
  useMap,
} from "react-leaflet";
import ApiHelper from "../../util/ApiHelper";
import MeasurementsModal from "./components/MeasurementsModal";
import HexCellsLayer from "./components/HexCellsLayer";
import Select from "react-select";

import iconRedUrl from "../../assets/images/cell_red.png";
import iconGreenUrl from "../../assets/images/cell_green.png";
import iconOrangeUrl from "../../assets/images/cell_orange.png";

export const onlineIcon = new L.Icon({
  iconUrl: iconGreenUrl,
  iconAnchor: [0, 0],
  popupAnchor: [0, 0],
  iconSize: [30, 30],
});

export const offlineIcon = new L.Icon({
  iconUrl: iconRedUrl,
  iconAnchor: [0, 0],
  popupAnchor: [0, 0],
  iconSize: [30, 30],
});

export const temperedIcon = new L.Icon({
  iconUrl: iconOrangeUrl,
  iconAnchor: [0, 0],
  popupAnchor: [0, 0],
  iconSize: [30, 30],
});

const mapDefaultPos = [40.73126130501113, -74.18223703631202];
const zoom = 10;

const rectDefaultBound = [
  [0, 0],
  [0, 0],
];

const getIconByStatus = (status) => {
  if (status === "ONLINE") {
    return onlineIcon;
  }

  if (status === "OFFLINE") {
    return offlineIcon;
  }

  return temperedIcon;
};

const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(
      [lat, lng],
      lat === mapDefaultPos[0] && lng === mapDefaultPos[1] ? 2 : 16
    );
  }, [lat, lng]);
  return null;
};

class MapOverview extends Component {
  state = {
    measurementsModalOpen: false,
    measurementsModalData: null,
    searchString: "",
    cellData: [],
    seconds: 5,
    showGrid: true,
    searchOptions: [],
    mapPos: mapDefaultPos,
  };

  timer = 0;
  countDown = this.countDown.bind(this);
  mapRef = React.createRef();

  async setCellData() {
    const res = await ApiHelper.fetchCellsForMap(
      this.props.user.operatorId
    ).then();
    const seconds = await ApiHelper.getRefreshTime().then();
    let searchValues = [];
    if (res) {
      searchValues = res.map((cell) => ({
        value: `${cell.name}(${cell.gatewayName})`,
        label: `${cell.name}(${cell.gatewayName})`,
        lat: cell.latitude,
        lon: cell.longitude,
      }));
    }

    this.setState({
      cellData: res || [],
      seconds: Number(seconds.refreshTime),
      searchOptions: searchValues,
    });
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  async countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    // this.setState({
    //   seconds: seconds,
    // });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      await this.setCellData();
      this.timer = 0;
      this.startTimer();
    }
  }

  async componentDidMount() {
    this.setCellData();
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  async openMeasurementsModal() {
    const measurementsData = await ApiHelper.fetchMeasurements();
    this.setState({
      measurementsModalOpen: true,
      measurementsModalData: measurementsData,
    });
  }

  onChangeSearch(event) {
    this.setState({
      searchString: event.value,
      mapPos: [Number(event.lat), Number(event.lon)],
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Coverage Map</h4>
                  <div className="overview__searchpanel">
                    {/* <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} /> */}
                    <div className="search_pan">
                      <div className="dropdown-container">
                        <Select
                          placeholder="Search for small cells"
                          options={this.state.searchOptions}
                          isSearchable
                          onChange={this.onChangeSearch.bind(this)}
                          searchString={this.state.searchString}
                        />
                      </div>
                      <span className="search-icon">
                        <button className="serach-btn" type="button">
                          <i className="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <MapContainer
              style={{ width: "100%", height: "calc(100vh - 250px)" }}
              zoom={zoom}
              center={mapDefaultPos}
              scrollWheelZoom={true}
              ref={this.mapRef}
              attributionControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {/* <LeafletControlGeocoder /> */}
              <LayersControl position="bottomright">
                <LayersControl.Overlay checked name="Grids">
                  <Rectangle
                    bounds={rectDefaultBound}
                    pathOptions={{ color: "transparent" }}
                    eventHandlers={{
                      add: (e) => {
                        this.setState({
                          showGrid: true,
                        });
                      },
                      remove: (e) => {
                        this.setState({
                          showGrid: false,
                        });
                      },
                    }}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay name="Satellite">
                  <TileLayer
                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                    maxzoom={20}
                    subdomains={["mt1", "mt2", "mt3"]}
                  />
                </LayersControl.Overlay>
              </LayersControl>
              <HexCellsLayer
                mapData={this.state.cellData}
                visible={this.state.showGrid}
              />
              <MarkerClusterGroup chunkedLoading disableClusteringAtZoom={13}>
                {this.state.cellData.map((cell) => {
                  return (
                    <Marker
                      icon={getIconByStatus(cell.status)}
                      position={[cell.latitude, cell.longitude]}
                      key={cell.uuID}
                      title={cell.name}
                    >
                      <Popup>
                        Name: {cell.name} <br />
                        PLMN: {cell.plmn} <br />
                        LAC: {cell.lac} <br />
                        CID: {cell.cellID} <br />
                        Radio: {cell.radioType} <br />
                        <br />
                        Latitude: {cell.latitude} <br />
                        Longitude: {cell.longitude} <br />
                        Range: 500m <br />
                        <br />
                        <span
                          style={{ color: "dodgerblue", cursor: "pointer" }}
                          onClick={this.openMeasurementsModal.bind(this)}
                        >
                          5 Measurements{" "}
                        </span>
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>
              <RecenterAutomatically
                lat={this.state.mapPos[0]}
                lng={this.state.mapPos[1]}
              />
            </MapContainer>
          </div>
        </div>
        <MeasurementsModal
          open={this.state.measurementsModalOpen}
          setOpen={(val) => this.setState({ measurementsModalOpen: val })}
          measurements={this.state.measurementsModalData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(MapOverview));
