import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import SearchBar from '../../../components/FormInputs/SearchBar';
import Carousel from 'react-grid-carousel'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import testCardImg from "../../../assets/images/test_card.png";
import ApiHelper from "../../../util/ApiHelper";

class CameraControlCenterModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
  };

  state = {
    rerenderCounter: 0,
    searchString: "",
    fullScreen: false,
    aimacServerIP: "",
    aimacServerPort: 0
  };

  async loadServerUrl() {

    const aimacUrl =  await ApiHelper.getAimacServerIP();

    this.setState({
      // aimacServerUrl: `http://${aimacUrl.serverIP}:${aimacUrl.serverPort}`,
      aimacServerIP: aimacUrl.serverIP,
      aimacServerPort: parseInt(aimacUrl.serverPort)
    })
  }

  componentDidMount() {
    this.loadServerUrl();
  }

  onCameraClick(uuid) {
    const cameraVideo = document.getElementById(uuid);
    if(cameraVideo) {
      if (cameraVideo.webkitSupportsFullscreen) {
        cameraVideo.webkitEnterFullscreen();
        return;
      }
    
      cameraVideo.requestFullscreen();
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  onFullScreenClick() {
    this.setState({ fullScreen: !this.state.fullScreen });
  }

  render() {
    const filteredCameras = this.props.cameras.filter(camera =>
      camera.label.includes(this.state.searchString) ||
      camera.description.includes(this.state.searchString)
    );

    const defaultLenth = filteredCameras.length > 3 ? 0 : (4 - filteredCameras.length);

    return (
      <Modal
        show={this.props.open}
        className={`camera-control-modal ${this.state.fullScreen ? 'camera-control-modal__active' : ''} theme-light`}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <div className='header-content'>
            <Modal.Title id="example-modal-sizes-title-lg">Control Center</Modal.Title>
            <div onClick={this.onFullScreenClick.bind(this)} className='extra-btn'>
              <i className="fas fa-window-maximize"/>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header overview__header">
                    <div className="overview__searchbar">
                      <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                    </div>
                  </div>
                  <div className="content camera-contents">
                    <Carousel
                      cols={this.state.fullScreen ? 4 : 2}
                      rows={2}
                      gap={30}
                      responsiveLayout={[
                        {
                          breakpoint: 1530,
                          cols: this.state.fullScreen ? 3 : 2,
                        },
                        {
                          breakpoint: 1180,
                          cols: 2
                        }
                      ]}
                      loop
                    >
                      {filteredCameras.map((camera)=> {
                        // const camUrl = camera.url;

                        let channel = camera.channel;
                        let key = camera.key;

                        // const urlArray = camUrl.split("/");

                        // if (urlArray.length > 4) {
                        //   channel = urlArray[3];
                        //   key = urlArray[4];
                        // }

                        const streamUrl = `http://${this.state.aimacServerIP}:${this.state.aimacServerPort}/${channel}/${key}`;

                        return (
                          <Carousel.Item key={camera.uuid}>
                            <div className='sub-item'>
                              <div onClick={this.onCameraClick.bind(this, camera.uuid)} className='transparent-div' />
                              <div className='stream-div'>
                                <object data={streamUrl} id={camera.uuid} aria-labelledby={camera.uuid} width="100%" height="230" alt="Cam streaming" />
                              </div>
                              {/* <div className='empty-text-div'>
                                <span className='empty-text'>Empty Stream</span>
                              </div> */}
                              {/* <div className='video-div'>
                                <img width="100%" src={testCardImg} />
                              </div> */}
                              <div className='label-div'>
                                <div>{camera.label}</div>
                                &nbsp;
                                {camera.description && (
                                  <span>({camera.description})</span>
                                )}
                              </div>
                            </div>
                          </Carousel.Item>
                        );
                      })}
                      {[...Array(defaultLenth)].map((e, i) => (
                        <Carousel.Item key={i}>
                          <div className='sub-item'>
                            <div className='transparent-div'/>
                            <div className='empty-text-div'>
                              <span className='empty-text'>Empty Stream</span>
                            </div>
                            <img width="100%" alt="" src={testCardImg} />
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  cameras: state.camera.cameras,
});

export default withRouter(connect(mapStateToProps)(CameraControlCenterModal));;
