import React from 'react';

const Icon = ({ name, title, onClick }) => (
  <i
    style={{cursor: "pointer", color: name.includes("folder") ? "goldenrod" : ""}}
    className={name}
    onClick={onClick}
    title={title}
    aria-label={title}
  />
);

const bigIconStyle = {
  display: "inline-block",
  cursor: "pointer",
  "--ggs": 3,
};

export const BigIcon = ({ name, title, onClick }) => (
  <i
    style={bigIconStyle}
    class={`gg-${name}`}
    onClick={onClick}
    title={title}
    aria-label={title}
  />
);

export default Icon;
