import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import BlockModal from "./components/BlockModal";
import ApiHelper, { customFetch } from "../../util/ApiHelper";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import { formatDateOnly } from "../../util/AppUtils";
import { toast } from "react-toastify";

const BlockOverview = () => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    blockData: null,
  });
  const [searchString, setSearchString] = useState("");
  const blocks = useSelector((state) => state.block.blocks);

  useEffect(() => {
    ApiHelper.fetchBlocks();
  }, []);

  const handleModalOpen = (blockData = null) => {
    setModalState({
      isOpen: true,
      blockData,
    });
  };

  const handleModalClose = () => {
    setModalState({
      isOpen: false,
      blockData: null,
    });
  };

  const handleAddBlock = async (blockData) => {
    handleModalClose();

    try {
      const success = await ApiHelper.createBlock(blockData);
      if (!success) {
        throw new Error("Failed to create block");
      }
      await ApiHelper.fetchBlocks();
    } catch (error) {
      toast.error("Error creating new block: " + error.message);
    }
  };

  const handleUpdateBlock = async (blockData) => {
    handleModalClose();

    try {
      const success = await ApiHelper.updateBlock(blockData);
      if (!success) {
        throw new Error("Failed to update block");
      }
      await ApiHelper.fetchBlocks();
    } catch (error) {
      toast.error(`Error updating block ${blockData.id}: ${error.message}`);
    }
  };

  const handleDeleteBlock = async (block) => {
    if (!window.confirm(`Delete block ${block.id}?`)) return;

    try {
      const result = await customFetch(`/block/${block.id}`, "DELETE");
      if (!result) {
        throw new Error("Failed to delete block");
      }
      await ApiHelper.fetchBlocks();
    } catch (error) {
      toast.error(`Error deleting block ${block.id}: ${error.message}`);
    }
  };

  const filteredBlocks = blocks.filter(
    (block) =>
      block.id.includes(searchString) || block.hash.includes(searchString),
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <div className="overview__searchbar">
                <h4>Blocks</h4>
                <div className="overview__searchpanel">
                  <SearchBar
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="content overview__content">
              <Table
                className="overview__table"
                striped
                bordered
                condensed
                hover
              >
                <thead>
                  <tr>
                    <th>Height</th>
                    <th style={{ width: 400 }}>Hash</th>
                    <th>Created</th>
                    <th>dSIM Transactions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBlocks.map((block) => (
                    <tr key={block.id}>
                      <td>{block.id}</td>
                      <td>{block.hash}</td>
                      <td>{formatDateOnly(block.createdDate)}</td>
                      <td>{block.transactionsCount}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="info"
                          disabled={block.transactionsCount === 0}
                          onClick={() =>
                            block.transactionsCount > 0 &&
                            handleModalOpen(block)
                          }
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <BlockModal
        open={modalState.isOpen}
        setOpen={(val) => setModalState((prev) => ({ ...prev, isOpen: val }))}
        block={modalState.blockData}
        onModify={handleUpdateBlock}
        onSubmit={handleAddBlock}
      />
    </div>
  );
};

export default withRouter(BlockOverview);
