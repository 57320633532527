// SupportedNetworkSliceWidget.jsx
import React, { useState, useEffect } from "react";
import { customFetch } from "../util/ApiHelper";

const SupportedNetworkSliceWidget = (props) => {
  const { formData, onChange, formContext, required } = props;
  const { data } = formContext;
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const sstMapping = {
    1: {
      short: "eMBB",
      long: "Slice suitable for the handling of 5G enhanced mobile broadband",
      characteristics:
        "Slice suitable for the handling of 5G enhanced mobile broadband",
      type: "standardized",
    },
    2: {
      short: "URLLC",
      long: "Slice suitable for the handling of ultra-reliable low latency communications",
      characteristics:
        "Slice suitable for the handling of ultra-reliable low latency communications",
      type: "standardized",
    },
    3: {
      short: "MIoT",
      long: "Slice suitable for the handling of massive IoT",
      characteristics: "Slice suitable for the handling of massive IoT",
      type: "standardized",
    },
    4: {
      short: "V2X",
      long: "Slice suitable for the handling of V2X services",
      characteristics: "Slice suitable for the handling of V2X services",
      type: "standardized",
    },
    5: {
      short: "HMTC",
      long: "Slice suitable for the handling of high-performance machine-type communications",
      characteristics:
        "Slice suitable for the handling of high-performance machine-type communications",
      type: "standardized",
    },
  };

  const sliceCategories = {
    standardized: {
      range: "1-5",
      description: "Standardized values - only SST, no SD",
    },
    reserved: {
      range: "6-127",
      description: "Reserved for future standardized values",
    },
    operatorSpecific: {
      range: "128-255",
      description:
        "Non-standardized values - SST with or without SD, only to be used within the network that issued the S-NSSAI",
    },
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const slices = await customFetch("/supportednetworkslices");
      try {
        if (slices) {
          setOptions(slices);
          // Set default value if none is selected and we have options
          if (
            (!data || !data.networkSlice.sst || !data.networkSlice.sd) &&
            slices &&
            slices.length > 0
          ) {
            const defaultOption = slices[0];
            onChange({
              sst: parseInt(defaultOption.SST, 10),
              sd: defaultOption.SD,
              dnn: defaultOption.DNN,
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleSelect = (e) => {
    const selectedIndex = e.target.value;
    const selectedOption = options[selectedIndex];
    if (selectedOption) {
      const updatedFormData = {
        ...formData,
        sst: parseInt(selectedOption.SST, 10),
        sd: selectedOption.SD,
        dnn: selectedOption.DNN,
      };
      onChange(updatedFormData);
    } else {
      onChange({
        sst: options.length > 0 ? parseInt(options[0].SST, 10) : 0,
        sd: options.length > 0 ? options[0].SD : "",
        dnn: options.length > 0 ? options[0].DNN : "",
      });
    }
  };

  const currentIndex = (options ?? []).findIndex((opt) => {
    if (!formData || !formData.sst || !formData.sd) return false;
    return (
      parseInt(opt.SST, 10) ===
        (typeof formData.sst === "number"
          ? formData.sst
          : parseInt(formData.sst, 10)) && opt.SD === formData.sd
    );
  });

  return (
    <div className="network-slice-widget">
      <label className="control-label">
        Network Slice{required && <span className="required">*</span>}
      </label>
      <select
        onChange={handleSelect}
        value={currentIndex >= 0 ? currentIndex : ""}
        className="form-control"
        disabled={isLoading}
      >
        {isLoading ? (
          <option value="">Loading network slices...</option>
        ) : (
          <>
            <option value="" className="p-2">
              Select Network Slice
            </option>
            {options.map((option, index) => {
              const sstValue = parseInt(option.SST, 10);
              const mapping = sstMapping[sstValue];
              const sstDisplay = mapping ? mapping.short : option.SST;
              const category =
                sstValue <= 5
                  ? "standardized"
                  : sstValue <= 127
                    ? "reserved"
                    : "operatorSpecific";

              return (
                <option
                  className="p-2"
                  key={index}
                  value={index}
                  title={mapping?.characteristics || ""}
                >
                  SST: {sstDisplay} - SD: {option.SD}
                </option>
              );
            })}
          </>
        )}
      </select>
      <span className="text-base text-gray-500 mx-2">
        {formData?.sst ? (
          <>
            <div className="font-semibold mb-1">
              {sstMapping[formData.sst]?.characteristics ||
                (formData.sst >= 128
                  ? "Operator specific"
                  : formData.sst >= 6
                    ? "Reserved"
                    : "")}
            </div>
            <div className="text-sm">
              S-NSSAI: SST({formData.sst}) SD({formData.sd})
            </div>
          </>
        ) : null}
      </span>
      <label className="control-label" htmlFor="root_dnn">
        Data Network Name (APN)
      </label>
      <input
        id="root_dnn"
        name="root_dnn"
        className="form-control"
        readOnly
        placeholder=""
        type="text"
        value={formData?.dnn || data?.dnn}
      />
    </div>
  );
};

export default SupportedNetworkSliceWidget;
