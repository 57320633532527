import React from 'react';
import {Route} from 'react-router-dom';
import GatewayOverview from "./GatewayOverview";

const Gateways = ({match}) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={GatewayOverview} />
  </div>
);

export default Gateways;
