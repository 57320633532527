import React, { useEffect } from 'react';
import { Modal, Table } from "react-bootstrap";

const LoggingInfoModal = ({
  open,
  setOpen,
  loggingData,
  serverURL
}) => {

  if (loggingData) {
    console.error("data url", loggingData, `${loggingData.imageURL}`);
  }

  if (!loggingData) {
    return (
      <Modal
        show={open}
        className={"device-modal theme-light"}
        backdrop={"static"}
        onHide={() => setOpen(false)}>
      </Modal>
    );
  }

  const MainInfoField = ({title, value}) => {
    return (
      <div className="device-modal__content__maininfo__field">
        <div className="device-modal__content__maininfo__field__title">
          {title}
        </div>
        <div className="device-modal__content__maininfo__field__value">
          {value}
        </div>
      </div>
    )
  };

  const LANHostsTable = ({hostsData}) => {
    const hosts = [];
    const keyArray = Object.keys(hostsData);
    keyArray.forEach((key) => {
      if (!isNaN(key) && !isNaN(parseFloat(key))) {
        hosts.push(hostsData[key]);
      }
    })

    return (
      <Table className="overview__table" striped bordered condensed hover>
        <thead>
          <tr>
            <th>Host name</th>
            <th>IP address</th>
            <th>MAC address</th>
          </tr>
        </thead>
        <tbody>
          {hosts.map(data => (
            <tr key={data.HostName._value}>
              {data.HostName._value
                ? <td >{data.HostName._value}</td>
                : <td style={{opacity: 0.5}}>{"blank"}</td>
              }
              <td>{data.IPAddress._value}</td>
              <td>{data.MACAddress._value}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  };

  return (
    <Modal
      show={open}
      className={"device-modal theme-light"}
      backdrop={"static"}
      onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Image/Video</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="device-modal__header">
                  <div className="device-modal__header__title">{loggingData.camera}</div>
                </div>
                <div className="device-modal__content">
                  <div className="media-container">
                    {loggingData.imageURL && (
                      <div className="content">
                        <h4>Image</h4>
                        <img
                            src={loggingData.imageURL}

                            alt={`Event ${loggingData.EventNumber}`}
                        />
                      </div>
                    )}
                    {loggingData.videoURL && (
                      <div className="content">
                        <h4>Video</h4>
                        <video controls>
                          <source src={loggingData.videoURL} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoggingInfoModal;
