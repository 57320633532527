import React, { useEffect } from "react";
import GenericDataList from "../../components/GenericDataList";
import { customFetch } from "../../util/ApiHelper";

// A simple status indicator for rendering cell statuses
const StatusIndicator = ({ isActive, label }) => (
  <div className="d-flex align-items-center">
    <span
      className="status-indicator"
      style={{
        display: "inline-block",
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: isActive ? "#4CBB17" : "#CD5C5C",
        marginRight: "8px",
      }}
      title={isActive ? "Active" : "Inactive"}
    />
    {label}
  </div>
);

const APStatus = () => {
  const fetchAPStatus = async () => {
    return await customFetch("/apStatus");
  };

  useEffect(() => {
    const interval = setInterval(fetchAPStatus, 10000);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    { header: "Access Point Serial Number", field: "APID", searchable: true },
    { header: "Number of connected UEs", field: "numUEs" },
    {
      header: "Cell Status",
      render: (item) => (
        <StatusIndicator
          isActive={item.connected}
          label={item.connected ? "Connected" : "Disconnected"}
        />
      ),
    },
    {
      header: "GNSS Status",
      render: (item) => (
        <StatusIndicator
          isActive={item.gNSSConnected}
          label={item.gNSSConnected ? "Available" : "Unavailable"}
        />
      ),
    },
    {
      header: "Core Status",
      render: (item) => (
        <StatusIndicator
          isActive={item.coreConnected}
          label={item.coreConnected ? "Connected" : "Disconnected"}
        />
      ),
    },
  ];

  return (
    <GenericDataList
      fetchAllData={fetchAPStatus}
      columns={columns}
      dataName="AP Status"
      allowDelete={false}
      allowAdd={false}
      noDetails={true}
      noActions={true}
      notFoundText="No AP status available"
    />
  );
};

export default APStatus;
