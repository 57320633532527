import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import BlockModal from "./components/BlockModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from '../../components/FormInputs/SearchBar.js';

class BlockOverview extends Component {
  state = {
    blockModalOpen: false,
    blockModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchBlocks().then();
  }

  openAddBlock() {
    this.setState({
      blockModalOpen: true,
      blockModalData: null,
    });
  }

  async openEditBlock(blockId) {
    this.setState({
      blockModalOpen: true,
      blockModalData: this.props.blocks[blockId],
    });
  }

  async addBlock(blockData) {
    this.setState({ blockModalOpen: false });

    if (!await ApiHelper.createBlock(blockData)) {
      alert("Error creating new block");
    }
    ApiHelper.fetchBlocks().then();
  }

  async updateBlock(blockData) {
    this.setState({ blockModalOpen: false });

    // const result = await ApiHelper.updateBlock(blockData);

    // if (!result) {
    //   alert("Error updating block: " + blockData["ueId"]);
    // }
    // ApiHelper.fetchBlocks().then();
  }

  async deleteBlock(block) {
    if (!window.confirm(`Delete block ${block.id}?`))
      return;

    const result = await ApiHelper.delete(block.id);
    ApiHelper.fetchblocks().then();
    if (!result) {
      alert("Error deleting block: " + block.id);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Blocks</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                  </div>
                </div>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th>Height</th>
                      <th style={{ width: 400 }}>Hash</th>
                      <th>Created On</th>
                      <th>dSIM Transactions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.blocks.filter(block =>
                      block.id.includes(this.state.searchString) ||
                      block.hash.includes(this.state.searchString)
                    ).map(block => (
                      <tr key={block.id}>
                        <td>{block.id}</td>
                        <td>{block.hash}</td>
                        <td>{block.createdDate}</td>
                        <td>{block.transactionsCount}</td>
                        <td style={{ textAlign: 'center' }}>
                        <Button
                          variant="info"
                          onClick={() => block.transactionsCount > 0 && this.openEditBlock(block.id)}
                        >
                          View
                        </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <BlockModal open={this.state.blockModalOpen}
          setOpen={val => this.setState({ blockModalOpen: val })}
          block={this.state.blockModalData}
          onModify={this.updateBlock.bind(this)}
          onSubmit={this.addBlock.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  blocks: state.block.blocks,
});

export default withRouter(connect(mapStateToProps)(BlockOverview));
