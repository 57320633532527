import React from "react";
import { Route } from "react-router-dom";
import CellOverview from "./CellOverview";

const Cells = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={CellOverview} />
  </div>
);

export default Cells;
