import React from "react";
import { createAppKit } from "@reown/appkit/react";
import { EthersAdapter } from "@reown/appkit-adapter-ethers";
import { PROJECT_ID } from "../constants/Env.ts";
import { mainnet, base, sepolia, baseSepolia } from "@reown/appkit/networks";

const networks = [mainnet, baseSepolia];

const metadata = {
  name: "bloxtel",
  description: "Bloxtel",
  url: "https://mywebsite.com",
  icons: ["https://avatars.mywebsite.com/"],
};

createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId: PROJECT_ID,
  features: {
    email: false,
    socials: false,
    analytics: true,
    emailShowWallets: false,
    onramp: false,
    swaps: false,
  },
});

export default function ReownContext({ children }) {
  return <>{children}</>;
}
