import { cloneDeep } from "lodash/lang";
import { operatorIdWidget, dsimIdWidget } from "../../components/FormWidgets";

export let cameraModalSchema = {
  type: "object",
  required: [
    "label",
    "cameraType",
    "manufacturerName",
    "modelName",
    "imei",
    "dsimId",
    "operatorName",
    "sliceId",
    "channel",
    "streamURL",
    // "osId",
    // "osAppId",
    // "osAppIdType",
  ],
  properties: {
    operatorName: {
      type: "string",
      title: "Operator Name",
      default: "",
    },

    operatorId: {
      type: "string",
      title: "Operator ID",
      default: "",
      readOnly: true,
    },
    label: {
      type: "string",
      title: "Label",
      default: "",
    },

    // uuId: {
    //   type: "string",
    //   title: "UUID",
    //   default: "",
    //   readOnly: true,
    // },

    description: {
      type: "string",
      title: "Description",
      default: "",
    },

    cameraType: {
      type: "string",
      title: "Camera Type",
      default: "STATIONARY",
      enum: ["STATIONARY", "MOBILE"],
    },

    manufacturerName: {
      type: "string",
      title: "Manufacturer Name",
      default: "",
    },

    modelName: {
      type: "string",
      title: "Model Name",
      default: "",
    },

    imei: {
      type: "string",
      title: "IMEI",
      default: "",
    },

    dsimId: {
      type: "string",
      title: "Associated dSIM",
      default: "",
    },

    dsimAuthMethod: {
      type: "string",
      title: "Associated dSIM Auth. Method",
      default: "",
      readOnly: true,
    },

    sliceId: {
      type: "string",
      title: "Slice ID",
      default: "",
    },

    // osId: {
    //   type: "string",
    //   title: "OS ID",
    //   default: "",
    // },

    // osAppId: {
    //   type: "string",
    //   title: "OS App ID",
    //   default: "",
    // },

    // osAppIdType: {
    //   type: "string",
    //   title: "OS App Type",
    //   default: "",
    // },

    // appId: {
    //   type: "string",
    //   title: "App ID",
    //   default: "",
    // },

    channel: {
      type: "string",
      title: "Channel",
      default: "",
      readOnly: true,
    },

    key: {
      type: "string",
      title: "Key",
      default: "",
      readOnly: true,
    },

    streamURL: {
      type: "string",
      title: "Stream URL",
      default: "",
    },

    status: {
      type: "string",
      title: "Status",
      enum: ["ENABLED", "DISABLED"],
      default: "ENABLED",
    },

    rule: {
      type: "array",
      title: "AI Rules",
      items: { $ref: "#/definitions/Rule" },
    },
  },
  definitions: {
    Rule: {
      type: "object",
      required: [
        //   "eventType",
        //   "actionType",
        //   "actionFromUserType",
        //   "actionToUserType",
        //   "actionMessage",
      ],
      properties: {
        eventType: {
          type: "string",
          title: "Event Type",
          default: "DETECTED_WEAPON",
          enum: [
            /*"DETECTED_FIGHT", "DETECTED_FIRE", "DETECTED_HUMAN_ANOMALY", */ "DETECTED_WEAPON",
            "LOGGING",
          ],
        },
        actionType: {
          type: "string",
          title: "Action Type",
          default: "NOTIFICATION",
          enum: ["NOTIFICATION"],
        },
        actionFromUserType: {
          type: "string",
          title: "Action From User Type",
          default: "ROBOT",
          enum: ["ROBOT", "HUMAN"],
        },
        actionToUserType: {
          type: "string",
          title: "Action To User Type",
          default: "HUMAN",
          enum: ["ROBOT", "HUMAN"],
        },
        actionUsers: {
          type: "object",
          title: "Action Users",
          properties: {
            guards: {
              type: "boolean",
              title: "All Guards",
              default: true,
              readOnly: true,
            },
            text: {
              type: "boolean",
              title: "Text",
              default: false,
            },
            email: {
              type: "boolean",
              title: "Email",
              default: false,
            },
            server: {
              type: "boolean",
              title: "App",
              default: false,
            },
          },
        },
        actionMessage: {
          type: "string",
          title: "Action Message",
          default: "",
        },
        userEmail: {
          type: "string",
          title: "User Email",
          default: "",
        },
        userPhoneNumber: {
          type: "string",
          title: "User Phone Number",
          default: "",
        },
        userAppUrl: {
          type: "string",
          title: "User App URL",
          default: "",
        },
      },
    },
  },
};

export let cameraModalUISchema = {
  dsimId: dsimIdWidget(false),
};

export let cameraModalSuperSchema = cloneDeep(cameraModalSchema);
// cameraModalSuperSchema.required.push("operatorName");
// cameraModalSuperSchema.properties.operatorName = {
//     type: "string",
//     title: "Operator Name",
//     default: "",
// }
// cameraModalSuperSchema.properties.operatorId = {
//     type: "string",
//     title: "Operator ID",
//     default: "",
//     readOnly: true,
// }

export let cameraModalSuperUISchema = {
  operatorName: operatorIdWidget(false),
  dsimId: dsimIdWidget(false),
};

export let cameraModalSuperEditUISchema = {
  operatorName: operatorIdWidget(true),
  dsimId: dsimIdWidget(true),
};
