import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, FormControl, Table, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { Spinner } from "@patternfly/react-core";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import { customFetch } from "../../util/ApiHelper";
import Footer from "../Main/Footer";
import Skeleton from "../../components/ui/Skeleton";
import { useAppKitAccount } from "@reown/appkit/react";
import { ConnectButton } from "../../components/ui/ConnectButton";

const Personalize = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [height, setHeight] = useState(0);
  const [geolocationLoading, setGeolocationLoading] = useState(false);
  const [geolocationError, setGeolocationError] = useState(false);
  const [isCustodial, setIsCustodial] = useState(true);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { address, isConnected } = useAppKitAccount();
  const [enrollmentStart, setEnrollmentStart] = useState(false);

  const updateWindowDimensions = useCallback(() => {
    setIsWidthBigger(
      (window.innerWidth * 0.67) / window.innerHeight > 650 / 525,
    );
  }, []);

  // Load geolocation data from localStorage
  useEffect(() => {
    const cachedData = JSON.parse(localStorage.getItem("geolocationData"));
    if (cachedData) {
      setLongitude(cachedData.longitude || 0);
      setLatitude(cachedData.latitude || 0);
      setHeight(cachedData.height || 0);
    }
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  async function checkEnrollStatus() {
    const getEnrollStatus = await customFetch("/apenrollmenstatus");
    if (getEnrollStatus.isEnrolled === true) {
      toast.error("Already enrolled");
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else if (getEnrollStatus.enrollmentInProgress === true) {
      setTimeout(() => {
        history.push("/enroll-loading");
      }, 2000);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    checkEnrollStatus();
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "walletType",
      JSON.stringify({ custodial: isCustodial }),
    );
  }, [isCustodial]);

  const getGeolocation = async () => {
    if (longitude !== 0 && latitude !== 0 && height !== 0) {
      return;
    }
    try {
      setGeolocationLoading(true);
      const response = await customFetch("/geolocation");
      const { longitude: l, latitude: la, height: he } = response;

      if (l === 0 && la === 0 && he === 0) {
        setGeolocationLoading(false);
        setGeolocationError(true);
      } else {
        setLongitude(Number(l));
        setLatitude(Number(la));
        setHeight(Number(he));
        setGeolocationLoading(false);

        localStorage.setItem(
          "geolocationData",
          JSON.stringify({ longitude: l, latitude: la, height: he }),
        );
      }
    } catch (error) {
      setGeolocationLoading(false);
      setGeolocationError(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (longitude === 0 && latitude === 0 && height === 0) {
        getGeolocation();
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [longitude, latitude, height]);

  const validateForm = () => {
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEnrollmentStart(true);

    if (validateForm()) {
      setErrorMsg(validateForm());
      setEnrollmentStart(false);
      return;
    }

    // setSubmitDisabled(true);
    setErrorMsg("");

    const data = {
      longitude,
      latitude,
      height,
    };
    if (!isCustodial) {
      data.walletAddress = address;
    }
    const response = await customFetch("/startpersonalization", "POST", data);
    if (response) {
      sessionStorage.setItem(
        "personalizationData",
        JSON.stringify(response.dataToBeSigned),
      );
      setEnrollmentStart(false);
      history.push("/enroll-sign");
    } else {
      setEnrollmentStart(false);
      console.error("Failed to submit personalization request");
    }

    setSubmitDisabled(false);
    setErrorMsg("");
    setEnrollmentStart(false);
  };

  const onClickBack = () => {
    history.push("/");
  };

  return (
    <div className="Login">
      <div className="HeroLogoContent">
        <Image
          src={HeroLogo}
          className={`${isWidthBigger ? "img-width" : "img-height"}`}
          alt="heroLogo"
        />
      </div>

      <div className="LoginFormContent">
        <div className="BackBtn" onClick={onClickBack}>
          <i className="fas fa-reply" />
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Spinner animation="border" className="m-auto" variant="primary" />
          </div>
        ) : (
          <div className="LoginForm">
            <div className="text-center mb-4">
              <img
                src={Open6gcLogo}
                alt="Bloxtel Operator dApp"
                style={{ width: "80px", height: "80px", marginBottom: "15px" }}
              />
              <h2 className="font-bold text-2xl mb-4">Enroll Access Point</h2>
            </div>

            <div className="card p-4 mb-5 rounded-lg shadow-sm bg-white">
              <h3 className="text-xl font-semibold mb-3">
                Web3 Wallet Connection
              </h3>

              <div className="text-center mb-4">
                <p className="text-gray-700">
                  Do you want to use your own Web3 wallet for managing the
                  access point and dSIMs?
                </p>
              </div>

              <div className="flex justify-center gap-4 mb-4">
                <Button
                  onClick={() => setIsCustodial(true)}
                  className={`px-4 py-2 rounded-md ${
                    isCustodial
                      ? "bg-[#7769f9] text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  style={{
                    width: "45%",
                    backgroundColor: isCustodial ? "#7769f9" : "#f0f0f0",
                    color: isCustodial ? "white" : "#333",
                    border: "none",
                  }}
                >
                  Skip
                </Button>

                <Button
                  onClick={() => setIsCustodial(false)}
                  className={`px-4 py-2 rounded-md ${
                    !isCustodial
                      ? "bg-[#7769f9] text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
              style={{
                    width: "45%",
                    backgroundColor: !isCustodial ? "#7769f9" : "#f0f0f0",
                    color: !isCustodial ? "white" : "#333",
                    border: "none",
              }}
            >
                  Use my own Web3 wallet
                </Button>
              </div>

              {!isCustodial && (
                <div className="mt-3 mb-2">
                  {!isConnected ? (
                    <div className="flex justify-center">
                      <ConnectButton />
                    </div>
                  ) : (
                    <div className="text-center text-green-600 flex items-center justify-center">
                      <i className="fas fa-check-circle mr-2"></i>
                      <span>Web3 wallet connected successfully</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="card p-4 rounded-lg shadow-sm bg-white">
              <h3 className="text-xl font-semibold mb-3">Geo Location</h3>

              <div className="text-center mb-4">
                {geolocationLoading ? (
                  <div className="flex justify-center items-center mb-3">
                    <Spinner
                      animation="border"
                      size="sm"
                      className="mr-2"
                      variant="primary"
                    />
                    <span className="text-gray-700">
                      Retrieving your location...
                    </span>
                  </div>
                ) : (
                  <>
                    <p className="text-gray-700 mb-2">
                      Your geolocation will be retrieved automatically. Please
                      wait a moment.
                    </p>
                    {geolocationError && (
                      <p className="text-red-500 mb-2">
                        Unable to retrieve location automatically. Please enter
                        manually.
                      </p>
                    )}
                    <p className="text-gray-700">
                      Please provide the geolocation of your access point
                      manually if needed.
                    </p>
                  </>
                )}
              </div>

              <div className="mb-4">
                <div className="mb-3">
                  <label className="block text-gray-700 mb-1 font-medium">
                    Longitude:
                  </label>
                  <FormControl
                    type="number"
                    placeholder="-84.4352"
                    value={longitude || ""}
                    onChange={(e) => setLongitude(Number(e.target.value))}
                    className="w-full rounded-md border-gray-300"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-gray-700 mb-1 font-medium">
                    Latitude:
                  </label>
                  <FormControl
                    type="number"
                    placeholder="33.7994"
                    value={latitude || ""}
                    onChange={(e) => setLatitude(Number(e.target.value))}
                    className="w-full rounded-md border-gray-300"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-gray-700 mb-1 font-medium">
                    Height:
                  </label>
                  <FormControl
                    type="number"
                    placeholder="0.01"
                    value={height || ""}
                    onChange={(e) => setHeight(Number(e.target.value))}
                    className="w-full rounded-md border-gray-300"
                  />
                </div>
              </div>

              {errorMsg && (
                <div className="text-red-500 mb-4 text-center">
                  <p>{errorMsg}</p>
                </div>
              )}

              <div className="flex justify-between mt-4">
                <Button
                  onClick={onClickBack}
                  variant="outline-secondary"
                  className="px-4 py-2 rounded-md"
                  style={{
                    backgroundColor: "#f0f0f0",
                    color: "#333",
                    border: "none",
                  }}
                >
                  Cancel
                </Button>

              <button
                type="submit"
                className={`rounded-md bg-[#7769f9] text-white px-4 py-2 cursor-pointer ${
                  (!isCustodial && !isConnected) ||
                  (longitude === 0 && latitude === 0 && height === 0)
                    ? "opacity-50"
                    : ""
                }`}
                disabled={
                    // (!isCustodial && !isConnected) ||
                    (longitude === 0 && latitude === 0 && height === 0) ||
                    enrollmentStart
                  }
                  onClick={handleSubmit}
                >
                  {enrollmentStart ? (
                    <div className="flex items-center justify-center">
                      <Spinner animation="border" size="sm" className="mr-2" />
                      <span>Enrollment in progress...</span>
                    </div>
                  ) : (
                    "Start Enrollment"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Personalize;
