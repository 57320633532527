import actions from '../actions/cellActions';

const initialState = {
  cells: [],
  cellsMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_CELLS:
      nextState.cells = action.cells;
      nextState.cellsMap = createCellsMap(action.cells);
      return nextState;

    default:
      return state;
  }
}

function createCellsMap(cells) {
  let cellsMap = {};
  cells.forEach(cells => cellsMap[cells['id']] = cells);
  return cellsMap;
}
