import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  phoneModalSchema,
  phoneModalUISchema,
  phoneModalSuperSchema,
  phoneModalSuperUISchema,
  phoneModalSuperEditUISchema
} from '../../../metadata/index'

class PhoneModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    isSuper: PropTypes.bool.isRequired,
    phone: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
  };

  schema = this.props.isSuper ?  phoneModalSuperSchema : phoneModalSchema;
  uiSchema = this.props.isSuper ?  phoneModalSuperUISchema : phoneModalUISchema;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.phone });

      if (this.props.phone) {
        const phone = this.props.phone;

        if (this.props.isSuper) {
          this.uiSchema = phoneModalSuperEditUISchema;
        }

        let formData = {
          uuId: phone['uuId'],
          operatorName: this.props.operators.filter((operator) => operator.id === phone['operatorId'])[0]?.name || "",
          operatorId: phone['operatorId'],
          manufacturerName: phone['manufacturerName'],
          modelName: phone['modelName'],
          imei: phone['imei'],
          dsimId: phone['dsimId'],
          dsimAuthMethod: this.props.dsims.filter((dsim) => dsim.id === phone['dsimId'])[0]?.authMethod || "",
          sliceId: phone['sliceId'],
          osId: phone['osId'],
          osAppId: phone['osAppId'],
          osAppIdType: phone['osAppIdType'],
          appId: phone['appId'],
          createdDate: phone["createdDate"],
          modifiedDate: phone["modifiedDate"],
        };

        this.updateFormData(formData).then();
      } else {
        console.error("new uphonne");
        let formData = {
          uuId: "",
          dsimId: "",
        };
        this.uiSchema = phoneModalSuperUISchema;
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    if(newData && lastData && lastData.operatorName !== newData.operatorName) {
      const selectedOperator = this.props.operators.filter((operator) => operator.name === newData.operatorName);
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }

    if(newData && lastData && lastData.dsimId !== newData.dsimId) {
      const selecteddSIM = this.props.dsims.filter((dsim) => dsim.id === newData.dsimId);

      if (selecteddSIM.length > 0) {
        newData.dsimAuthMethod = selecteddSIM[0].authenticationMethod;
      } else {
        newData.dsimAuthMethod = "";
      }
      this.updateFormData(newData).then();
    }
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper ? formData["operatorId"] : this.props.user.operatorId;

    let phoneData = {
      "uuId": formData["uuId"],
      "operatorId": operatorId,
      "manufacturerName": formData["manufacturerName"],
      "modelName": formData["modelName"],
      "imei": formData["imei"],
      "dsimId": formData["dsimId"],
      "sliceId": formData["sliceId"],
      "osId": formData["osId"],
      "osAppId": formData["osAppId"],
      "osAppIdType": formData["osAppIdType"],
      "appId": formData["appId"],
      "createdDate": formData["createdDate"],
      "modifiedDate": formData["modifiedDate"],
    };

    if(this.state.editMode) {
      this.props.onModify(phoneData);
    } else {
      this.props.onSubmit(phoneData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit Phone" : "New Phone"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 &&
            <Form schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)} />
          }
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  operators: state.operator.operators,
  dsims: state.dsim.dsims,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(PhoneModal));
