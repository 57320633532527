import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {downloadModalSchema} from "../forms";
import ApiHelper from "../../../util/ApiHelper";
import validator from '@rjsf/validator-ajv8';

class DownloadModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    dsimId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    subscribers: PropTypes.array.isRequired,
  };

  state = {
    formData: undefined,
    isTransferring: false,
    rerenderCounter: 0,
    connected: false, 
    address: "", 
  };

  uiSchema = downloadModalSchema;

  componentDidUpdate(prevProps, prevState, snapshot) {}

  extractData(inputStr) {
    const partBeforeUnderscores = inputStr.split('____')[0];
    return partBeforeUnderscores.replace(/-/g, '');
  }

  getData = (subscriberName) => {
    const regex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/;
    const matches = subscriberName.match(regex);

    // Check if any match is found and print the first one
    if (matches) {
      return matches[0];
    } else {
      console.log("No UUID found in the string.");
      return ""
    }
  }

  async updateFormData(newData) {
    // Workaround for bug: https://github.com/rjsf-team/react-jsonschema-form/issues/758
    await this.setState({ rerenderCounter: this.state.rerenderCounter + 1 });
    await this.setState({
      rerenderCounter: this.state.rerenderCounter + 1,
      formData: newData,
    });
  }

  async onSubmitAsync(formData) {

    const subscriberId = this.getData(formData.subscriber)

    if (subscriberId === "") {
        alert("Invalid subscriber");
        return;
    }

    await this.setState({ isTransferring: true });
    const response = await ApiHelper.downloadToken(this.props.dsimId, subscriberId);
    await this.setState({ isTransferring: false });

    if (response.status !== 200) {
      alert("Error: " + response.statusText);
      return;
    }

    this.props.setOpen(false)
    alert("Token successfully downloaded");

  }

  onSubmitClick(result) {

    const formData = result.formData;

    this.onSubmitAsync(formData);
  }

  async onChange(data) {}

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Transfer to a subscriber</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.isTransferring && <div className="sp loading" />}
          {this.state.rerenderCounter % 2 === 0 &&
            !this.state.isTransferring && (
              <Form
                schema={this.props.schema}
                uiSchema={this.uiSchema}
                formData={this.state.formData}
                onChange={this.onChange.bind(this)}
                onSubmit={this.onSubmitClick.bind(this)}
                validator={validator}
              />
            )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(DownloadModal));