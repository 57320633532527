import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import validator from "@rjsf/validator-ajv8";
import { connect } from "react-redux";
import {
  invoiceModalSchema,
  invoiceModalUISchema,
} from "../../../metadata/index";
import ApiHelper from "../../../util/ApiHelper";

class InvoiceModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isSuper: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onPay: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    isPaying: false,
    rerenderCounter: 0,
    pricePerGB: 0,
    oldStatus: "",
  };

  schema = invoiceModalSchema(this.props.isSuper, "Subscription");
  uiSchema = invoiceModalUISchema(false);

  async loadPricePerGB() {
    const res = await ApiHelper.getPricePerGB();
    this.setState({ pricePerGB: Number(res.price) || 0 });
  }

  componentDidMount() {
    this.loadPricePerGB();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.invoice });

      if (this.props.invoice) {
        const invoice = this.props.invoice;

        this.uiSchema = invoiceModalUISchema(true);

        let formData = {
          invoiceId: invoice["invoiceId"],
          invoiceTo: invoice["invoiceTo"],
          operatorName:
            this.props.operators.filter(
              (operator) => operator.id === invoice["operatorId"],
            )[0]?.name || "",
          operatorId: invoice["operatorId"],
          invoiceAmount: invoice["invoiceAmount"],
          invoiceDueDate: invoice["invoiceDueDate"],
          invoicePaidDate: invoice["invoicePaidDate"],
          invoiceType: invoice["invoiceType"],
          offloadedGB: Number(invoice["offloadedGB"]),
          pricePerGB: Number(invoice["pricePerGB"]),
          pdf: invoice["pdf"],
          orderId: invoice["orderId"],
          status: invoice["status"],
          createdDate: invoice["createdDate"],
          modifiedDate: invoice["modifiedDate"],
        };
        this.setState({ oldStatus: invoice["status"] });
        this.updateFormData(formData).then();
      } else {
        let formData = {
          invoiceId: "",
          invoiceName: "",
          pricePerGB: this.state.pricePerGB,
        };

        this.uiSchema = invoiceModalUISchema(false);
        this.setState({ oldStatus: "" });
        this.updateFormData(formData).then();
      }
    }
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onDownloadPDFClick() {
    console.error("download pdf");
  }

  async onPayClick() {
    this.setState({ isPaying: true });
    const invoice = await ApiHelper.payInvoice(
      this.props.user.operatorId,
      this.state.formData.invoiceId,
    );

    this.setState({ isPaying: false });
    if (invoice) {
      this.props.onPay();
    }
  }

  async onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper
      ? formData["operatorId"]
      : this.props.user.operatorId;

    let invoiceData = {
      invoiceId: formData["invoiceId"],
      invoiceTo: formData["invoiceTo"],
      operatorId: operatorId,
      invoiceAmount: formData["invoiceAmount"],
      invoiceDueDate: formData["invoiceDueDate"],
      invoicePaidDate: formData["invoicePaidDate"],
      invoiceType: formData["invoiceType"],
      offloadedGB: formData["offloadedGB"]?.toString(),
      pricePerGB: formData["pricePerGB"].toString(),
      orderId: formData["orderId"],
      status: formData["status"],
      createdDate: formData["createdDate"],
      modifiedDate: formData["modifiedDate"],
    };

    if (
      formData.invoiceType === "Offloading" &&
      formData.status === "PAID" &&
      this.state.oldStatus !== "PAID"
    ) {
      const payoutData = {
        operatorId: operatorId,
        payerName: "bloxtel",
        payeeName: formData["invoiceTo"],
        payoutType: "Neutral Hosting",
        payoutAmountCurrency: "USD",
        payoutAmount: formData["invoiceAmount"],
        status: "DUE",
      };

      if (!(await ApiHelper.createPayouts(operatorId, payoutData))) {
        alert("Error creating new payout");
      }
    }

    if (this.state.editMode) {
      this.props.onModify(invoiceData);
    } else {
      this.props.onSubmit(invoiceData);
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    this.schema = invoiceModalSchema(this.props.isSuper, newData.invoiceType);

    if (
      lastData &&
      lastData.offloadedGB !== newData.offloadedGB &&
      newData.invoiceType === "Offloading"
    ) {
      const cal = newData.offloadedGB * newData.pricePerGB;
      newData.invoiceAmount = cal.toString();
      await this.updateFormData(newData);
      const acInput = document.getElementById("root_offloadedGB");
      acInput.focus();
    }

    if (newData && lastData && lastData.operatorName !== newData.operatorName) {
      const selectedOperator = this.props.operators.filter(
        (operator) => operator.name === newData.operatorName,
      );
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }

    this.setState({ formData: newData });
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit Invoice" : "New invoice"}
            {this.state.editMode && (
              <>
                {this.state.formData?.status !== "PAID" && (
                  <Button
                    bsStyle={"primary"}
                    style={{ float: "right", marginRight: 20 }}
                    onClick={this.onPayClick.bind(this)}
                    disabled={this.state.isPaying}
                  >
                    Pay
                  </Button>
                )}

                <Button
                  href={this.state.formData?.pdf}
                  bsStyle={"primary"}
                  style={{ float: "right", marginRight: 20 }}
                  onClick={this.onDownloadPDFClick.bind(this)}
                >
                  Download
                </Button>
              </>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.isPaying && <div className="sp loading" />}
          {this.state.rerenderCounter % 2 === 0 && !this.state.isPaying && (
            <Form
              schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
              validator={validator}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  operators: state.operator.operators,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(InvoiceModal));
