import { cloneDeep } from "lodash/lang";

export let orderModalSchema = {
  type: "object",
  required: ["orderQuantity", "name", "orderType", "dsimType", "authMethod"],
  properties: {
    orderId: {
      type: "string",
      title: "Order ID",
      default: "",
      readOnly: true,
    },
    name: {
      type: "string",
      title: "Name",
      default: "",
    },
    orderType: {
      type: "string",
      title: "Order Type",
      default: "dSIM Purchase",
      enum: ["dSIM Purchase"],
      readOnly: true,
    },
    dsimType: {
      type: "string",
      title: "dSIM Type",
      default: "ELECTRONIC",
      enum: ["PHYSICAL", "ELECTRONIC"],
    },
    authMethod: {
      type: "string",
      title: "Authentication Mode",
      default: "6G_AKA",
      enum: ["6G_AKA", "5G_AKA"],
    },
    orderQuantity: {
      type: "integer",
      title: "Order Quantity",
      default: 1,
      maximum: 10000,
      minimum: 1,
    },
    status: {
      type: "string",
      title: "Order Status",
      default: "PENDING",
      enum: ["PENDING", "FULFILLED", "CANCELED"],
      readOnly: true,
    },
  },
};

export let orderModalEditSchema = cloneDeep(orderModalSchema);
orderModalEditSchema.properties.orderType.readOnly = true;
orderModalEditSchema.properties.dsimType.readOnly = true;
orderModalEditSchema.properties.authMethod.readOnly = true;
orderModalEditSchema.properties.orderQuantity.readOnly = true;
orderModalEditSchema.properties.name.readOnly = true;

export let orderModalSuperSchema = cloneDeep(orderModalSchema);
orderModalSuperSchema.required.push("operatorId");
orderModalSuperSchema.properties.operatorId = {
  type: "string",
  title: "Operator ID",
  default: "",
};

export let orderModalSuperEditSchema = cloneDeep(orderModalSuperSchema);
orderModalSuperEditSchema.properties.operatorId = {
  type: "string",
  title: "Operator ID",
  readOnly: true,
};

export let orderModalUISchema = {
  operatorId: {
    "ui:field": "OperatorNameWidget",
  },
  orderId: { "ui:options": { widget: "hidden" } },
  status: { "ui:options": { widget: "hidden" } },
};

