import React, { useEffect, useState } from "react";
import OrderModal from "./components/OrderModal";
import { customFetch } from "../../util/ApiHelper";
import GenericDataList from "../../components/GenericDataList";
import AppUtils from "../../util/AppUtils";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { signTypedDataMessage } from "../../util/blockchainUtils";
import { Button } from "react-bootstrap";

export const SignButton = ({ orderStatus, order, isCustodial, refetch }) => {
  const { walletProvider } = useAppKitProvider("eip155");
  const { address } = useAppKitAccount();

  const signMessage = async (orderId, amount) => {
    if (orderStatus[orderId] === "Signed, pending") {
      toast.error("Already signed!");
      return;
    }
    const provider = new ethers.BrowserProvider(walletProvider);

    try {
      const deploymentInfo = await customFetch(
        `/deployment-info?contractName=${"BloxtelManager"}`,
      );
      if (!deploymentInfo) {
        return;
      }

      const {
        address: BLOXTEL_MANAGER_ADDRESS,
        chainId: CHAIN_ID,
        abi: BLOXTEL_MANAGER_ABI,
      } = deploymentInfo;

      const bloxtelManager = new ethers.Contract(
        BLOXTEL_MANAGER_ADDRESS,
        BLOXTEL_MANAGER_ABI,
        provider,
      );

      const trustedForwarderDeploymentInfo = await customFetch(
        `/deployment-info?contractName=${"TrustedForwarder"}`,
      );

      if (!trustedForwarderDeploymentInfo) {
        return;
      }

      const { abi: ABI, address: TRUSTED_FORWARDER_ADDRESS } =
        trustedForwarderDeploymentInfo;

      const trustedForwarder = new ethers.Contract(
        TRUSTED_FORWARDER_ADDRESS,
        ABI,
        provider,
      );

      const nonce = await trustedForwarder.getNonce(address);

      const types = {
        ForwardRequest: [
          { name: "from", type: "address" },
          { name: "to", type: "address" },
          { name: "value", type: "uint256" },
          { name: "gas", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "data", type: "bytes" },
        ],
      };

      if (!walletProvider) {
        toast.error("No wallet provider found, please connect wallet");
        return;
      }

      const mintFTData = bloxtelManager.interface.encodeFunctionData(
        "mintdSIMSFT",
        [orderId, amount],
      );

      const message = {
        from: address,
        to: BLOXTEL_MANAGER_ADDRESS,
        value: 0,
        gas: 1000000,
        nonce: nonce,
        data: mintFTData,
      };

      const domain = {
        name: "TrustedForwarder",
        version: "1",
        chainId: parseInt(CHAIN_ID),
        verifyingContract: TRUSTED_FORWARDER_ADDRESS,
      };

      const signature = await signTypedDataMessage(
        domain,
        types,
        message,
        address,
        walletProvider,
      );

      const requestData = {
        externalId: orderId,
        trustedForwarder: TRUSTED_FORWARDER_ADDRESS,
        from: address,
        to: BLOXTEL_MANAGER_ADDRESS,
        value: "0",
        gas: "1000000",
        nonce: nonce.toString(),
        data: mintFTData,
        signature: signature,
      };

      await customFetch("/execution-request", "POST", requestData);
      refetch();
    } catch (error) {
      console.error("Error signing message", error);
    }
  };

  return (
    orderStatus[order.orderId] === "Order unsigned" &&
    !isCustodial && (
      <Button
        variant="info"
        onClick={() => signMessage(order.orderId, order.quantity)}
      >
        Sign
      </Button>
    )
  );
};

export const OrderOverview = () => {
  const [orderStatus, setOrderStatus] = useState({});
  const [isCustodial, setIsCustodial] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setIsCustodial(user.walletIsCustodial);
  }, []);

  const fetch = async () => {
    const orders = await customFetch(`/order`, "GET");
    if (orders) {
      await getOrdersStatus(orders);
    }
    return orders;
  };

  const add = async (data) => {
    const result = await customFetch(`/order`, "POST", data);
    return !!result;
  };

  const get = async (data) => {
    return await customFetch(`/order/${data.orderId}`);
  };

  const update = async (data) => {
    const result = await customFetch(`/order/${data.orderId}`, "PUT", data);
    return !!result;
  };

  const _delete = async (data) => {
    const result = await customFetch(`/order/${data.orderId}`, "DELETE");
    return !!result;
  };

  const isOrderMinted = async (orderId) => {
    const ftRequestStatus = await customFetch(
      `/mint-ft-request/status/${orderId}`,
      "GET",
    );

    if (!ftRequestStatus.exists) return "Order unsigned";
    if (!ftRequestStatus.minted) return "Order signed, pending execution.";
    return `Order executed. dSIMs ${ftRequestStatus.nftMinted}`;
  };

  const getOrdersStatus = async (orders) => {
    const status = {};
    for (const order of orders) {
      status[order.orderId] = await isOrderMinted(order.orderId);
    }
    setOrderStatus(status);
  };

  const columns = [
    {
      header: "Name",
      searchable: true,
      field: "name",
    },
    {
      header: "dSIM Type",
      field: "dsimType",
    },
    {
      header: "Authentication Method",
      field: "authMethod",
    },
    {
      header: "Quantity",
      field: "orderQuantity",
    },
    {
      header: "Remaining",
      field: "remaining",
    },
    {
      header: "Status",
      render: (data) => {
        return (
          <span
            style={{
              color: AppUtils.getStatusTextColor(data.status),
            }}
          >
            {" "}
            {data.status}{" "}
          </span>
        );
      },
    },
    {
      header: "Blockchain Status",
      render: (data) => {
        return orderStatus[data.orderId] || "Fetching...";
      },
    },
    {
      header: "Created",
      field: "createdDate",
    },
  ];

  const customActions = (order) => (
    <SignButton
      orderStatus={orderStatus}
      isCustodial={isCustodial}
      order={order}
      refetch={fetch}
    />
  );
  return (
    <GenericDataList
      fetchAllData={fetch}
      addData={add}
      getData={get}
      updateData={update}
      allowDelete={false}
      dataName="Order"
      itemKey="orderId"
      columns={columns}
      DetailComponent={OrderModal}
      customActions={customActions}
    />
  );
};

export default OrderOverview;
