import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { customFetch } from "../../../util/ApiHelper";

const PricingTable = (props) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const data = await customFetch("/subscriptiontypes");
      setSubscriptions(data);
    };
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClickPlan = (planType) => {
    if (props.isPending) {
      toast("Cannot change subscription type while pending.");
      return;
    }
    props.onSetPlan(planType);
  };

  const calculateCardWidth = () => {
    if (windowWidth < 768) {
      return "100%";
    }

    const availableWidth = windowWidth - 100;
    const cardCount = subscriptions.length;
    const gapSpace = (cardCount - 1) * 15;
    const cardWidth = Math.floor((availableWidth - gapSpace) / cardCount);

    return Math.min(Math.max(cardWidth, 220), 280) + "px";
  };

  const styles = {
    container: {
      width: "100%",
      margin: "0 auto",
      padding: "10px",
      overflowX: "hidden",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "center",
      gap: "15px",
    },
    card: {
      flex: "0 0 auto",
      width: calculateCardWidth(),
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 0 15px rgba(0,0,0,0.08)",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
    },
    cardHover: {
      transform: "translateY(-5px)",
      boxShadow: "0 10px 25px rgba(88, 70, 249, 0.2)",
    },
    header: {
      height: "50px",
      backgroundColor: "#5846F9",
      color: "white",
      fontSize: "18px",
      fontWeight: "500",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    priceContainer: {
      padding: "15px 15px 5px",
      color: "#888",
      fontSize: "13px",
      display: "flex",
      alignItems: "baseline",
    },
    price: {
      position: "relative",
      fontSize: "28px",
      fontWeight: "600",
      color: "#5846F9",
      marginRight: "8px",
      paddingLeft: "12px",
    },
    dollarSign: {
      position: "absolute",
      top: "3px",
      left: "0",
      fontSize: "14px",
    },
    featureList: {
      padding: "0 15px 10px",
      margin: "0",
      listStyle: "none",
    },
    feature: {
      padding: "8px 0",
      borderBottom: "1px solid #f5f5f5",
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
    },
    disabledFeature: {
      opacity: "0.4",
      textDecoration: "line-through",
    },
    buttonContainer: {
      padding: "15px 0 20px",
      marginTop: "auto",
      display: "flex",
      justifyContent: "center",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#603FF1",
      color: "white",
      border: "none",
      borderRadius: "5px",
      fontSize: "12px",
      fontWeight: "600",
      cursor: "pointer",
      transition: "background-color 0.3s ease, transform 0.2s ease",
      width: "80%",
      maxWidth: "150px",
    },
    buttonHover: {
      backgroundColor: "#4e32d3",
      transform: "scale(1.05)",
    },
    activeButton: {
      backgroundColor: "#10be3c",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.cardContainer}>
        {subscriptions.map((sub) => (
          <div
            key={sub.subscriptionType}
            style={styles.card}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = styles.cardHover.transform;
              e.currentTarget.style.boxShadow = styles.cardHover.boxShadow;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "none";
              e.currentTarget.style.boxShadow = styles.card.boxShadow;
            }}
          >
            <div style={styles.header}>{sub.subscriptionType}</div>
            <div style={styles.priceContainer}>
              <span style={styles.price}>
                <span style={styles.dollarSign}>$</span>
                {sub.amount}
              </span>
              <span>/ month</span>
            </div>
            <ul style={styles.featureList}>
              {sub.includedOptions.map((option, index) => (
                <li key={`inc-${index}`} style={styles.feature}>
                  ✓ {option}
                </li>
              ))}
              {sub.excludedOptions.map((option, index) => (
                <li
                  key={`exc-${index}`}
                  style={{ ...styles.feature, ...styles.disabledFeature }}
                >
                  ✗ {option}
                </li>
              ))}
            </ul>
            <div style={styles.buttonContainer}>
              <button
                onClick={() => onClickPlan(sub.subscriptionType)}
                style={{
                  ...styles.button,
                  ...(props.plan === sub.subscriptionType
                    ? styles.activeButton
                    : {}),
                }}
                onMouseEnter={(e) => {
                  if (props.plan !== sub.subscriptionType) {
                    e.target.style.backgroundColor =
                      styles.buttonHover.backgroundColor;
                    e.target.style.transform = styles.buttonHover.transform;
                  }
                }}
                onMouseLeave={(e) => {
                  if (props.plan !== sub.subscriptionType) {
                    e.target.style.backgroundColor =
                      styles.button.backgroundColor;
                    e.target.style.transform = "none";
                  }
                }}
              >
                {props.plan === sub.subscriptionType
                  ? "Subscribed"
                  : "Get Started"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingTable;
