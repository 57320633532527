import actions from '../actions/operatorActions';

const initialState = {
  operators: [],
  operatorsMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_OPERATORS:
      nextState.operators = action.operators;
      nextState.operatorsMap = createOperatorsMap(action.operators);
      return nextState;

    default:
      return state;
  }
}

function createOperatorsMap(operators) {
  let operatorsMap = {};
  operators.forEach(operators => operatorsMap[operators['id']] = operators);
  return operatorsMap;
}
