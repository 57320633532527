import Serializable from "./Serializable";

export default class Plan extends Serializable {
  id = "";
  name = "";
  status = "";
  description = "";

  constructor(id, name, description, dataLimit, price, quota, createdDate) {
    super();
    this.id = id;
    this.name = name;
    this.dataLimit = dataLimit;
    this.price = price;
    this.quota = quota;
    this.description = description;
    this.createdDate = createdDate;
  }
}
