import { IndustryData } from "../../config/industry";

export let businessModalSchema = {
  type: "object",
  required: ["organizationName", "organizationAddress", "industry"],
  properties: {
    organizationName: {
      type: "string",
      title: "Organization Name",
      default: "",
    },

    organizationAddress: {
      type: "string",
      title: "Organization Address",
      default: "",
    },

    industry: {
      type: "string",
      title: "Industry",
      default: "Accounting",
      enum: IndustryData,
    },
  },
};

export let businessModalUISchema = {};

export let operatorUserModalSchema = {
  type: "object",
  required: ["firstName", "lastName", "email", "password", "passwordConfirm"],
  properties: {
    firstName: {
      type: "string",
      title: "First Name",
      default: "",
    },

    lastName: {
      type: "string",
      title: "Last Name",
      default: "",
    },

    email: {
      type: "string",
      title: "Email",
      default: "",
    },

    password: {
      type: "string",
      title: "Password",
      default: "",
    },

    passwordConfirm: {
      type: "string",
      title: "Confirm Password",
      default: "",
    },

    title: {
      type: "string",
      title: "Title",
      default: "",
    },

    phoneNumber: {
      type: "string",
      format: "phone-us",
      title: "Phone Number",
      default: "",
    },
  },
};

export let operatorUserModalUISchema = {
  password: {
    "ui:widget": "password",
  },
  passwordConfirm: {
    "ui:widget": "password",
  },
};
