import { cloneDeep } from "lodash/lang";

export let planModalSchema = {
  type: "object",
  required: ["planName", "dataLimit"],
  properties: {
    planId: {
      type: "string",
      title: "Plan ID",
      default: "",
      readOnly: true,
    },
    planName: {
      type: "string",
      title: "Plan Name",
      default: "",
    },
    description: {
      type: "string",
      title: "Plan Description",
      default: "",
    },
    dataLimit: {
      type: "string",
      title: "Data Limit",
      default: "UNLIMITED",
      enum: ["UNLIMITED", "LIMITED"],
    },
    price: {
      type: "number",
      title: "Price ($)",
      default: 0,
    },
    quota: {
      type: "integer",
      title: "Quota (MB)",
      default: 0,
    },
  },
};

export let planModalSuperUISchema = {
  planId: { "ui:options": { widget: "hidden" } },
  operatorId: {
    "ui:field": "OperatorNameWidget",
  },
};

export let planModalSuperSchema = cloneDeep(planModalSchema);
planModalSuperSchema.required.push("operatorId");
planModalSuperSchema.properties.operatorId = {
  type: "string",
  title: "Operator ID",
  default: "",
  readOnly: true,
};

export let planModalEditSuperSchema = cloneDeep(planModalSuperSchema);
planModalSuperSchema.properties.operatorId.readOnly = true;

export let planModalUISchema = {
  planId: { "ui:options": { widget: "hidden" } },
};
