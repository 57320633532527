import React from "react";
import { Modal, Table } from "react-bootstrap";

const DeviceInfoModal = ({ open, setOpen, deviceData }) => {
  if (!deviceData) {
    return (
      <Modal
        show={open}
        className={"device-modal theme-light"}
        backdrop={"static"}
        onHide={() => setOpen(false)}
      ></Modal>
    );
  }

  const MainInfoField = ({ title, value }) => {
    return (
      <div className="device-modal__content__maininfo__field">
        <div className="device-modal__content__maininfo__field__title">
          {title}
        </div>
        <div className="device-modal__content__maininfo__field__value">
          {value}
        </div>
      </div>
    );
  };

  const LANHostsTable = ({ hostsData }) => {
    const hosts = [];
    const keyArray = Object.keys(hostsData);
    keyArray.forEach((key) => {
      if (!isNaN(key) && !isNaN(parseFloat(key))) {
        hosts.push(hostsData[key]);
      }
    });

    return (
      <Table
        className="overview__table"
        striped
        bordered
        condensed
        hover
        responsive
      >
        <thead>
          <tr>
            <th>Host name</th>
            <th>IP address</th>
            <th>MAC address</th>
          </tr>
        </thead>
        <tbody>
          {hosts.map((data) => (
            <tr key={data.HostName._value}>
              {data.HostName._value ? (
                <td>{data.HostName._value}</td>
              ) : (
                <td style={{ opacity: 0.5 }}>{"blank"}</td>
              )}
              <td>{data.IPAddress._value}</td>
              <td>{data.MACAddress._value}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Modal
      show={open}
      className={"device-modal theme-light"}
      backdrop={"static"}
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Device</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="device-modal__header">
                  <div className="device-modal__header__title">
                    {deviceData.Id}
                  </div>
                </div>
                <div className="device-modal__content">
                  <div className="device-modal__content__maininfo">
                    <MainInfoField
                      title={"Last inform"}
                      value={`${new Date(
                        deviceData.LastInform,
                      ).toLocaleDateString()}, ${new Date(
                        deviceData.LastInform,
                      ).toLocaleTimeString()}`}
                    />
                    <MainInfoField
                      title={"Serial number"}
                      value={deviceData._deviceId._SerialNumber}
                    />
                    <MainInfoField
                      title={"Product class"}
                      value={deviceData._deviceId._ProductClass}
                    />
                    <MainInfoField
                      title={"OUI"}
                      value={deviceData._deviceId._Manufacturer}
                    />
                    <MainInfoField
                      title={"Manufacturer"}
                      value={deviceData._deviceId._SerialNumber}
                    />
                    <MainInfoField
                      title={"Hardware version"}
                      value={
                        deviceData.InternetGatewayDevice.DeviceInfo
                          .HardwareVersion._value
                      }
                    />
                    <MainInfoField
                      title={"Software version"}
                      value={
                        deviceData.InternetGatewayDevice.DeviceInfo
                          .SoftwareVersion._value
                      }
                    />
                    <MainInfoField
                      title={"MAC"}
                      value={
                        deviceData.InternetGatewayDevice.WANDevice["1"]
                          .WANConnectionDevice["1"].WANIPConnection["1"]
                          .MACAddress._value
                      }
                    />
                    <MainInfoField
                      title={"IP"}
                      value={
                        deviceData.InternetGatewayDevice.WANDevice["1"]
                          .WANConnectionDevice["1"].WANIPConnection["1"]
                          .ExternalIPAddress._value
                      }
                    />
                    <MainInfoField
                      title={"WLAN SSID"}
                      value={
                        deviceData.InternetGatewayDevice.LANDevice["1"]
                          .WLANConfiguration["1"].SSID._value
                      }
                    />
                  </div>
                  <div className="device-modal__content__lanhosts">
                    <div className="device-modal__content__lanhosts__title">
                      LAN hosts
                    </div>
                    <LANHostsTable
                      hostsData={
                        deviceData.InternetGatewayDevice.LANDevice["1"].Hosts
                          .Host
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeviceInfoModal;
