
export default class gatewayActions {
  static SET_GATEWAYS = 'GATEWAY/SET_GATEWAYS';

  static setGateways(gateways) {
    return {
      type: this.SET_GATEWAYS,
      gateways: gateways,
    };
  }
}
