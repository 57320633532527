import React from 'react';
import packageJson from '../../../package.json'

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <nav className="pull-left">
        <ul>
          {/*<li>*/}
          {/*  <a href="https://www.bloxtel.com" target="_blank"> </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#">Bloxtel Inc.</a>*/}
          {/*</li>*/}
        </ul>
      </nav>
      <p className="copyright pull-right">
        Version {packageJson.version} © 2024 <a href="https://www.bloxtel.com">Bloxtel Inc.</a>
      </p>
    </div>
  </footer>
);

export default Footer;
