import React from "react";
import { apModalSchema, apModalUISchema } from "../forms";
import GenericDataView from "../../../components/GenericDataView";

const AccessPointModal = ({ open, setOpen, data }) => {
  return (
    <GenericDataView
      dataName="Access Point"
      open={open}
      data={data}
      setOpen={setOpen}
      readOnly={true}
      superSchema={apModalSchema}
      operatorSchema={apModalSchema}
      editOperatorSchema={apModalSchema}
      editSuperSchema={apModalSchema}
      uiEditSuperSchema={apModalUISchema}
      uiEditOperatorSchema={apModalUISchema}
      uiOperatorSchema={apModalUISchema}
      uiSuperSchema={apModalUISchema}
    />
  );
};

export default AccessPointModal;
