import { cloneDeep } from "lodash/lang";

export let userModalSchema = {
  type: "object",
  required: ["email", "firstName", "lastName", "password", "passwordConfirm"],
  properties: {
    userId: {
      type: "string",
      title: "User ID",
      readOnly: true,
    },
    title: {
      type: "string",
      title: "Title",
      default: "",
    },
    firstName: {
      type: "string",
      title: "First Name",
      default: "",
    },
    lastName: {
      type: "string",
      title: "Last Name",
      default: "",
    },
    email: {
      type: "string",
      format: "email",
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
      minLength: 1,
      title: "Email",
    },
    phoneNumber: {
      type: "string",
      title: "Phone Number",
      default: "",
    },
    password: {
      type: "string",
      title: "Password",
      default: "",
    },
    passwordConfirm: {
      type: "string",
      title: "Confirm Password",
      default: "",
    },
  },
};

export const userModalEditSchema = cloneDeep(userModalSchema);
const passwordIndex = userModalEditSchema.required.indexOf("password");
userModalEditSchema.required.splice(passwordIndex, 1);
const passwordConfirmIndex =
  userModalEditSchema.required.indexOf("passwordConfirm");
userModalEditSchema.required.splice(passwordConfirmIndex, 1);

export const userModalUISchema = {
  userId: {
    "ui:widget": "hidden",
  },
  password: {
    "ui:widget": "password",
  },
  passwordConfirm: {
    "ui:widget": "password",
  },
};
