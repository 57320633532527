import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import ApiHelper from "../../../util/ApiHelper.js";
import SearchBar from '../../../components/FormInputs/SearchBar.js';

import DeviceInfoModal from './DeviceInfoModal.js';


class DevicesOverview extends Component {
  state = {
    deviceModalOpen: false,
    deviceModalData: null,
    devicesData: [],
    searchString: "",
  };

  componentDidMount() {
    this.getDevicesData();
  }

  async getDevicesData() {
    const resStr = await ApiHelper.getFrimwareDevices();
    const result = JSON.parse(resStr);
    this.setState({ devicesData: result || [] });
  }

  openDeviceInfoModal(device) {
    this.setState({
      deviceModalOpen: true,
      deviceModalData: device,
    });
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Listing devices</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                  </div>
                </div>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th>Serial number</th>
                      <th>Product class</th>
                      <th>Software version</th>
                      <th>IP</th>
                      <th>SSID</th>
                      <th>Last inform</th>
                      <th>Tags</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.devicesData ? this.state.devicesData.map(device => 
                    (
                      <tr key={device._id}>
                        <td>{device._deviceId._SerialNumber}</td>
                        <td>{device._deviceId._ProductClass}</td>
                        <td>{device.InternetGatewayDevice.DeviceInfo.SoftwareVersion._value}</td>
                        <td>{device.InternetGatewayDevice.WANDevice["1"].WANConnectionDevice["1"].WANIPConnection["1"].ExternalIPAddress._value}</td>
                        <td>{device.InternetGatewayDevice.LANDevice["1"].WLANConfiguration["1"].SSID._value}</td>
                        <td>{device.LastInform}</td>
                        <td>{device.Tags}</td>
                        <td><Button bsStyle={"primary"} onClick={this.openDeviceInfoModal.bind(this, device)}>View</Button></td>
                      </tr>
                    )) : <tr><td style={{ textAlign: 'center', fontSize: 18, opacity: 0.7 }} colSpan={7}>No records</td></tr>}
                  </tbody>
                </Table>
                <div>
                  <div>0/0  <span style={{opacity: 0.5}}>More</span> <span style={{ marginLeft: 10, color: "blue", cursor: 'pointer'}}>Download</span></div>
                </div>
                <div className='overview__content__btn-group'>
                  <Button bsStyle={"primary"} disabled className='action__button'>Reboot</Button>
                  <Button bsStyle={"primary"} disabled className='action__button'>Reset</Button>
                  <Button bsStyle={"primary"} disabled className='action__button'>Push file</Button>
                  <Button bsStyle={"primary"} disabled className='action__button'>Delete</Button>
                  <Button bsStyle={"primary"} disabled className='action__button'>Tag</Button>
                  <Button bsStyle={"primary"} disabled className='action__button'>Untag</Button>
                </div>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
        <DeviceInfoModal
          open={this.state.deviceModalOpen}
          setOpen={val => this.setState({ deviceModalOpen: val })}
          deviceData={this.state.deviceModalData}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(DevicesOverview));
