import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import PhoneModal from "./components/PhoneModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from '../../components/FormInputs/SearchBar.js';

class PhoneOverview extends Component {
  state = {
    phoneModalOpen: false,
    phoneModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchPhones(this.props.user.operatorId).then();
    ApiHelper.fetchOperators().then();
    ApiHelper.fetchdSIMs().then();
  }

  openAddPhone() {
    this.setState({
      phoneModalOpen: true,
      phoneModalData: null,
    });
  }

  /**
   * @param uuId  {string}
   */
  async openEditPhone(uuId) {
    const phone = await ApiHelper.fetchPhoneById(this.props.user.operatorId, uuId);

    this.setState({
      phoneModalOpen: true,
      phoneModalData: phone,
    });
  }

  async addPhone(phoneData) {
    this.setState({ phoneModalOpen: false });

    if (!await ApiHelper.createPhone(phoneData['operatorId'], phoneData)) {
      alert("Error creating new phone");
    }
    ApiHelper.fetchPhones(this.props.user.operatorId).then();
  }

  /**
   * @param phoneData
   */
  async updatePhone(phoneData) {
    this.setState({ phoneModalOpen: false });

    const result = await ApiHelper.updatePhone(phoneData['operatorId'], phoneData);

    if (!result) {
      alert("Error updating phone: " + phoneData["uuId"]);
    }
    ApiHelper.fetchPhones(this.props.user.operatorId).then();
  }

  /**
  * @param phone  {Phone}
   */
  async deletePhone(phone) {
    if (!window.confirm(`Delete Phone ${phone.uuid}?`))
      return;

    const result = await ApiHelper.deletePhone(this.props.user.operatorId, phone.uuid);
    ApiHelper.fetchPhones().then();
    if (!result) {
      alert("Error deleting phone: " + phone.uuid);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Phones</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                  </div>
                </div>
                <Button bsStyle={"primary"} className="overview__button"
                  onClick={this.openAddPhone.bind(this)}>
                  New Phone
                </Button>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th>IMEI</th>
                      <th>Manufacturer/Model</th>
                      <th colSpan={2}>Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.phones.filter(phone =>
                      phone.imei.includes(this.state.searchString) ||
                      phone.manufacturerName.includes(this.state.searchString) ||
                      phone.modelName.includes(this.state.searchString)
                    ).map(phone => (
                      <tr key={phone.imei}>
                        <td>{phone.imei}</td>
                        <td>{phone.manufacturerName}/{phone.modelName}</td>
                        <td>{phone.createdDate}</td>
                        <td style={{ textAlign: 'center' }}>
                          <Button variant="danger" onClick={this.deletePhone.bind(this, phone)}>Delete</Button>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="info" onClick={this.openEditPhone.bind(this, phone.uuid)}>View</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <PhoneModal open={this.state.phoneModalOpen}
          setOpen={val => this.setState({ phoneModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          phone={this.state.phoneModalData}
          onModify={this.updatePhone.bind(this)}
          onSubmit={this.addPhone.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  phones: state.phone.phones,
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(PhoneOverview));
