import actions from "../actions/gatewayActions";

const initialState = {
  gateways: [],
  gatewaysMap: {},
};

export default function reducer(state = initialState, action) {
  let nextState = { ...state };

  switch (action.type) {
    case actions.SET_GATEWAYS:
      nextState.gateways = action.gateways;
      nextState.gatewaysMap = createGatewaysMap(action.gateways);
      return nextState;

    default:
      return state;
  }
}

function createGatewaysMap(gateways) {
  let gatewaysMap = {};
  gateways.forEach((gateways) => (gatewaysMap[gateways["id"]] = gateways));
  return gatewaysMap;
}
