import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import ApiHelper from "../../util/ApiHelper";
import AppUtils, { formatDate } from "../../util/AppUtils";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import PayoutModal from "./components/PayoutModal";

class PayoutsOverview extends Component {
  state = {
    payoutModalOpen: false,
    payoutModalData: null,
    searchString: "",
    data: [],
  };

  async loadData() {
    await ApiHelper.fetchPayouts(this.props.user.operatorId);
    ApiHelper.fetchOperators().then();
  }
  componentDidMount() {
    this.loadData();
  }

  openAddPayout() {
    this.setState({
      payoutModalOpen: true,
      payoutModalData: null,
    });
  }

  /**
   * @param payoutId  {string}
   */
  async openEditPayout(payoutId) {
    const payout = await ApiHelper.fetchPayoutById(
      this.props.user.operatorId,
      payoutId
    );

    this.setState({
      payoutModalOpen: true,
      payoutModalData: payout,
    });
  }

  async addPayout(payoutData) {
    this.setState({ payoutModalOpen: false });

    if (
      !(await ApiHelper.createPayouts(payoutData["operatorId"], payoutData))
    ) {
      alert("Error creating new payout");
    }
    ApiHelper.fetchPayouts(this.props.user.operatorId).then();
  }

  /**
   * @param payoutData
   */
  async updatePayout(payoutData) {
    this.setState({ payoutModalOpen: false });

    const result = await ApiHelper.updatePayouts(
      payoutData["operatorId"],
      payoutData
    );

    if (!result) {
      alert("Error updating payout: " + payoutData["ueId"]);
    }
    ApiHelper.fetchPayouts(this.props.user.operatorId).then();
  }

  /**
   * @param payout  {payout}
   */
  async deletePayout(payout) {
    if (!window.confirm(`Delete payout ${payout.id}?`)) return;

    const result = await ApiHelper.deletePayouts(
      this.props.user.operatorId,
      payout.id
    );
    ApiHelper.fetchPayouts(this.props.user.operatorId).then();
    if (!result) {
      alert("Error deleting payout: " + payout.id);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Payouts</h4>
                  <div className="overview__searchpanel">
                    <SearchBar
                      value={this.state.searchString}
                      onChange={this.onChangeSearch.bind(this)}
                    />
                  </div>
                </div>
                {this.props.user.role === "Admin" && (
                  <Button
                    bsStyle={"primary"}
                    className="overview__button"
                    onClick={this.openAddPayout.bind(this)}
                  >
                    New payout
                  </Button>
                )}
              </div>
              <div className="content overview__content">
                <Table
                  className="overview__table"
                  striped
                  bordered
                  condensed
                  hover
                >
                  <thead>
                    <tr>
                      <th>Payer Name</th>
                      <th>Payee Name</th>
                      <th>Payout Type</th>
                      <th>Currency</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th colSpan={2}>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.payouts
                      .filter(
                        (payout) =>
                          payout.payeeName.includes(this.state.searchString) ||
                          payout.payerName.includes(this.state.searchString) ||
                          payout.payoutType.includes(this.state.searchString)
                      )
                      .map((payout) => (
                        <tr key={payout.payerName}>
                          <td>{payout.payerName}</td>
                          <td>{payout.payeeName}</td>
                          <td>{payout.payoutType}</td>
                          <td>{payout.payoutAmountCurrency}</td>
                          <td>{payout.payoutAmount}</td>
                          <td>
                            <span
                              style={{
                                color: AppUtils.getStatusTextColor(
                                  payout.status
                                ),
                              }}
                            >
                              {payout.status}
                            </span>
                          </td>
                          <td>{formatDate(payout.createdDate)}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button
                              variant="info"
                              onClick={this.openEditPayout.bind(
                                this,
                                payout.id
                              )}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <PayoutModal
          open={this.state.payoutModalOpen}
          setOpen={(val) => this.setState({ payoutModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          payout={this.state.payoutModalData}
          onModify={this.updatePayout.bind(this)}
          onSubmit={this.addPayout.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  payouts: state.payout.payouts,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(PayoutsOverview));
