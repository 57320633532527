
export default class walletActions {
  static SET_WALLETS = 'WALLET/SET_WALLETS';

  static setWallets(wallets) {
    return {
      type: this.SET_WALLETS,
      wallets: wallets,
    };
  }
}
