import React from 'react';
import {Route} from 'react-router-dom';
import ChargingTable from "./ChargingTable";

const Charging = ({match}) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={ChargingTable} />
  </div>
);

export default Charging;
