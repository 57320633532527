import React from "react";
import { useAppKit } from "@reown/appkit/react";

export const ConnectButton = () => {
  const { open, close } = useAppKit();

  return (
    <button
      className="bg-[#7769f9] text-white px-4 py-2 rounded-md w-full"
      style={{
        fontWeight: "500",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
      }}
      onClick={open}
    >
      <i className="fas fa-wallet"></i>
      Connect Wallet
    </button>
  );
};
