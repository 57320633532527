import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { LogViewer, LogViewerSearch } from "@patternfly/react-log-viewer";
import {
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Tooltip,
} from "@patternfly/react-core";
import DownloadIcon from "@patternfly/react-icons/dist/esm/icons/download-icon";
import "@patternfly/react-core/dist/styles/base.css";
import GatewayModal from "./components/GatewayModal";
import ApiHelper from "../../util/ApiHelper";
import AppUtils, { formatDate } from "../../util/AppUtils";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import Directory from "./routes/directory/directory";
import {
  CloudRegistrarMockupData,
  CloudVerifierMockupData,
} from "../../config/keylimeMockupData";

const VIEW_TYPE = {
  FILE_VIEW: "FILE_VIEW",
  LOGS_VIEW: "LOGS_VIEW",
  MAIN_VIEW: "MAIN_VIEW",
};

class GatewayOverview extends Component {
  state = {
    gatewayModalOpen: false,
    gatewayModalData: null,
    viewType: VIEW_TYPE.MAIN_VIEW,
    searchString: "",
    logContent: "",
    gatewayId: "",
    fileName: "",
    apData: [
      {
        APID: "AP001",
        Latitude: "37.7749",
        Longitude: "-122.4194",
        Altidude: "50m",
        APType: "RAKAllInOne",
        GNBType: "RAKAllInOne",
        GNBID: "191",
        TAC: 1,
        Band: 48,
        AMFID: "4",
        MCC: "315",
        MNC: "010",
        SST: 1,
        SD: 16777215,
        PCI: 0,
      },
      // Agregar más objetos aquí si es necesario
    ],
  };

  async readFile(name) {
    const res = await ApiHelper.getRemoteLoggingServerIP();
    const remoteLoggingServerIP = `http://${res.serverIP}:${res.serverPort}`;

    const response = await fetch(
      remoteLoggingServerIP + `/api/retrievelos/${name}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    const json = await response.json();

    this.setState({
      logContent: json.fileContent,
    });
  }

  async updateGatewaysList() {
    const res = await ApiHelper.fetchGateways(
      this.props.user.operatorId
    ).then();

    const registrarList = CloudRegistrarMockupData.results.uuids;
    const verifierList = CloudVerifierMockupData.results.uuids;

    if (res) {
      this.props.gateways.forEach(async (gateway, index) => {
        let currentStatus = "UNPROVISIONED";
        const isRegistered =
          registrarList.filter((id) => id === gateway.id).length > 0;
        const isVerified =
          verifierList.filter((id) => id === gateway.id).length > 0;

        if (!isRegistered && !isVerified) {
          return;
        }

        if (isRegistered) {
          currentStatus = "PROVISIONED";
        }

        if (isVerified) {
          currentStatus = "TRUSTED";
        }

        const cgateway = await ApiHelper.fetchGatewayById(
          this.props.user.operatorId,
          gateway.id
        );
        cgateway.status = currentStatus;

        await ApiHelper.updateGateway(this.props.user.operatorId, cgateway);
        ApiHelper.fetchGateways(this.props.user.operatorId).then();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line
      let gatewayId = this.props.match.url.replace(/^.*[\\\/]/, "");

      if (gatewayId.includes("log_")) {
        this.setState({
          viewType: VIEW_TYPE.LOGS_VIEW,
          fileName: gatewayId.replace("log_", ""),
        });

        this.readFile(gatewayId.replace("log_", ""));
      }
    }
  }

  componentDidMount() {
    // eslint-disable-next-line
    let gatewayId = this.props.match.url.replace(/^.*[\\\/]/, "");
    if (gatewayId === "gateways") {
      this.setState({
        viewType: VIEW_TYPE.MAIN_VIEW,
      });

      ApiHelper.fetchOperators().then();

      this.updateGatewaysList();
    } else if (gatewayId === "logs") {
      this.setState({
        viewType: VIEW_TYPE.LOGS_VIEW,
      });
    } else {
      this.setState({
        gatewayId: gatewayId,
        viewType: VIEW_TYPE.FILE_VIEW,
      });
    }
  }

  openAddGateway() {
    this.setState({
      gatewayModalOpen: true,
      gatewayModalData: null,
    });
  }

  openLogsView(id) {
    this.props.history.push(`/gateways/${id}`);
  }

  async openEditGateway(gatewayId) {
    const gateway = await ApiHelper.fetchGatewayById(
      this.props.user.operatorId,
      gatewayId
    );

    this.setState({
      gatewayModalOpen: true,
      gatewayModalData: gateway,
    });
  }

  async addGateway(gatewayData) {
    this.setState({ gatewayModalOpen: false });

    if (
      !(await ApiHelper.createGateway(gatewayData["operatorId"], gatewayData))
    ) {
      alert("Error creating new gateway");
    }

    this.updateGatewaysList();
  }

  async updateGateway(gatewayData) {
    this.setState({ gatewayModalOpen: false });

    const result = await ApiHelper.updateGateway(
      gatewayData["operatorId"],
      gatewayData
    );

    if (!result) {
      alert("Error updating gateway: " + gatewayData["ueId"]);
    }
    ApiHelper.fetchGateways(this.props.user.operatorId).then();
  }

  async deleteGateway(gateway) {
    if (!window.confirm(`Delete Gateway ${gateway.id}?`)) return;

    const result = await ApiHelper.deleteGateway(
      this.props.user.operatorId,
      gateway.id
    );
    ApiHelper.fetchGateways(this.props.user.operatorId).then();
    if (!result) {
      alert("Error deleting gateway: " + gateway.id);
    }
  }

  openFirmware() {
    this.props.history.push("/firmware/devices");
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    const { apData, searchString } = this.state;
    const filteredAPData = apData.filter(
      (ap) =>
        ap.APID.includes(searchString) ||
        ap.APType.includes(searchString) ||
        ap.GNBType.includes(searchString)
    );

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              {this.state.viewType === VIEW_TYPE.FILE_VIEW && (
                <>
                  <div className="header overview__header">
                    <div className="overview__searchbar">
                      <span className="overview_backBtn">
                        <i
                          onClick={this.onClickBackGatewayList.bind(this)}
                          className="fas fa-chevron-left"
                        />
                      </span>
                      <h4>Access Gateway: {this.state.gatewayId}</h4>
                    </div>
                  </div>
                  <Directory path="/" />
                </>
              )}
              {this.state.viewType === VIEW_TYPE.LOGS_VIEW && (
                <div>
                  <div className="header overview__header">
                    <div className="overview__searchbar">
                      <span className="overview_backBtn">
                        <i
                          onClick={this.onClickBackFileSystem.bind(this)}
                          className="fas fa-chevron-left"
                        />
                      </span>
                      <h4>Access Gateway: {this.state.gatewayId}</h4>
                      <div style={{ opacity: 0.8 }}>{this.state.fileName}</div>
                    </div>
                  </div>
                  {this.state.logContent ? (
                    <div className="dsims__logs">
                      <LogViewer data={this.state.logContent} theme="dark" />
                    </div>
                  ) : (
                    <h4 style={{ marginLeft: 25 }}>No logs</h4>
                  )}
                </div>
              )}
              {this.state.viewType === VIEW_TYPE.MAIN_VIEW && (
                <>
                  <div className="header overview__header">
                    <div className="flex  items-center gap-8">
                      <h4>Access Points</h4>
                      <SearchBar
                        value={searchString}
                        onChange={this.onChangeSearch.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="content overview__content">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>APID</th>
                          <th>Latitude</th>
                          <th>Longitude</th>
                          <th>Altitude</th>
                          <th>APType</th>
                          <th>GNBType</th>
                          <th>GNBID</th>
                          <th>TAC</th>
                          <th>Band</th>
                          <th>AMFID</th>
                          <th>MCC</th>
                          <th>MNC</th>
                          <th>PCI</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredAPData.map((ap, index) => (
                          <tr key={index}>
                            <td>{ap.APID}</td>
                            <td>{ap.Latitude}</td>
                            <td>{ap.Longitude}</td>
                            <td>{ap.Altidude}</td>
                            <td>{ap.APType}</td>
                            <td>{ap.GNBType}</td>
                            <td>{ap.GNBID}</td>
                            <td>{ap.TAC}</td>
                            <td>{ap.Band}</td>
                            <td>{ap.AMFID}</td>
                            <td>{ap.MCC}</td>
                            <td>{ap.MNC}</td>
                            <td>{ap.PCI}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <GatewayModal
          open={this.state.gatewayModalOpen}
          setOpen={(val) => this.setState({ gatewayModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          gateway={this.state.gatewayModalData}
          onModify={this.updateGateway.bind(this)}
          onSubmit={this.addGateway.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gateways: state.gateway.gateways,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(GatewayOverview));
