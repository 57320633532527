import {RJSFSchema} from "@rjsf/utils";
import {cloneDeep} from "lodash/lang";
import {operatorIdWidget, orderIdWidget, qrWidget, subscriberIdWidget} from "../../components/FormWidgets";
import DSIMLogo from "../../assets/images/dsim_logo.png";

export const policySchema : RJSFSchema = {
    type: "object",
    properties: {
        sliceConfigurations: {
            type: "array",
            title: "S-NSSAI Configuration",
            items: { $ref: "#/definitions/SliceConfiguration" },
            minItems: 1,
            default: [
                {
                    snssai: {
                        "sst": 1,
                        "sd": "010203",
                        "isDefault": true,
                        "chargingMethod": "Online",
                        "quota": 100000,
                        "unitCost": 1
                    },
                    dnnConfigurations: [
                        {
                            dnn: "internet",
                            uplinkAmbr: "200 Mbps",
                            downlinkAmbr: "100 Mbps",
                            "5qi": 9,
                        }
                    ]
                },
            ],
        },
    },
    definitions: {
        Snssai: {
            type: "object",
            required: ["sst", "sd", "quota", "unitCost"],
            properties: {
                sst: {
                    type: "integer",
                    title: "SST",
                    minimum: 0,
                    maximum: 255,
                },
                sd: {
                    type: "string",
                    title: "SD",
                    pattern: "^[A-Fa-f0-9]{6}$",
                },
                isDefault: {
                    type: "boolean",
                    title: "Default S-NSSAI",
                    default: false,
                },
                chargingMethod: {
                    type: "string",
                    title: "Charging Method",
                    default: "Online",
                    enum: ["Offline", "Online"]
                },
                quota: {
                    type: "integer",
                    title: "Quota (USD)",
                    minimum: 0
                },
                unitCost: {
                    type: "integer",
                    title: "USD per GB",
                    minimum: 0
                },
            },
        },
        SliceConfiguration: {
            type: "object",
            properties: {
                snssai: {
                    $ref: "#/definitions/Snssai"
                },
                dnnConfigurations: {
                    type: "array",
                    title: "DNN Configurations",
                    items: { $ref: "#/definitions/DnnConfiguration" },
                    minItems: 1,
                },
            },
        },
        DnnConfiguration: {
            type: "object",
            required: ["dnn", "uplinkAmbr", "downlinkAmbr"],
            properties: {
                dnn: {
                    type: "string",
                    title: "Data Network Name"
                },
                uplinkAmbr: {
                    $ref: "#/definitions/bitRate",
                    title: "Uplink AMBR",
                    default: "1000 Kbps"
                },
                downlinkAmbr: {
                    $ref: "#/definitions/bitRate",
                    title: "Downlink AMBR",
                    default: "1000 Kbps"
                },
                "5qi": {
                    type: "integer",
                    minimum: 0,
                    maximum: 255,
                    title: "Default 5QI"
                },
                flowRules: {
                    type: "array",
                    items: { $ref: "#/definitions/FlowInformation" },
                    maxItems: 1,
                    title: "Flow Rules"
                },
                upSecurityChk: {
                    "type": "boolean",
                    title: "UP Security",
                    "default": false
                },
            },
            "dependencies": {
                upSecurityChk: {
                    "oneOf": [
                        {
                            "properties": {
                                upSecurityChk: {
                                    "enum": [false]
                                }
                            },
                        },
                        {
                            "properties": {
                                upSecurityChk: {
                                    "enum": [true]
                                },
                                upIntegrity: {
                                    type: "string",
                                    title: "Integrity of UP Security",
                                    enum: ["NOT_NEEDED", "PREFERRED", "REQUIRED"],
                                },
                                upConfidentiality: {
                                    type: "string",
                                    title: "Confidentiality of UP Security",
                                    enum: ["NOT_NEEDED", "PREFERRED", "REQUIRED"],
                                },
                            },
                            "required": [
                                "upIntegrity",
                                "upConfidentiality"
                            ]
                        }
                    ]
                }
            },
        },
        FlowInformation: {
            type: "object",
            required: ["5qi", "filter", "precedence", "unitCost"],
            properties: {
                filter: {
                    $ref: "#/definitions/IPFilter",
                    title: "IP Filter"
                },
                precedence: {
                    type: "integer",
                    title: "Precedence",
                    minimum: 1,
                    maximum: 256,
                    default: 128,
                },
                "5qi": {
                    type: "integer",
                    minimum: 0,
                    maximum: 255,
                    title: "5QI",
                    default: 9,
                },
                gbrUL: {
                    $ref: "#/definitions/bitRate",
                    title: "Uplink GBR",
                },
                gbrDL: {
                    $ref: "#/definitions/bitRate",
                    title: "Downlink GBR",
                },
                mbrUL: {
                    $ref: "#/definitions/bitRate",
                    title: "Uplink MBR",
                },
                mbrDL: {
                    $ref: "#/definitions/bitRate",
                    title: "Downlink MBR",
                },
                chargingMethod: {
                    type: "string",
                    title: "Charging Method",
                    default: "Online",
                    enum: ["Offline", "Online"]
                },
                quota: {
                    type: "integer",
                    title: "Quota (monetary)",
                    minimum: 0,
                    default: 10000
                },
                unitCost: {
                    type: "integer",
                    title: "Unit Cost (money per byte)",
                    default: 1,
                    minimum: 0
                }
            }
        },
        IPFilter: {
            type: "string",
        },
        bitRate: {
            type: "string",
            pattern: "^[0-9]+(\\.[0-9]+)? (bps|Kbps|Mbps|Gbps|Tbps)$"
        },
    },
};

export const policyUISchema = {
    sliceConfigurations: {
        "ui:options": {
            "orderable": false
        },
        "isDefault": {
            //"ui:widget": "radio",
        },
        "dnnConfigurations": {
            "ui:options": {
                "orderable": false
            },
            "flowRules": {
                "ui:options": {
                    "orderable": false
                },
            }
        },
        "nav": "Policies"
    },
};

export const tokenDataModalSchema = {
    type: "object",
    title: "Token Data",
    properties: {
        tokenAddress: {
            type: "string",
            title: "Token Address",
            default: "",
            readOnly: true,
        },
        smartContractTokenId: {
            type: "string",
            title: "Smart Contract Token ID",
            default: "",
            readOnly: true,
        },
        tokenId: {
            type: "string",
            title: "Inner Token ID",
            default: "",
            readOnly: true,
        },
        tokenType: {
            type: "string",
            title: "Token Tasdasdype",
            default: "",
            readOnly: true,
        },
        smartContract: {
            type: "string",
            title: "Smart Contract",
            default: "",
            readOnly: true,
        },
        ownerID: {
            type: "string",
            title: "Operator ID",
            default: "",
            readOnly: true
        },
        ownerType: {
            type: "string",
            title: "Owner Type",
            default: "",
            readOnly: true,
        },
        artworkLink: {
            type: "string",
            title: "Artwork",
            default: "",
            readOnly: true,
        },
        qrCode: {
            type: "string",
            title: "QR Code",
            default: "",
            readOnly: true
        }
    }
}

export const tokenDataModalUISchema = {
    tokenId: {
        "nav": "Token",
    },
    tokenType: {
        "nav": "Token",
    },
    balance: {
        "nav": "Token",
    },
    smartContract: {
        "nav": "Token",
    },
    ownerID: {
        "nav": "Token",
    },
    ownerType: {
        "nav": "Token",
    },
    artworkLink: {
        "nav": "Token",
    },
    qrCode: {
        "nav": "Token",
    }
}

export const dSIMBatchSchema = {
    type: "object",
    required: [
        "orderName",
        "batchQuantity",
        "servingPlmnId"
    ],
    properties: {
        orderName: {
            type: "string",
            title: "Order Name",
            default: ""
        },
        orderId: {
            type: "string",
            title: "Order ID",
            default: "",
            readOnly: true
        },
        batchQuantity: {
            type: "integer",
            default: 1,
            minimum: 1,
            title: "Batch Quantity",
        },
        servingPlmnId: {
            type: "string",
            default: "",
            pattern: "^[0-9]*$",
            "minLength": 5,
            "maxLength": 6,
            title: "Serving PLMN ID",
        },
    },
};

export const dsimBatchEditSchema = cloneDeep(dSIMBatchSchema);
dsimBatchEditSchema.properties.batchQuantity.readOnly = true
dsimBatchEditSchema.properties.orderName.readOnly = true

export const dSIMBatchUISchema = {
    batchQuantity: {
        "nav": "Batch",
    },
    servingPlmnId: {
        "nav": "Batch",
    },
    orderId:
        {"nav": "Batch"}
}
dSIMBatchUISchema.orderName = orderIdWidget(false);
dSIMBatchUISchema.orderName.nav = "Batch"


export const dSIMSchema = {
    type: "object",
    required: [
        "iccid",
        "imsi",
        "servingPlmnId",
        "dsimType",
        "authMethod",
    ],
    properties: {
        dsimId: {
            type: "string",
            title: "dSIM ID",
            pattern: "^[0-9a-zA-Z-]*$",
            default: "",
            readOnly: true,
        },
        iccid: {
            type: "string",
            title: "ICCID",
            pattern: "^[0-9]*$",
            minLength: 19,
            maxLength: 20,
            default: "",
        },
        imsi: {
            type: "string",
            default: "",
            pattern: "^[0-9]*$",
            length: 15,
            title: "IMSI",
        },
        servingPlmnId: {
            type: "string",
            default: "",
            pattern: "^[0-9]*$",
            "minLength": 5,
            "maxLength": 6,
            title: "Serving PLMN ID",
        },
        dsimType: {
            type: "string",
            title: "dSIM Type",
            default: "ELECTRONIC",
            enum: ["PHYSICAL", "ELECTRONIC"],
        },
        authMethod: {
            type: "string",
            title: "Authentication Mode",
            default: "6G_AKA",
            enum: ["6G_AKA", "5G_AKA"],
        },
        activationCode: {
            type: "string",
            title: "Activation Code",
            default: ""
        }
    },
};

export const dSIMSuperSchema = cloneDeep(dSIMSchema);
dSIMSuperSchema.required.push("operatorName");
dSIMSuperSchema.properties.operatorId = {
    type: "string",
    title: "Operator Name",
    default: ""
}
dSIMSuperSchema.properties.operatorId = {
    type: "string",
    title: "Operator ID",
    default: "",
    readonly: true
}
dSIMSuperSchema.properties.sliceConfigurations = policySchema.properties.sliceConfigurations
dSIMSuperSchema.definitions = policySchema.definitions

export const dSIMEditSchema = cloneDeep(dSIMSchema);
dSIMEditSchema.properties.iccid.readOnly = true
dSIMEditSchema.properties.dsimType.readOnly = true
dSIMEditSchema.properties.authMethod.readOnly = true
dSIMEditSchema.properties.imsi.readOnly = true
dSIMEditSchema.properties.activationCode.readOnly = true
dSIMEditSchema.properties.tokenData = tokenDataModalSchema
dSIMEditSchema.properties.subscriberName = {
    type: "string",
    title: "Subscriber Name",
    default: "",
    readOnly: true
}
dSIMEditSchema.properties.subscriberId = {
    type: "string",
    title: "Subscriber ID",
    default: "",
    readOnly: true
}
dSIMEditSchema.properties.orderName = {
    type: "string",
    title: "Order Name",
    default: "",
    readOnly: true
}
dSIMEditSchema.properties.orderId = {
    type: "string",
    title: "Order ID",
    default: "",
    readOnly: true
}
dSIMEditSchema.properties.sliceConfigurations = policySchema.properties.sliceConfigurations
dSIMEditSchema.definitions = policySchema.definitions

export const dSIMSubscriberEditSchema = cloneDeep(dSIMEditSchema);
delete dSIMSubscriberEditSchema.properties.sliceConfigurations
delete dSIMSubscriberEditSchema.definitions
delete dSIMSubscriberEditSchema.properties.orderId
delete dSIMSubscriberEditSchema.properties.orderName

export const dSIMSuperEditSchema = cloneDeep(dSIMEditSchema);
dSIMSuperEditSchema.properties.operatorName = {
    type: "string",
    title: "Operator Name",
    default: "",
    readOnly: true
}
dSIMSuperEditSchema.properties.operatorId = {
    type: "string",
    title: "Operator ID",
    default: "",
    readOnly: true
}

export const dSIMUISchema = {
    dsimId: {
        "nav": "dSIM"
    },
    iccid: {
        "nav": "dSIM",
    },
    imsi: {
        "nav": "dSIM",
    },
    servingPlmnId: {
        "nav": "dSIM",
    },
    dsimType: {
        "nav": "dSIM",
    },
    authMethod: {
        "nav": "dSIM",
    },
    activationCode: {
        "nav": "dSIM",
    }
}
dSIMUISchema.sliceConfigurations = policyUISchema.sliceConfigurations

export const dSIMSuperUISchema = cloneDeep(dSIMUISchema);
dSIMSuperUISchema.operatorName = operatorIdWidget(false)
dSIMSuperUISchema.operatorName.nav = "dSIM"
dSIMSuperUISchema.operatorId = {"nav": "dSIM"}
dSIMSuperUISchema.activationCode = qrWidget(DSIMLogo)

export const dSIMEditUISchema = cloneDeep(dSIMUISchema);
dSIMEditUISchema.subscriberName = subscriberIdWidget(true)
dSIMEditUISchema.subscriberName.nav = "dSIM"
dSIMEditUISchema.subscriberId = {"nav": "dSIM"}
dSIMEditUISchema.orderName = orderIdWidget(true)
dSIMEditUISchema.orderName.nav = "dSIM"
dSIMEditUISchema.orderId = {"nav": "dSIM"}
dSIMEditUISchema.tokenData = {
    qrCode: qrWidget(),
    "nav": "Token"
}

export const dSIMSuperEditUISchema = cloneDeep(dSIMEditUISchema);
dSIMSuperEditUISchema.operatorName = operatorIdWidget(true)
dSIMSuperEditUISchema.operatorName.nav = "dSIM"
dSIMSuperEditUISchema.operatorId = {"nav": "dSIM"}

export const dSIMSubscriberEditUISchema = cloneDeep(dSIMEditUISchema)
delete dSIMSubscriberEditUISchema.operatorId
delete dSIMSubscriberEditUISchema.orderName
delete dSIMSubscriberEditUISchema.orderId
delete dSIMSubscriberEditUISchema.orderName
delete dSIMSubscriberEditUISchema.subscriberId
delete dSIMSubscriberEditUISchema.subscriberName

export let transferModalSchema = {
    type: "object",
    required: [
        "sourceChain",
        "destChain",
    ],
    properties: {
        sourceChain: {
            type: "string",
            title: "Source Blockchain",
            default: "Fabric",
            enum: ["Fabric", "Ethereum"],
            readOnly: true,
        },
        destChain: {
            type: "string",
            title: "Destination Blockchain",
            default: "Fabric",
            enum: ["Fabric", "Ethereum"],
        },
    },
};

export let downloadModalSchema = {
    type: "object",
    required: [
      "subscriber",
      ],
    properties: {
      subscriber: {
        type: "string",
        title: "Subscriber name",
        enum: [],
        default: undefined,
      },
    },
  };  

export let transferModalUISchema = {
    // fromId: {
    //   "ui:widget": withRouter(connect(mapStateToProps)((props) => {
    //     const onChangeOwnerID = (event) => {
    //       props.onChange(event.target.value);
    //     };

    //     return (
    //       <select onChange={onChangeOwnerID} value={props.value} id="root_ownerID" className='form-control' required>
    //         <option key="empty" value=""></option>
    //         {props.operators.map(operator => (
    //           <option key={operator.id} value={`${operator.id}____${operator.name}`}>
    //             {operator.id}____{operator.name}
    //           </option>
    //         ))}
    //       </select>
    //     );
    //   })),
    // },
    // toId: {
    //   "ui:widget": withRouter(connect(mapStateToProps)((props) => {
    //     const onChangeOwnerID = (event) => {
    //       props.onChange(event.target.value);
    //     };

    //     return (
    //       <select onChange={onChangeOwnerID} value={props.value} id="root_ownerID" className='form-control' required>
    //         <option key="empty" value=""></option>
    //         {props.operators.map(operator => (
    //           <option key={operator.id} value={`${operator.id}____${operator.name}`}>
    //             {operator.id}____{operator.name}
    //           </option>
    //         ))}
    //       </select>
    //     );
    //   })),
    // }
}
