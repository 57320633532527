import React from "react";

export function UriWidget(props) {
  // Don't render anything if value is empty, invalid or contains unwanted URLs
  if (
    !props.value ||
    props.value.includes("918100tae040813011.local") ||
    !props.value.startsWith("http")
  ) {
    return null;
  }

  return (
    <div className="form-group">
      <div className="uri-widget">
        <a
          href={props.value}
          id="explorer-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            // Verify URL is valid before opening
            if (!props.value.match(/^(http|https):\/\/[^ "]+$/)) {
              e.preventDefault();
              return false;
            }
          }}
        >
          View Details
        </a>
      </div>
    </div>
  );
}
