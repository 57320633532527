import React from 'react';
import {Route} from 'react-router-dom';
import CameraOverview from "./CameraOverview";

const Cameras = ({match}) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={CameraOverview} />
  </div>
);

export default Cameras;
