
export default class operatorActions {
  static SET_OPERATORS = 'OPERATOR/SET_OPERATORS';

  static setOperators(operators) {
    return {
      type: this.SET_OPERATORS,
      operators: operators,
    };
  }
}
