import React, { useState } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import { setMobileNavVisibility } from "../../redux/reducers/layout";

//import '../../assets/styles/base.scss';

import Header from "./Header";
import Footer from "./Footer";
import SideBar from "../../components/SideBar";
import SideBarMobile from "../../components/SideBar/mobile";
/**
 * Pages
 */
import dSIMs from "../dSIMs";
import Tasks from "../Tasks";
import UEInfo from "../Dashboard";
import UEInfoDetail from "../UEInfoDetail";
import Gateways from "../Gateways";
import Cells from "../Cells";
import Map from "../Map";
import Orders from "../Orders";
import Phones from "../Phones";
import Cameras from "../Cameras";
import Plans from "../Plans";
import Wallets from "../Wallets";
import Invoices from "../Invoices";
import Payouts from "../Payouts";
import Blocks from "../Blocks";
import Operators from "../Operators";
import Subscribers from "../Subscribers";
import Charging from "../Charging";
import Users from "../Users";
import Analytics from "../Analytics";
import Devices from "../Firmware/Devices";
import Logging from "../Cameras/Logging";
import { Navbar } from "../../components/Navbar/index.jsx";

const Main = ({ mobileNavVisibility, hideMobileMenu, isLoggedIn, history }) => {
  const [sidebarOpened, setSidebarOpened] = useState(true);

  if (!isLoggedIn) {
    return null;
  }

  history.listen(() => {
    if (mobileNavVisibility === true) {
      hideMobileMenu();
    }
  });

  return (
    <div
      className={cx({
        "nav-open": mobileNavVisibility === true,
      })}
    >
      <div className="wrapper">
        <div className="close-layer" onClick={hideMobileMenu} />
        <SideBar
          sidebarOpened={sidebarOpened}
          setSidebarOpened={setSidebarOpened}
        />

        <SideBarMobile
          sidebarOpened={sidebarOpened}
          setSidebarOpened={setSidebarOpened}
        />

        <div
          className={`main-panel ${!sidebarOpened ? "main-panel__hide" : ""}`}
        >
          <Navbar
            setSidebarOpened={setSidebarOpened}
            sidebarOpened={sidebarOpened}
          />
          <Route exact path="/" component={UEInfo} />
          <Route exact path="/dsim" component={dSIMs} />
          <Route exact path="/tasks" component={Tasks} />
          <Route exact path="/ueinfo" component={UEInfo} />
          <Route exact path="/ueinfo/:id" component={UEInfoDetail} />
          <Route exact path="/gateways" component={Gateways} />
          <Route exact path="/gateways/:id" component={Gateways} />
          <Route exact path="/cells" component={Cells} />
          <Route exact path="/firmware/devices" component={Devices} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/charging" component={Charging} />
          <Route exact path="/phones" component={Phones} />
          <Route exact path="/cameras" component={Cameras} />
          <Route exact path="/cameras/logging" component={Logging} />
          <Route exact path="/plans" component={Plans} />
          <Route exact path="/wallets" component={Wallets} />
          <Route exact path="/invoices" component={Invoices} />
          <Route exact path="/payouts" component={Payouts} />
          <Route exact path="/blocks" component={Blocks} />
          <Route exact path="/operators" component={Operators} />
          <Route exact path="/operators/:id" component={Operators} />
          <Route exact path="/subscribers" component={Subscribers} />
          <Route exact path="/analytics" component={Analytics} />
          <Route exact path="/users/:id" component={Users} />
          <Route exact path="/users" component={Users} />
          <Footer />
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => ({
  mobileNavVisibility: state.layout.mobileNavVisibility,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
