import React, { Component } from 'react';
import { Modal, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import SearchBar from '../../../components/FormInputs/SearchBar';

class MeasurementsModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    measurements: PropTypes.object,
  };

  state = {
    rerenderCounter: 0,
    searchString: "",
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

    onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"coverate-map theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Measurements</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header overview__header">
                    <div className="overview__searchbar">
                      <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                    </div>
                  </div>
                  <div className="content overview__content">
                    <Table className="overview__table" striped bordered condensed hover>
                      <thead>
                        <tr>
                          <th>Cell ID</th>
                          <th>PLMN</th>
                          <th>RSRP</th>
                          <th>RSRQ</th>
                          <th>SINR</th>
                          <th>Quality</th>
                          <th>Measurement Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.measurements && this.props.measurements.filter(measurement =>
                          measurement.cellID.includes(this.state.searchString) ||
                          measurement.plmn.includes(this.state.searchString) ||
                          measurement.rsrp.includes(this.state.searchString) ||
                          measurement.rsrq.includes(this.state.searchString) ||
                          measurement.sinr.includes(this.state.searchString) ||
                          measurement.quality.includes(this.state.searchString)
                        ).map(measurement => (
                          <tr key={measurement.cellID}>
                            <td>{measurement.cellID}</td>
                            <td>{measurement.plmn}</td>
                            <td>{measurement.rsrp}</td>
                            <td>{measurement.rsrq}</td>
                            <td>{measurement.sinr}</td>
                            <td>{measurement.quality}</td>
                            <td>{measurement.measurementDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <p>&nbsp;</p><p>&nbsp;</p>
                    <p>&nbsp;</p><p>&nbsp;</p>
                    <p>&nbsp;</p><p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  orders: state.order.orders,
});

export default withRouter(connect(mapStateToProps)(MeasurementsModal));
