import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import PlanModal from "./components/PlanModal";
import ApiHelper from "../../util/ApiHelper";
import AppUtils from '../../util/AppUtils';
import SearchBar from '../../components/FormInputs/SearchBar.js';

class PlanOverview extends Component {
  state = {
    planModalOpen: false,
    planModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchPlans(this.props.user.operatorId).then();
    ApiHelper.fetchOperators().then();
  }

  openAddPlan() {
    this.setState({
      planModalOpen: true,
      planModalData: null,
    });
  }

  /**
   * @param planId  {string}
   */
  async openEditPlan(planId) {
    const plan = await ApiHelper.fetchPlanById(this.props.user.operatorId, planId);

    this.setState({
      planModalOpen: true,
      planModalData: plan,
    });
  }

  async addPlan(planData) {
    this.setState({ planModalOpen: false });

    if (!await ApiHelper.createPlan(planData['operatorId'], planData)) {
      alert("Error creating new plan");
    }
    ApiHelper.fetchPlans(this.props.user.operatorId).then();
  }

  /**
   * @param planData
   */
  async updatePlan(planData) {
    this.setState({ planModalOpen: false });

    const result = await ApiHelper.updatePlan(planData['operatorId'], planData);

    if (!result) {
      alert("Error updating plan: " + planData["ueId"]);
    }
    ApiHelper.fetchPlans(this.props.user.operatorId).then();
  }

  /**
  * @param plan  {Plan}
   */
  async deletePlan(plan) {
    if (!window.confirm(`Delete Plan ${plan.id}?`))
      return;

    const result = await ApiHelper.deletePlan(this.props.user.operatorId, plan.id);
    ApiHelper.fetchPlans(this.props.user.operatorId).then();
    if (!result) {
      alert("Error deleting plan: " + plan.id);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Plans</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                  </div>
                </div>
                {this.props.user.role !== "SUBSCRIBER" &&
                  <Button bsStyle={"primary"} className="overview__button"
                    onClick={this.openAddPlan.bind(this)}>
                    New Plan
                  </Button>
                }
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th style={{ width: 400 }}>ID</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th colSpan={2}>Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.plans.filter(plan =>
                      plan.id.includes(this.state.searchString) ||
                      plan.name.includes(this.state.searchString) ||
                      plan.status.includes(this.state.searchString)
                    ).map(plan => (
                      <tr key={plan.id}>
                        <td>{plan.id}</td>
                        <td>{plan.name}</td>
                        <td><span style={{color: AppUtils.getStatusTextColor(plan.status)}}>{plan.status}</span></td>
                        <td>{plan.createdDate}</td>
                        <td style={{ textAlign: 'center' }}>
                          <Button variant="danger" onClick={this.deletePlan.bind(this, plan)}>Delete</Button>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="info" onClick={this.openEditPlan.bind(this, plan.id)}>View</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <PlanModal open={this.state.planModalOpen}
          setOpen={val => this.setState({ planModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          plan={this.state.planModalData}
          onModify={this.updatePlan.bind(this)}
          onSubmit={this.addPlan.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  plans: state.plan.plans,
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(PlanOverview));
