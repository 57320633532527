import actions from '../actions/phoneActions';

const initialState = {
  phones: [],
  phonesMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_PHONES:
      nextState.phones = action.phones;
      nextState.phonesMap = createPhonesMap(action.phones);
      return nextState;

    default:
      return state;
  }
}

function createPhonesMap(phones) {
  let phonesMap = {};
  phones.forEach(phones => phonesMap[phones['id']] = phones);
  return phonesMap;
}
