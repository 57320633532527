import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Form from '@rjsf/core';
import applyNav from 'rjsf-tabs/lib/applyNav'
import validator from '@rjsf/validator-ajv8';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  dSIMSchema,
  dSIMUISchema,
  dSIMEditSchema,
  dSIMSuperEditSchema,
  dSIMSubscriberEditSchema,
  dSIMSuperSchema,
  dSIMSuperUISchema,
  dSIMSubscriberEditUISchema,
  dSIMSuperEditUISchema, dSIMEditUISchema,
} from "../forms";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

let FormWithNav = applyNav(Form)

let snssaiToString = (snssai) => snssai.sst.toString(16).padStart(2, '0').toUpperCase() + snssai.sd

function validate(formData, errors) {
  let item = formData["sliceConfigurations"]
  item.forEach(i => {
    i.dnnConfigurations.forEach(d => {
      let qfiList = new Set([]);
      if (d.qosFlows) {
        d.qosFlows.forEach(q => {
          if (qfiList.has(q["5qi"])) {
            errors.sliceConfigurations.addError(`The QoS Flow (qfi = ${q["5qi"]}) already exists.`);
          } else {
            qfiList.add(q["5qi"]);
          }
          if (q["5qi"] >= 5 && (q.gbrUL || q.gbrDL)) {
            errors.sliceConfigurations.addError(`${q["5qi"]} is non-GBR 5QI.\nthus, gbr related parameters won’t be applied.`);
          }
        })
      }
    })
  })
  return errors;
}

function dnnConfigurationFromSliceConfiguration(dnnConfig) {
  let obj = {
    "sscModes": {
      "defaultSscMode": "SSC_MODE_1",
      "allowedSscModes": ["SSC_MODE_2", "SSC_MODE_3"]
    },
    "pduSessionTypes": {
      "defaultSessionType": "IPV4",
      "allowedSessionTypes": ["IPV4"]
    },
    "sessionAmbr": {
      "uplink": dnnConfig.uplinkAmbr,
      "downlink": dnnConfig.downlinkAmbr
    },
    "5gQosProfile": {
      "5qi": dnnConfig["5qi"],
      "arp": {
        "priorityLevel": 8
      },
      "priorityLevel": 8
    }
  }

  if (dnnConfig.upSecurityChk === true) {
    obj["upSecurity"] = {
      "upIntegr": dnnConfig.upIntegrity,
      "upConfid": dnnConfig.upConfidentiality
    }
  }

  if (dnnConfig.staticIP !== undefined && dnnConfig.staticIP.length !== 0) {
    obj["staticIpAddress"] = [
      {
        "ipv4Addr": dnnConfig.staticIP
      }
    ]
  }
  return obj
}

function smDatasFromSliceConfiguration(sliceConfiguration) {
  return _.map(sliceConfiguration, slice => {
    return {
      "singleNssai": {
        "sst": slice.snssai.sst,
        "sd": slice.snssai.sd
      },
      "dnnConfigurations": _.fromPairs(_.map(slice.dnnConfigurations, dnnConfig => [
        // key
        dnnConfig.dnn,
        // value
        dnnConfigurationFromSliceConfiguration(dnnConfig)
      ]))
    }
  })
}

function qosFlowsFromSliceConfiguration(sliceConfigurations) {
  var qosFlows = [];
  var qosRef = 1;

  sliceConfigurations.forEach(sliceConfiguration => {
    sliceConfiguration.dnnConfigurations.forEach(dnnConfiguration => {
      if (dnnConfiguration.flowRules !== undefined) {
        dnnConfiguration.flowRules.forEach(flowRule => {
          qosFlows.push(
            Object.assign(
              { 
                snssai: snssaiToString(sliceConfiguration.snssai), 
                dnn: dnnConfiguration.dnn,
                qosRef: qosRef 
              },
              flowRule
            )
          )
	  qosRef++;
        })
      }
    })
  })

  return qosFlows
}

function chargingDatasFromSliceConfiguration(sliceConfigurations) {
  var chargingDatas = [];
  var qosRef = 1;

  sliceConfigurations.forEach(sliceConfiguration => {
    if (sliceConfiguration.snssai) {
      chargingDatas.push({
        chargingMethod: sliceConfiguration.snssai.chargingMethod,
        dnn: "",
        filter: "",
        quota: sliceConfiguration.snssai.quota.toString(),
        snssai: snssaiToString(sliceConfiguration.snssai),
        unitCost: sliceConfiguration.snssai.unitCost.toString()
      })
    }
    sliceConfiguration.dnnConfigurations.forEach(dnnConfiguration => {
      if (dnnConfiguration.flowRules !== undefined) {
        dnnConfiguration.flowRules.forEach(flowRule => {
          chargingDatas.push({
            chargingMethod: flowRule.chargingMethod,
            dnn: "internet",
            filter: flowRule.filter,
            qosRef: qosRef,
            quota: flowRule.quota.toString(),
            snssai: snssaiToString(sliceConfiguration.snssai),
            unitCost: flowRule.unitCost.toString()
          });
	  qosRef++;
        })
      }
    })
  })

  return chargingDatas
}

function flowRulesFromSliceConfiguration(sliceConfigurations) {
  var flowRules = []
  var qosRef = 1;
  sliceConfigurations.forEach(sliceConfiguration => {
    sliceConfiguration.dnnConfigurations.forEach(dnnConfiguration => {
      if (dnnConfiguration.flowRules !== undefined){
        dnnConfiguration.flowRules.forEach(flowRule => {
          flowRules.push(
            Object.assign(
              {
                filter: flowRule.filter,
                precedence: flowRule.precedence,
                snssai: snssaiToString(sliceConfiguration.snssai),
                dnn: dnnConfiguration.dnn,
                qosRef: qosRef
              }
            )
          )
	  qosRef++;
        })
      }
    })
  })

  return flowRules
}

function sliceConfigurationsFromdSIM(dsimBatch) {
  const defaultSingleNssais = dsimBatch["AccessAndMobilitySubscriptionData"]["nssai"]["defaultSingleNssais"] ? dsimBatch["AccessAndMobilitySubscriptionData"]["nssai"]["defaultSingleNssais"].map(nssai => {
    return {
      snssai: {
        sst: nssai.sst,
        sd: nssai.sd,
        isDefault: true
      }
    }
  }) : [];
  const singleNssais = dsimBatch["AccessAndMobilitySubscriptionData"]["nssai"]["singleNssais"] ? dsimBatch["AccessAndMobilitySubscriptionData"]["nssai"]["singleNssais"].map(nssai => {
    return {
      snssai: {
        sst: nssai.sst,
        sd: nssai.sd,
        isDefault: false
      }
    }
  }) : [];

  let sliceConfigurations = [ // merge
    ...defaultSingleNssais,
    ...singleNssais
  ];

  const sessionManagementSubscriptionData = dsimBatch["SessionManagementSubscriptionData"];

  sliceConfigurations.forEach(sliceConf => {
    const dnnConfigs = sessionManagementSubscriptionData.find(data => data.singleNssai.sst === sliceConf.snssai.sst && data.singleNssai.sd === sliceConf.snssai.sd).dnnConfigurations;
    sliceConf.dnnConfigurations = Object.keys(dnnConfigs).map(dnn => {

      let flowRules = []; 
      const qosFlowsData = dsimBatch["QosFlows"];
      if (qosFlowsData && qosFlowsData.length !== 0) {
        flowRules = qosFlowsData
          .filter(rule => rule.snssai === snssaiToString(sliceConf.snssai) && dnn === rule.dnn)
          .map(rule => {
            return {
              gbrUL: rule.gbrUL,
              gbrDL: rule.gbrDL,
              mbrUL: rule.mbrUL,
              mbrDL: rule.mbrDL,
              precedence: rule.flowRules[0].precedence,
              filter: rule.flowRules[0].filter
            }
          })
      }

      let staticIps = "";
      const staticIpAddress = dnnConfigs[dnn].staticIpAddress
      if (staticIpAddress && staticIpAddress.length !== 0) {
        staticIps += staticIpAddress.reduce((total, element) => {
          return total + element["ipv4Addr"]
        }, "")
      }
      if (dnnConfigs[dnn].upSecurity) {
        return {
          dnn: dnn,
          staticIP: staticIps,
          uplinkAmbr: dnnConfigs[dnn].sessionAmbr.uplink,
          downlinkAmbr: dnnConfigs[dnn].sessionAmbr.downlink,
          "5qi": dnnConfigs[dnn]["5gQosProfile"]["5qi"],
          flowRules: flowRules, // new
          upSecurityChk: true,
          upIntegrity: dnnConfigs[dnn].upSecurity.upIntegr,
          upConfidentiality: dnnConfigs[dnn].upSecurity.upConfid
        };
      }
      return {
        dnn: dnn,
        staticIP: staticIps,
        uplinkAmbr: dnnConfigs[dnn].sessionAmbr.uplink,
        downlinkAmbr: dnnConfigs[dnn].sessionAmbr.downlink,
        "5qi": dnnConfigs[dnn]["5gQosProfile"]["5qi"],
        flowRules: flowRules // new
      };
    });
  });
  
  return sliceConfigurations;
}

class dSIMModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    dsim: PropTypes.object,
    user: PropTypes.object,
    isSuper: PropTypes.bool.isRequired,
    isOperator: PropTypes.bool.isRequired,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
  };

  currentSchema = this.props.isSuper ? dSIMSuperSchema : (this.props.isOperator ? dSIMSchema : dSIMSchema);
  currentUISchema = this.props.isSuper ? dSIMSuperUISchema : (this.props.isOperator ? dSIMUISchema : dSIMUISchema);

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.dsim });
      if (this.props.dsim) {
        const dSIM = this.props.dsim;
        this.currentSchema = this.props.isSuper ? dSIMSuperEditSchema : (this.props.isOperator ? dSIMEditSchema : dSIMSubscriberEditSchema);
        this.currentUISchema = this.props.isSuper ? dSIMSuperEditUISchema : (this.props.isOperator ? dSIMEditUISchema : dSIMSubscriberEditUISchema);
        let formData = {
          sliceConfigurations: sliceConfigurationsFromdSIM(dSIM),
          dsimId: dSIM["dsimId"],
          iccid: dSIM["iccid"],
          imsi: dSIM["imsi"],
          servingPlmnId: dSIM["servingPlmnId"],
          authMethod: dSIM["authMethod"],
          dsimType: dSIM["dsimType"],
          orderId: dSIM["orderId"],
          orderName: this.props.orders.filter((order) => order.id === dSIM['orderId'])[0]?.name || "",
          operatorName: this.props.operators.filter((operator) => operator.id === dSIM['operatorId'])[0]?.name || "",
          subscriberName: this.props.subscribers.filter((subscriber) => subscriber.id === dSIM['subscriberId'])[0]?.name || "",
          operatorId: dSIM["operatorId"],
          subscriberId: dSIM["subscriberId"],
          activationCode: dSIM["activationCode"],
          tokenData: {
            tokenAddress: dSIM["tokenData"]["tokenAddress"],
            smartContractTokenId: dSIM["tokenData"]["smartContractTokenId"],
            tokenId: dSIM["tokenData"]["tokenId"],
            tokenType: dSIM["tokenData"]["tokenType"],
            ownerID: dSIM["tokenData"]["ownerID"],
            ownerType: dSIM["tokenData"]["ownerType"],
            balance: dSIM["tokenData"]["balance"],
            smartContract: dSIM["tokenData"]["smartContract"],
            artworkLink: dSIM["tokenData"]["artworkLink"],
            qrCode: dSIM["tokenData"]["tokenId"]
          }
        };
        this.updateFormData(formData).then();
      } else {
        let formData = {}
        if (this.props.isOperator) {
          formData['operatorId'] = this.user.operatorId
        }
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {

  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;
    let dsimData = {
      "servingPlmnId": formData["servingPlmnId"],
      "dsimId": formData["dsimId"],
      "AccessAndMobilitySubscriptionData": {
        "nssai": {
          "defaultSingleNssais": _(formData["sliceConfigurations"])
            .map(slice => slice.snssai)
            .filter(snssai => !!snssai.isDefault),
          "singleNssais": _(formData["sliceConfigurations"])
            .map(slice => slice.snssai)
            .filter(snssai => !snssai.isDefault),
        },
        "subscribedUeAmbr": {
          "downlink": "2 Gbps",
          "uplink": "1 Gbps",
        },
      },
      "SessionManagementSubscriptionData": smDatasFromSliceConfiguration(formData["sliceConfigurations"]),
      "SmfSelectionSubscriptionData": {
        "subscribedSnssaiInfos": _.fromPairs(
          _.map(formData["sliceConfigurations"], slice => [snssaiToString(slice.snssai),
          {
            "dnnInfos": _.map(slice.dnnConfigurations, dnnConfig => {
              return { "dnn": dnnConfig.dnn }
            })
          }]))
      },
      "AmPolicyData": {
        "subscCats": [
          "open6gc",
        ]
      },
      "SmPolicyData": {
        "smPolicySnssaiData": _.fromPairs(
          _.map(formData["sliceConfigurations"], slice => [snssaiToString(slice.snssai),
          {
            "snssai": {
              "sst": slice.snssai.sst,
              "sd": slice.snssai.sd
            },
            "smPolicyDnnData": _.fromPairs(
              _.map(slice.dnnConfigurations, dnnConfig => [
                dnnConfig.dnn,
                {
                  "dnn": dnnConfig.dnn
                }
              ])
            )
          }]))
      },
      "FlowRules": flowRulesFromSliceConfiguration(formData["sliceConfigurations"]),
      "QosFlows": qosFlowsFromSliceConfiguration(formData["sliceConfigurations"]),
    };
    if (this.state.editMode) {
      this.props.onModify(dsimData);
    } else {
      this.props.onSubmit(dsimData);
    }
  }
  
  render() {
    return (
      <Modal
        show={this.props.open}
        className="dsims__modal"
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit dSIM" : "New dSIM"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form schema={this.currentSchema}
              uiSchema={this.currentUISchema}
              formData={this.state.formData}
                customValidate={validate}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
                validator={validator}/>
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  operators: state.operator.operators,
  orders: state.order.orders,
  subscribers: state.subscriber.subscribers,
});

export default withRouter(connect(mapStateToProps)(dSIMModal));
