// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating {
  position: fixed;
  bottom: 10%;
  right: 10%;
}

.floatingUpload {
  position: relative;
  bottom: 2em;
}

.floatingMkdir {
  position: relative;
  right: 5em;
}

.fileUpload {
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
  margin-left: 1em;
  color: #002b36;
  background-color: #769fb6;
}

.input {
  display: none;
}

.textInput {
  padding: 10px;
  border: none;
  border-radius: 20px;
  outline: none;
}

.submit {
  border: none;
  color: #002b36;
  background-color: #d4cb92;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
  margin-left: 1em;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ff6b6c;
  color: #073642;
  border-radius: 3px;
  margin-top: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Gateways/components/add/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".floating {\n  position: fixed;\n  bottom: 10%;\n  right: 10%;\n}\n\n.floatingUpload {\n  position: relative;\n  bottom: 2em;\n}\n\n.floatingMkdir {\n  position: relative;\n  right: 5em;\n}\n\n.fileUpload {\n  border: none;\n  padding: 10px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 16px;\n  border-radius: 3px;\n  margin-left: 1em;\n  color: #002b36;\n  background-color: #769fb6;\n}\n\n.input {\n  display: none;\n}\n\n.textInput {\n  padding: 10px;\n  border: none;\n  border-radius: 20px;\n  outline: none;\n}\n\n.submit {\n  border: none;\n  color: #002b36;\n  background-color: #d4cb92;\n  padding: 10px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 16px;\n  border-radius: 3px;\n  margin-left: 1em;\n}\n\n.error {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  background-color: #ff6b6c;\n  color: #073642;\n  border-radius: 3px;\n  margin-top: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
