// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uri-widget {
    display: block;
    margin-top: .5rem;
  }
  
  .uri-widget a {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #F5F5F5;
    background-clip: padding-box;
    border: 0.6px solid rgba(37, 38, 95, 0.2);
    border-radius: 2px;
    text-decoration: none; /* Optional: if you don't want the URL underlined */
  }
  
  .uri-widget a:hover {
    color: #0d6efd;
    text-decoration: underline;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/Operators/components/UriWidget.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;IACzB,4BAA4B;IAC5B,yCAAyC;IACzC,kBAAkB;IAClB,qBAAqB,EAAE,mDAAmD;EAC5E;;EAEA;IACE,cAAc;IACd,0BAA0B;EAC5B","sourcesContent":[".uri-widget {\n    display: block;\n    margin-top: .5rem;\n  }\n  \n  .uri-widget a {\n    display: block;\n    padding: .375rem .75rem;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.5;\n    background-color: #F5F5F5;\n    background-clip: padding-box;\n    border: 0.6px solid rgba(37, 38, 95, 0.2);\n    border-radius: 2px;\n    text-decoration: none; /* Optional: if you don't want the URL underlined */\n  }\n  \n  .uri-widget a:hover {\n    color: #0d6efd;\n    text-decoration: underline;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
