
export default class dsimActions {
  static SET_DSIMS = 'DSIM/SET_DSIMS';

  /**
   * @param dsims  {dSIM}
   */
  static setdSIMs(dsims) {
    return {
      type: this.SET_DSIMS,
      dsims: dsims,
    };
  }
}
