import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import CameraModal from "./components/CameraModal";
import CameraControlCenterModal from "./components/CameraControlCenterModal";
import ApiHelper, { customFetch } from "../../util/ApiHelper";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import AppUtils from "../../util/AppUtils";

class CameraOverview extends Component {
  state = {
    cameraModalOpen: false,
    cameraControlCenterModalOpen: false,
    cameraModalData: null,
    searchString: "",
    aimacServerUrl: "",
    isCheckedAll: false,
    checkedData: [],
  };

  async loadServerUrl() {
    const aimacUrl = await customFetch("/aimacServerIP");
    this.setState({
      aimacServerUrl: `http://${aimacUrl.serverIP}:${aimacUrl.serverPort}`,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cameras !== this.props.cameras) {
      this.setCheckedData();
    }
  }

  setCheckedData = () => {
    const updatedItems = this.props.cameras.map((item) => ({
      ...item,
      checked: false,
    }));

    this.setState({ checkedData: updatedItems });
    this.setState({ isCheckedAll: false });
  };

  componentDidMount() {
    ApiHelper.fetchCameras(this.props.user.operatorId).then();
    ApiHelper.fetchdSIMs().then();
    ApiHelper.fetchOperators().then();
    this.loadServerUrl();
  }

  openCameraControlCenter() {
    this.setState({
      cameraControlCenterModalOpen: true,
    });
  }

  openAddCamera() {
    this.setState({
      cameraModalOpen: true,
      cameraModalData: null,
    });
  }

  /**
   * @param uuId  {string}
   */
  async openEditCamera(uuId) {
    const camera = await ApiHelper.fetchCameraById(
      this.props.user.operatorId,
      uuId,
    );

    this.setState({
      cameraModalOpen: true,
      cameraModalData: camera,
    });
  }

  async addCamera(cameraData) {
    this.setState({ cameraModalOpen: false });

    const res = await ApiHelper.createCamera(
      cameraData["operatorId"],
      cameraData,
    );
    if (!res) {
      alert("Error creating new camera");
    } else {
      await ApiHelper.createCameraToAimacServer(this.state.aimacServerUrl, res);
    }
    ApiHelper.fetchCameras(this.props.user.operatorId).then();
  }

  /**
   * @param cameraData
   */
  async updateCamera(cameraData) {
    this.setState({ cameraModalOpen: false });

    const result = await ApiHelper.updateCamera(
      cameraData["operatorId"],
      cameraData,
    );

    if (!result) {
      alert("Error updating camera: " + cameraData["uuId"]);
    } else {
      await ApiHelper.updateCameraToAimacServer(
        this.state.aimacServerUrl,
        cameraData,
      );
    }
    ApiHelper.fetchCameras(this.props.user.operatorId).then();
  }

  /**
   * @param camera  {Camera}
   */
  async deleteCamera(camera) {
    if (!window.confirm(`Delete Camera ${camera.uuid}?`)) return;
    const result = await ApiHelper.deleteCamera(
      this.props.user.operatorId,
      camera.uuid,
    );
    ApiHelper.fetchCameras(this.props.user.operatorId).then();
    if (!result) {
      alert("Error deleting camera: " + camera.uuid);
    } else {
      await ApiHelper.deleteCameraToAimacServer(
        this.state.aimacServerUrl,
        camera.uuid,
      );
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  onClickEnable(value) {
    this.state.checkedData.map(async (item) => {
      if (item.checked) {
        if (
          (item.status === "ENABLED" && !value) ||
          (item.status === "DISABLED" && value)
        ) {
          const camera = await ApiHelper.fetchCameraById(
            this.props.user.operatorId,
            item.uuid,
          );
          camera.status = item.status === "ENABLED" ? "DISABLED" : "ENABLED";
          this.updateCamera(camera);
          if (value) {
            await ApiHelper.createCameraToAimacServer(
              this.state.aimacServerUrl,
              camera,
            );
          } else {
            await ApiHelper.deleteCameraToAimacServer(
              this.state.aimacServerUrl,
              item.uuid,
            );
          }
        }
      }
    });
  }

  onChangeSelectAll(event) {
    const { checked } = event.target;
    this.setState({ isCheckedAll: checked });

    const updatedItems = this.state.checkedData.map((item) => {
      return { ...item, checked: checked };
    });

    this.setState({ checkedData: updatedItems });
  }

  onChangeSelect(uuid) {
    const updatedItems = this.state.checkedData.map((item) => {
      if (item.uuid === uuid) {
        return { ...item, checked: !item.checked };
      }

      return item;
    });

    this.setState({ checkedData: updatedItems });
  }

  getCheckedValueFromSelect(uuid) {
    const res = this.state.checkedData.filter((item) => item.uuid === uuid);
    if (res.length > 0) {
      return res.checked;
    }
    return false;
  }

  openFirmware() {
    this.props.history.push("/cameras/logging");
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Cameras</h4>
                  <div className="overview__searchpanel">
                    <SearchBar
                      value={this.state.searchString}
                      onChange={this.onChangeSearch.bind(this)}
                    />
                    <div
                      onClick={this.openCameraControlCenter.bind(this)}
                      className="overview__searchpanel__extra-icon"
                    >
                      <i className="fas fa-th-large" />
                      <span style={{ marginLeft: 5 }}>Control Center</span>
                    </div>
                    <div
                      onClick={this.openFirmware.bind(this)}
                      className="overview__searchpanel__extra-icon"
                    >
                      <i class="fas fa-th-list" />
                      <span style={{ marginLeft: 5 }}>Logging</span>
                    </div>
                  </div>
                </div>
                <Button
                  bsStyle={"primary"}
                  className="overview__button"
                  onClick={this.openAddCamera.bind(this)}
                >
                  New Camera
                </Button>
                <Button
                  className="overview__button overview__button__margin-right"
                  style={{
                    backgroundColor: "#fd0505",
                    borderColor: "#fd0505",
                    color: "white",
                  }}
                  onClick={this.onClickEnable.bind(this, false)}
                >
                  Disable
                </Button>
                <Button
                  className="overview__button overview__button__margin-right"
                  style={{
                    backgroundColor: "green",
                    borderColor: "green",
                    color: "white",
                  }}
                  onClick={this.onClickEnable.bind(this, true)}
                >
                  Enable
                </Button>
              </div>
              <div className="content overview__content">
                <Table
                  className="overview__table"
                  striped
                  bordered
                  condensed
                  hover
                >
                  <thead>
                    <tr>
                      <th>
                        <input
                          className="table-checkbox"
                          type="checkbox"
                          label=""
                          checked={this.state.isCheckedAll}
                          onChange={this.onChangeSelectAll.bind(this)}
                        />
                      </th>
                      <th>IMEI</th>
                      <th>Manufacturer/Model</th>
                      <th>Created</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.checkedData
                      .filter(
                        (camera) =>
                          camera.imei.includes(this.state.searchString) ||
                          camera.manufacturerName.includes(
                            this.state.searchString,
                          ) ||
                          camera.modelName.includes(this.state.searchString) ||
                          camera.description.includes(this.state.searchString),
                      )
                      .map((camera) => (
                        <tr key={camera.uuid}>
                          <td>
                            <input
                              className="table-checkbox"
                              type="checkbox"
                              label=""
                              checked={camera.checked}
                              onChange={this.onChangeSelect.bind(
                                this,
                                camera.uuid,
                              )}
                            />
                          </td>
                          <td>{camera.imei}</td>
                          <td>
                            {camera.manufacturerName}/{camera.modelName}
                          </td>
                          <td>
                            {moment(camera.createdDate).format(
                              "YYYY-MM-DD HH:mm:ss",
                            )}
                          </td>
                          <td>
                            <span
                              style={{
                                color: AppUtils.getStatusTextColor(
                                  camera.status,
                                ),
                              }}
                            >
                              {camera.status}
                            </span>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderBottom: "none",
                            }}
                          >
                            <Button
                              variant="danger"
                              onClick={this.deleteCamera.bind(this, camera)}
                            >
                              Delete
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                              variant="info"
                              onClick={this.openEditCamera.bind(
                                this,
                                camera.uuid,
                              )}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <CameraModal
          open={this.state.cameraModalOpen}
          setOpen={(val) => this.setState({ cameraModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          camera={this.state.cameraModalData}
          onModify={this.updateCamera.bind(this)}
          onSubmit={this.addCamera.bind(this)}
        />

        <CameraControlCenterModal
          open={this.state.cameraControlCenterModalOpen}
          setOpen={(val) =>
            this.setState({ cameraControlCenterModalOpen: val })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cameras: state.camera.cameras,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(CameraOverview));
