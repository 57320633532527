import React from "react";
import { Route } from "react-router-dom";
import MapOverview from "./MapOverview";

const Map = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={MapOverview} />
  </div>
);

export default Map;
