import React, { useEffect, useRef, useState } from "react";
import h337 from "heatmap.js";

const HeatmapMap = ({ heatmapData, serverURL }) => {
  const heatmapContainerRef = useRef(null);
  const [tooltip, setTooltip] = useState(null);
  const [points, setPoints] = useState([]);

  useEffect(() => {
    if (heatmapContainerRef.current) {
      const heatmapInstance = h337.create({
        container: heatmapContainerRef.current,
        radius: 20,
        maxOpacity: 1,
        minOpacity: 0,
        blur: 1,
      });

      let temPoints = [];
      let temPoints1 = [];
      const max = 10;
      const width = heatmapContainerRef.current.offsetWidth;
      const height = heatmapContainerRef.current.offsetHeight;

      if (heatmapData.length > 0) {
        for (let j = 0; j < heatmapData.length; j++) {
          const data1 = heatmapData[j].coordinates;
          for (let i = 0; i < data1.length; i++) {
            const value = 1;
            const x = Math.floor(data1[i].x / 2);
            const y = Math.floor(data1[i].y / 2);
            temPoints.push({ x, y, value });
            const imgURL = heatmapData[j].faceImageURL || "";
            const location = heatmapData[j].location;
            const cam = heatmapData[j].camera;
            temPoints1.push({ x, y, value, imgURL, location, cam });
          }
        }
      }

      const data = {
        max: heatmapData.length * 2,
        data: temPoints,
      };
      setPoints(temPoints1);
      heatmapInstance.setData(data);

      // Cleanup the previous event listener before adding a new one
      const handleMouseMove = (event) => {
        const rect = heatmapContainerRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        let totalValue = 0;
        const radius = 10;
        let faceImgURL = "";
        let location = "";
        let cam = "";
        points.forEach((point) => {
          const distance = Math.sqrt((x - point.x) ** 2 + (y - point.y) ** 2);
          if (distance <= radius) {
            totalValue += point.value;
            faceImgURL = point.imgURL;
            location = point.location;
            cam = point.cam;
          }
        });

        setTooltip({
          x: x,
          y: y,
          value: totalValue,
          faceImgURL: faceImgURL,
          location: location,
          camera: cam,
        });
      };

      heatmapContainerRef.current.addEventListener(
        "mousemove",
        handleMouseMove,
      );

      // Cleanup function to remove the event listener
      return () => {
        if (heatmapContainerRef.current) {
          heatmapContainerRef.current.removeEventListener(
            "mousemove",
            handleMouseMove,
          );
        }
      };
    }
  }, [heatmapData]); // Use heatmapData as the dependency

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={heatmapContainerRef}
        style={{ width: "640px", height: "360px", position: "relative" }}
      />
      {tooltip && (
        <div
          style={{
            position: "absolute",
            left: tooltip.x + 5,
            top: tooltip.y,
            padding: "5px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "3px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>Location: {tooltip.location}</div>
          <div>Camera: {tooltip.camera}</div>
          {tooltip.faceImgURL && (
            <img
              src={tooltip.faceImgURL} // Construct the full URL
              alt="Face img"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HeatmapMap;
