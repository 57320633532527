import React from "react";
import { Route } from "react-router-dom";
import BlockOverview from "./BlockOverview";

const Blocks = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={BlockOverview} />
  </div>
);

export default Blocks;
