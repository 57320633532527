import React from "react";
import { Button } from "react-bootstrap";

const NotFound = ({ onCreateBatch, text, buttonText, description }) => {
  return (
    <div className="flex flex-col items-center justify-center py-10">
      <p className="text-gray-600 text-lg font-bold">{text}</p>
      <p className="text-gray-500">{description}</p>
      <br />
      <Button
        bsStyle="primary"
        className="overview__button"
        onClick={onCreateBatch}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default NotFound;
