import React from "react";
import { customFetch } from "../../util/ApiHelper";
import GenericDataList from "../../components/GenericDataList";
import SubscriberModal from "./components/SubscriberModal";
import AppUtils from "../../util/AppUtils";

export const SubscriberOverview = () => {
  const fetch = async () => {
    return await customFetch(`/subscriber`, "GET");
  };

  const add = async (data) => {
    const result = await customFetch(`/subscriber`, "POST", data);
    return !!result;
  };

  const get = async (data) => {
    const subscriberId = data?.userId;
    if (!subscriberId) {
      console.error("ID de suscriptor no válido:", data);
      return null;
    }
    return await customFetch(`/subscriber/${subscriberId}`);
  };

  const update = async (data) => {
    const result = await customFetch(`/subscriber/${data.userId}`, "PUT", data);
    return !!result;
  };

  const _delete = async (data) => {
    console.log("data", data);

    const result = await customFetch(`/subscriber/${data.userId}`, "DELETE");
    return !!result;
  };

  const columns = [
    {
      header: "Name",
      searchable: true,
      field: "name",
    },
    {
      header: "Created",
      field: "createdDate",
    },
    {
      header: "Status",
      field: "status",
      render: (subscriber) => (
        <span style={{ color: AppUtils.getStatusTextColor(subscriber.status) }}>
          {subscriber.status}
        </span>
      ),
    },
  ];

  return (
    <GenericDataList
      fetchAllData={fetch}
      addData={add}
      getData={get}
      updateData={update}
      deleteData={_delete}
      dataName="Subscriber"
      deleteItemName="name"
      itemKey="id"
      columns={columns}
      DetailComponent={SubscriberModal}
    />
  );
};

export default SubscriberOverview;
