import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import CellModal from "./components/CellModal";
import ApiHelper from "../../util/ApiHelper";
import AppUtils from '../../util/AppUtils';
import SearchBar from '../../components/FormInputs/SearchBar.js';

class CellOverview extends Component {
  state = {
    cellModalOpen: false,
    cellModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchCells(this.props.user.operatorId).then();
    ApiHelper.fetchGateways(this.props.user.operatorId).then();
    ApiHelper.fetchOperators().then();
  }

  openAddCell() {
    this.setState({
      cellModalOpen: true,
      cellModalData: null,
    });
  }

  /**
   * @param cellId  {string}
   */
  async openEditCell(cellId) {
    const cell = await ApiHelper.fetchCellById(this.props.user.operatorId, cellId);

    this.setState({
      cellModalOpen: true,
      cellModalData: cell,
    });
  }

  async addCell(cellData) {
    this.setState({ cellModalOpen: false });

    if (!await ApiHelper.createCell(cellData['operatorId'], cellData)) {
      alert("Error creating new cell");
    }
    ApiHelper.fetchCells(this.props.user.operatorId).then();
  }

  /**
   * @param cellData
   */
  async updateCell(cellData) {
    this.setState({ cellModalOpen: false });

    const result = await ApiHelper.updateCell(cellData['operatorId'], cellData);

    if (!result) {
      alert("Error updating cell: " + cellData["uuId"]);
    }
    ApiHelper.fetchCells(this.props.user.operatorId, ).then();
  }

  /**
  * @param cell  {Cell}
   */
  async deleteCell(cell) {
    if (!window.confirm(`Delete Cell ${cell.uuid}?`))
      return;

    const result = await ApiHelper.deleteCell(this.props.user.operatorId, cell.uuid);
    ApiHelper.fetchCells(this.props.user.operatorId).then();
    if (!result) {
      alert("Error deleting cell: " + cell.uuid);
    }
  }

  openFirmware() {
    this.props.history.push('/firmware/devices');
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Small Cells</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                    <div onClick={this.openFirmware.bind(this)} className='overview__searchpanel__extra-icon'>
                      <i className="fas fa-th-large"/>
                      <span style={{ marginLeft: 5}}>Firmware</span>
                    </div>
                  </div>
                </div>
                <Button bsStyle={"primary"} className="overview__button"
                  onClick={this.openAddCell.bind(this)}>
                  New Cell
                </Button>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th style={{ width: 220 }}>ID</th>
                      <th>Band/Frequency</th>
                      <th>Status</th>
                      <th colSpan={2}>Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.cells.filter(cell =>
                      cell.uuid.includes(this.state.searchString) ||
                      cell.band.includes(this.state.searchString) ||
                      cell.status.includes(this.state.searchString)
                    ).map(cell => (
                      <tr key={cell.uuid}>
                        <td>{cell.uuid}</td>
                        <td>{cell.band}</td>
                        <td><span style={{color: AppUtils.getStatusTextColor(cell.status)}}>{cell.status}</span></td>
                        <td>{cell.createdDate}</td>
                        <td style={{ textAlign: 'center' }}>
                          <Button variant="danger" onClick={this.deleteCell.bind(this, cell)}>Delete</Button>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="info" onClick={this.openEditCell.bind(this, cell.uuid)}>View</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <CellModal open={this.state.cellModalOpen}
          setOpen={val => this.setState({ cellModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          cell={this.state.cellModalData}
          onModify={this.updateCell.bind(this)}
          onSubmit={this.addCell.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cells: state.cell.cells,
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(CellOverview));
