import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import PropTypes from "prop-types";

const BlockModal = ({ open, setOpen, block }) => {
  const [transactions, setTransactions] = useState([]);
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    if (block?.transactions?.[0]) {
      setTransactions(block.transactions[0].tx_data || []);
      setTransactionId(block.transactions[0].tx_id || "");
    } else {
      setTransactions([]);
      setTransactionId("");
    }
  }, [block]);

  const getActionType = (from, to) => {
    if (from === "0x0") return "Mint";
    if (to === "0x0") return "Burn";
    return "Transfer";
  };

  const scrollableTableStyle = {
    display: "block",
    maxHeight: "400px",
    overflowY: "auto",
    marginBottom: "10px",
  };

  const modalBodyStyle = {
    padding: "15px",
  };

  return (
    <Modal
      show={open}
      className="block-modal theme-light"
      backdrop="static"
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>View Block Details</Modal.Title>
      </Modal.Header>

      <Modal.Body style={modalBodyStyle}>
        <div style={scrollableTableStyle}>
          <Table className="overview__table" striped bordered hover responsive>
            <thead>
              <tr>
                <th>Action</th>
                <th>From</th>
                <th>To</th>
                <th>Amount</th>
                <th>Token ID</th>
                <th style={{ width: 400 }}>Transaction ID</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((action) => (
                <tr key={action.id}>
                  <td>{getActionType(action.from, action.to)}</td>
                  <td>{action.from}</td>
                  <td>{action.to}</td>
                  <td>{action.value}</td>
                  <td>{action.id}</td>
                  <td>{transactionId}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

BlockModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  block: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
    transactionsCount: PropTypes.number,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        tx_id: PropTypes.string,
        tx_data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            from: PropTypes.string,
            to: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
};

export default BlockModal;
