import actions from "../actions/orderActions";

const initialState = {
  orders: [],
  ordersMap: {},
};

export default function reducer(state = initialState, action) {
  let nextState = { ...state };

  switch (action.type) {
    case actions.SET_ORDERS:
      nextState.orders = action.orders;
      nextState.ordersMap = createOrdersMap(action.orders);
      return nextState;

    default:
      return state;
  }
}

function createOrdersMap(orders) {
  let ordersMap = {};
  orders.forEach((orders) => (ordersMap[orders["id"]] = orders));
  return ordersMap;
}
