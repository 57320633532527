/* eslint-disable */
import React from "react";

const SearchBar = (props) => {
  return (
    <div className="searchbar">
      <input
        className="form-control search-bar border-end-0 border"
        type="search"
        placeholder="Search"
        id="search-input"
        value={props.value}
        onChange={props.onChange}
        aria-label="Search"
      />
      <span className="search-icon">
        <button className="search-btn" type="button" aria-label="Search button">
          <i className="fa fa-search"></i>
        </button>
      </span>
    </div>
  );
};

export default SearchBar;
