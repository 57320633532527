import React, { useEffect, useState } from "react";
import { customFetch } from "../util/ApiHelper";

const PLMNSelector = (props) => {
  const { formData, onChange, disabled, formContext, required, readonly } =
    props;
  const { data } = formContext;
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const plmns = await customFetch("/supportedplmns");
      try {
        if (plmns) {
          setOptions(plmns);
          // Set default value if none is selected and we have options
          if ((!data || !data.servingPlmnId) && plmns && plmns.length > 0) {
            const defaultOption = plmns[0];
            onChange({
              servingPlmnId: defaultOption.mcc + defaultOption.mnc,
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleSelect = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="plmn-selector">
      <label className="control-label" htmlFor="root_servingPlmnId">
        Serving PLMN ID{required && <span className="required">*</span>}
      </label>
      <select
        onChange={handleSelect}
        value={formData || ""}
        className="form-control"
        disabled={isLoading || readonly}
      >
        {isLoading ? (
          <option value="">Loading PLMNS...</option>
        ) : (
          <>
            <option value="" className="p-2">
              Select PLMN
            </option>
            {options.map((option, index) => {
              return (
                <option
                  className="p-2"
                  key={option.mcc + option.mnc || ""}
                  value={option.mcc + option.mnc || ""}
                  title={option.mcc + option.mnc || ""}
                >
                  MCC: {option.mcc} - MNC: {option.mnc}
                </option>
              );
            })}
          </>
        )}
      </select>
    </div>
  );
};

export default PLMNSelector;
