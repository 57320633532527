import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import DSIMBatchModal from "./components/dSIMBatchModal";
import DSIMModal from "./components/dSIMModal";
import DSIMSyncModal from "./components/dSIMSyncModal";
import ApiHelper, { customFetch } from "../../util/ApiHelper";
import { downloadModalSchema } from "./forms";
import DownloadModal from "./components/DownloadModal.js";
import { ethers, Signature } from "ethers";
import { signTypedDataMessage } from "../../util/blockchainUtils";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";
import { toast } from "react-toastify";
import GenericDataList from "../../components/GenericDataList";

const DSIMOverview = () => {
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [dsimBatchModalOpen, setDSIMBatchModalOpen] = useState(false);
  const [dsimSyncModalOpen, setDSIMSyncModalOpen] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [schema, setSchema] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [imsi, setIMSI] = useState("");
  const [dsimStatus, setDSIMStatus] = useState({});
  const [refreshList, setRefreshList] = useState(false);
  const { walletProvider } = useAppKitProvider("eip155");
  const { address } = useAppKitAccount();
  const [isCustodial, setIsCustodial] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setIsCustodial(user.walletIsCustodial);
  }, []);

  // Fetch all DSIMs
  const fetchAllData = async () => {
    return await customFetch("/dsim");
  };

  // Get specific DSIM by ID
  const getData = async (item) => {
    return await customFetch(`/dsim/${item.dsimId}`);
  };

  // Update DSIM
  const updateData = async (data) => {
    const result = await customFetch(`/dsim/${data.dsimId}`, "PUT", data);
    return !!result;
  };

  // Override add behavior to open batch modal
  const addData = async () => {
    setDSIMBatchModalOpen(true);
    return false; // Return false to prevent GenericDataList's standard modal from opening
  };

  const getDsimsStatus = async (dsims) => {
    const statuses = {};
    for (const dsim of dsims) {
      const status = await isDsimMinted(dsim.id, dsim.imsi);
      statuses[dsim.id] = status;
    }
    setDSIMStatus(statuses);
  };

  const isDsimMinted = async (dsimId, imsi) => {
    const nftRequestStatus = await ApiHelper.getNftRequestStatus(dsimId, imsi);
    if (nftRequestStatus.minted) return "Minted";
    if (nftRequestStatus.exists) return "Signed, pending";
    return "Unsigned";
  };

  const signMessage = async (orderId, tokenId, dsim) => {
    if (["Signed, pending", "Minted"].includes(dsimStatus[orderId])) {
      toast.error("Already signed!");
      return;
    }

    const provider = new ethers.BrowserProvider(walletProvider);

    try {
      const deploymentInfo =
        await ApiHelper.getDeploymentInfo("BloxtelManager");
      if (!deploymentInfo) {
        console.error("Failed to get deployment info");
        return;
      }

      const {
        address: BLOXTEL_MANAGER_ADDRESS,
        chainId: CHAIN_ID,
        abi: BLOXTEL_MANAGER_ABI,
      } = deploymentInfo;

      const bloxtelManager = new ethers.Contract(
        BLOXTEL_MANAGER_ADDRESS,
        BLOXTEL_MANAGER_ABI,
        provider,
      );

      const trustedForwarderDeploymentInfo =
        await ApiHelper.getDeploymentInfo("TrustedForwarder");

      if (!trustedForwarderDeploymentInfo)
        throw new Error("Failed to get trusted forwarder deployment info");

      // Get the ABI, address, chain ID from the trustedForwarderDeploymentInfo
      const { abi: ABI, address: TRUSTED_FORWARDER_ADDRESS } =
        trustedForwarderDeploymentInfo;
      const trustedForwarder = new ethers.Contract(
        TRUSTED_FORWARDER_ADDRESS,
        ABI,
        provider,
      );

      const nonce = await trustedForwarder.getNonce(address);

      const types = {
        ForwardRequest: [
          { name: "from", type: "address" },
          { name: "to", type: "address" },
          { name: "value", type: "uint256" },
          { name: "gas", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "data", type: "bytes" },
        ],
      };

      if (!walletProvider) {
        alert("Web 3 wallet not detected. Please install a Web 3 wallet.");
        return;
      }

      // Hash the token ID
      const tokenIdHash = ethers.sha256(ethers.toUtf8Bytes(tokenId.toString()));

      const mintNFTData = bloxtelManager.interface.encodeFunctionData(
        "mintdSIMNFT",
        [orderId, tokenIdHash],
      );

      const message = {
        from: address,
        to: BLOXTEL_MANAGER_ADDRESS,
        value: 0,
        gas: 1000000,
        nonce: nonce,
        data: mintNFTData,
      };

      const signer = provider.getSigner();

      const domain = {
        name: "TrustedForwarder",
        version: "1",
        chainId: parseInt(CHAIN_ID),
        verifyingContract: TRUSTED_FORWARDER_ADDRESS,
      };

      const signature = await signTypedDataMessage(
        domain,
        types,
        message,
        address,
        walletProvider,
      );

      const parsedSignature = Signature.from(signature);

      const requestData = {
        externalId: dsim.id,
        trustedForwarder: TRUSTED_FORWARDER_ADDRESS,
        from: address,
        to: BLOXTEL_MANAGER_ADDRESS,
        value: "0",
        gas: "1000000",
        nonce: nonce.toString(),
        data: mintNFTData,
        signature: signature,
      };
      const response = await customFetch(
        "/mint-nft-request",
        "POST",
        requestData,
      );

      toast.success("dSIM signed successfully");
      // Refresh the data list to show updated status
      setRefreshList((prev) => !prev);
    } catch (error) {
      console.error("Error signing message", error);
    }
  };

  // Handle DSIMBatch creation submission
  const adddSIMBatch = async (dsimBatchData) => {
    try {
      const ok = await customFetch(`/dsim/batch`, "POST", dsimBatchData);
      if (ok) {
        toast.success("dSIM batch created successfully");
        // Close the batch modal
        setDSIMBatchModalOpen(false);
        setRefreshList((prev) => !prev);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error creating DSIM batch:", error);
      toast.error("Failed to create dSIM batch");
      return false;
    }
  };

  // Simple batch modal close handler
  const updateBatch = () => {
    setDSIMBatchModalOpen(false);
  };

  const openSync = async () => {
    setDSIMSyncModalOpen(true);
  };

  const openDownloadModal = async (tokenId, network) => {
    const subscribers = await ApiHelper.getSubscribers();

    if (!subscribers) {
      toast("No subscribers found");
      return;
    }

    downloadModalSchema.properties.subscriber.enum = [];

    let updatedSchema = { ...downloadModalSchema };

    subscribers.forEach((subscriber, index) => {
      let subscriberString =
        subscriber.firstName +
        " " +
        subscriber.lastName +
        " (" +
        subscriber.userId +
        ")";
      updatedSchema.properties.subscriber.enum.push(subscriberString);

      if (index === 0) {
        downloadModalSchema.properties.subscriber.default = subscriberString;
      }
    });

    setDownloadModalOpen(true);
    setTokenId(tokenId);
    setSchema(updatedSchema);
    setSubscribers(subscribers);
  };

  const columns = [
    {
      header: "EID",
      searchable: true,
      field: "eid",
    },
    {
      header: "ICCID",
      searchable: true,
      field: "iccid",
    },
    {
      header: "IMSI",
      searchable: true,
      field: "imsi",
    },
    {
      header: "Authentication Method",
      field: "authMethod",
    },
    {
      header: "dSIM Type",
      field: "dsimType",
    },
    {
      header: "Created",
      field: "createdDate",
    },
    {
      header: "Blockchain Status",
      field: "id",
      render: (dsim) => dsimStatus[dsim.id] || "Fetching...",
    },
  ];

  const customActions = (dsim) => (
    <>
      {user.role !== "SUBSCRIBER" && (
        <Button
          variant="info"
          onClick={() => openDownloadModal(dsim.dsimId, dsim.network)}
        >
          Transfer
        </Button>
      )}
      {dsimStatus[dsim.dsimId] === "Unsigned" && !isCustodial && (
        <Button
          variant="info"
          onClick={() => signMessage(dsim.orderId, dsim.imsi, dsim)}
        >
          Sign
        </Button>
      )}
    </>
  );

  const customGlobalActions = () => (
    <>
      {user.role !== "SUBSCRIBER" && (
        <Button
          variant="info"
          className="overview__button"
          onClick={() => openSync()}
        >
          Sync with Cloud
        </Button>
      )}
    </>
  );

  return (
    <>
      <GenericDataList
        fetchAllData={fetchAllData}
        getData={getData}
        updateData={updateData}
        addData={addData}
        dataName="dSIM"
        deleteItemName="iccid"
        itemKey="id"
        columns={columns}
        DetailComponent={DSIMModal}
        customActions={customActions}
        allowDelete={false}
        allowAdd={user.role !== "SUBSCRIBER"}
        addButtonText="Add dSIMs"
        notFoundText="No dSIMs found"
        notFoundButtonText="Create new dSIMs"
        notFoundDescription="You can create new dSIMs to start minting dSIMs."
        refetch={refreshList}
        noOpenDetailOnAdd={true}
        customGlobalActions={customGlobalActions}
      />
      <DSIMBatchModal
        open={dsimBatchModalOpen}
        setOpen={setDSIMBatchModalOpen}
        onSubmit={adddSIMBatch}
        onModify={updateBatch}
      />
      <DownloadModal
        open={downloadModalOpen}
        setOpen={setDownloadModalOpen}
        dsimId={tokenId}
        imsi={imsi}
        schema={schema}
        subscribers={subscribers}
      />
      <DSIMSyncModal open={dsimSyncModalOpen} setOpen={setDSIMSyncModalOpen} />
    </>
  );
};

export default DSIMOverview;
