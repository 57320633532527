import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import OperatorModal from "./components/OperatorModal";
import ApiHelper, { customFetch } from "../../util/ApiHelper";
import AppUtils, { formatDateOnly } from "../../util/AppUtils";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import Skeleton from "../../components/ui/Skeleton";
import { toast } from "react-toastify";

class OperatorOverview extends Component {
  state = {
    operatorModalOpen: false,
    operatorModalData: null,
    searchString: "",
    isWalletCreated: "FALSE",
  };

  componentDidMount() {
    // eslint-disable-next-line
    let operatorId = this.props.match.url.replace(/^.*[\\\/]/, "");
    ApiHelper.fetchOperators().then();

    if (operatorId !== "operators") {
      this.openEditOperator(operatorId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line
      let operatorId = this.props.match.url.replace(/^.*[\\\/]/, "");

      if (operatorId !== "operators") {
        this.openEditOperator(operatorId);
      }
    }
  }

  openAddOperator() {
    this.setState({
      operatorModalOpen: true,
      operatorModalData: null,
    });
  }

  /**
   * @param operatorId  {string}
   */
  async openEditOperator(operatorId) {
    const operator = await ApiHelper.fetchOperatorById(operatorId);

    this.setState({
      operatorModalOpen: true,
      operatorModalData: operator,
    });
  }

  async addOperator(operatorData) {
    operatorData.initialUser = {
      email: operatorData["email"],
      password: operatorData["password"],
      passwordConfirm: operatorData["passwordConfirm"],
    };
    const operator = await customFetch("/operator", "POST", operatorData);
    if (operator) {
      this.setState({ operatorModalOpen: false });
      ApiHelper.fetchOperators().then();
    }
  }

  /**
   * @param operatorData
   */
  async updateOperator(operatorData) {
    this.setState({ operatorModalOpen: false });

    const result = await ApiHelper.updateOperator(operatorData);

    if (!result) {
      alert("Error updating operator: " + operatorData["ueId"]);
    }
    ApiHelper.fetchOperators().then();
    this.props.history.push(`/operators`);
  }

  /**
   * @param operator  {Operator}
   */
  async deleteOperator(operator) {
    if (!window.confirm(`Delete operator ${operator.id}?`)) return;

    const result = await ApiHelper.deleteOperator(operator.id);
    ApiHelper.fetchOperators().then();
    if (!result) {
      alert("Error deleting operator: " + operator.id);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Operator details</h4>
                  <div className="overview__searchpanel">
                    <SearchBar
                      value={this.state.searchString}
                      onChange={this.onChangeSearch.bind(this)}
                    />
                  </div>
                </div>
              </div>

              <div className="content overview__content">
                {this.props.operators.length === 0 ? (
                  <Skeleton />
                ) : (
                  <Table
                    className="overview__table border"
                    striped
                    bordered
                    condensed
                    hover
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Subscription Type</th>
                        <th>Subscription Status</th>
                        <th>Created</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.operators
                        .filter(
                          (operator) =>
                            !this.state.searchString ||
                            operator.name
                              .toLowerCase()
                              .includes(this.state.searchString.toLowerCase()),
                        )
                        .map((operator) => (
                          <tr key={operator.id}>
                            <td>
                              <span>{operator.name}</span>
                            </td>
                            <td>{operator.subscriptionType}</td>
                            <td>
                              <span
                                style={{
                                  color: AppUtils.getStatusTextColor(
                                    operator.subscriptionStatus,
                                  ),
                                }}
                              >
                                {operator.subscriptionStatus}
                              </span>
                            </td>
                            <td>{formatDateOnly(operator?.createdDate)}</td>
                            <td>
                              <span
                                style={{
                                  color: AppUtils.getStatusTextColor(
                                    operator.status,
                                  ),
                                }}
                              >
                                {operator.status}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Button
                                variant="info"
                                onClick={this.openEditOperator.bind(
                                  this,
                                  operator.id,
                                )}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>

        <OperatorModal
          open={this.state.operatorModalOpen}
          setOpen={(val) => this.setState({ operatorModalOpen: val })}
          operator={this.state.operatorModalData}
          onModify={this.updateOperator.bind(this)}
          onSubmit={this.addOperator.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  operators: state.operator.operators,
});

export default withRouter(connect(mapStateToProps)(OperatorOverview));
