import React from "react";

const Skeleton = ({
  width = "w-full",
  height = "h-24",
  borderRadius = "rounded-xl",
}) => {
  return (
    <div
      className={`bg-gray-300 mt-10 ${width} ${height} ${borderRadius} animate-pulse m-auto`}
    />
  );
};

export default Skeleton;
