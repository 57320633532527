
export default class cellActions {
  static SET_CELLS = 'CELL/SET_CELLS';

  static setCells(cells) {
    return {
      type: this.SET_CELLS,
      cells: cells,
    };
  }
}
