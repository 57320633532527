import React from "react";
import { Route } from "react-router-dom";
import OperatorOverview from "./OperatorOverview";

const Operators = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={OperatorOverview} />
  </div>
);

export default Operators;
