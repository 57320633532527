import React from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import "@asymmetrik/leaflet-d3";
import ApiHelper from "../../../util/ApiHelper";

let hexLayer = null;

export function HexCellsLayer(props) {
  const { visible, mapData } = props;
  const map = useMap();

  const addLayer = async () => {
    if (mapData.length === 0) return;

    const radiusStr = 12;
    const radius =
      typeof Number(radiusStr.radius) === "number"
        ? Number(radiusStr.radius)
        : 12;

    if (hexLayer) {
      removeLayer();
    }

    hexLayer = L.hexbinLayer({
      radiusRange: [4, radius],
      colorRange: ["#27f4d3", "#d8306b"],
    })
      .hoverHandler(L.HexbinHoverHandler.tooltip())
      .addTo(map);

    let geoPoints = mapData.map((cell) => [cell.longitude, cell.latitude]);

    hexLayer.data(geoPoints);
  };

  const removeLayer = () => {
    hexLayer.removeFrom(map);
    hexLayer = null;
  };

  React.useEffect(() => {
    if (map) {
      addLayer();
    }
  }, [mapData]);

  React.useEffect(() => {
    if (map) {
      if (!visible) {
        if (hexLayer) {
          removeLayer();
        }
      } else {
        addLayer();
      }
    }
  }, [visible]);

  return null;
}

export default HexCellsLayer;
