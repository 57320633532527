import React from "react";
import { Table, Button } from "react-bootstrap";
import AppUtils, { formatDateOnly } from "../../../util/AppUtils";

const CellsTable = ({ cells, onDelete, onEdit }) => {
  return (
    <Table className="overview__table" striped bordered condensed hover>
      <thead>
        <tr>
          <th style={{ width: 220 }}>ID</th>
          <th>Band/Frequency</th>
          <th>Status</th>
          <th colSpan={2}>Created</th>
        </tr>
      </thead>
      <tbody>
        {cells.map((cell) => (
          <tr key={cell.uuid}>
            <td>{cell.uuid}</td>
            <td>{cell.band}</td>
            <td>
              <span
                style={{
                  color: AppUtils.getStatusTextColor(cell.status),
                }}
              >
                {cell.status}
              </span>
            </td>
            <td>{formatDateOnly(cell.createdDate)}</td>
            <td
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button variant="danger" onClick={() => onDelete(cell)}>
                Delete
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="info" onClick={() => onEdit(cell.uuid)}>
                View
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CellsTable;
