import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import { toast } from "react-toastify";
import { customFetch } from "../../util/ApiHelper";
import Footer from "../Main/Footer";
import { decodeBase64, toUtf8String } from "ethers";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";
import Skeleton from "../../components/ui/Skeleton";

const EnrollSign = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { address, isConnected } = useAppKitAccount();
  const history = useHistory();
  const { walletProvider } = useAppKitProvider("eip155");
  const updateWindowDimensions = useCallback(() => {
    setIsWidthBigger(
      (window.innerWidth * 0.67) / window.innerHeight > 650 / 525,
    );
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  async function checkEnrollStatus() {
    const getEnrollStatus = await customFetch("/apenrollmenstatus");
    if (getEnrollStatus.isEnrolled === true) {
      toast.error("Already enrolled");
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else if (getEnrollStatus.enrollmentInProgress === true) {
      setTimeout(() => {
        history.push("/enroll-loading");
      }, 2000);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    checkEnrollStatus();
  }, []);

  const handleSubmit = async () => {
    const personalizationData = sessionStorage.getItem("personalizationData");
    if (!personalizationData) {
      history.push("/");
    }

    const parsePersonalizationData = JSON.parse(personalizationData);
    const decodeForUser = decodeBase64(parsePersonalizationData);

    try {
      setSubmitDisabled(true);
      const walletType = JSON.parse(sessionStorage.getItem("walletType"));
      if (!walletType || walletType.custodial) {
        history.push("/");
      }
      const messageToSign = toUtf8String(decodeForUser);
      const parseDecodeForUser = toUtf8String(decodeForUser);

      const data = {
        dataToBeSigned: parseDecodeForUser,
        custodialWallet: walletType.custodial,
      };
      if (!walletType.custodial) {
        const ethereum = walletProvider;
        try {
          if (typeof window?.ethereum !== "undefined") {
            const accounts = await ethereum.request({
              method: "eth_requestAccounts",
            });
            const address = accounts[0];
            data.signature = await ethereum.request({
              method: "personal_sign",
              params: [messageToSign, address],
            });
          } else {
            setErrorMsg("Browser web3 wallet, e.g. MetaMask is not installed.");
          }
        } catch (error) {
          console.error("Error during signing process:", error);
          setErrorMsg("An error occurred during enrollment.");
        }
      }
      await customFetch("/finishpersonalization", "POST", data);
      setTimeout(() => {
        history.push("/enroll-loading");
      }, 2000);
    } finally {
      setSubmitDisabled(false);
    }
  };

  const onClickBack = () => {
    history.push("/");
  };

  return isLoading ? (
    <div className="flex justify-center items-center ">
      <Skeleton size="lg" className="mt-10 w-10 h-10" />
    </div>
  ) : (
    <div className="Login">
      <div className="HeroLogoContent">
        <Image
          src={HeroLogo}
          className={isWidthBigger ? "img-width" : "img-height"}
          alt="heroLogo"
        />
      </div>

      <div className="LoginFormContent">
        <div className="BackBtn" onClick={onClickBack}>
          <i className="fas fa-reply" />
        </div>
        <div className="LoginForm">
          <img src={Open6gcLogo} alt="Bloxtel Operator dApp" />
          <h3>Enroll Access Point</h3>

          <div style={{ textAlign: "center", padding: "10px" }}>
            <p>
              Click "Continue Enrollment" to proceed. You will then need to
              confirm the action in your Web 3 wallet popup.
            </p>
          </div>

          <span className="error-msg">
            <p>{errorMsg}</p>
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <button
              className="rounded-md bg-[#7769f9] text-white px-4 py-2 cursor-pointer"
              onClick={onClickBack}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`rounded-md bg-[#7769f9] text-white px-4 py-2 cursor-pointer ${
                submitDisabled || !isConnected ? "opacity-50" : ""
              }`}
              onClick={handleSubmit}
              disabled={submitDisabled || !isConnected}
            >
              Continue Enrollment
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EnrollSign;
