export default class orderActions {
  static SET_ORDERS = "ORDER/SET_ORDERS";

  static setOrders(orders) {
    return {
      type: this.SET_ORDERS,
      orders: orders,
    };
  }
}
