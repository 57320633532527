import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import CellModal from "./components/CellModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import CellsTable from "./components/CellsTable";
import { toast } from "react-toastify";

const useCellOperations = (operatorId) => {
  const [cellModalOpen, setCellModalOpen] = useState(false);
  const [cellModalData, setCellModalData] = useState(null);

  const openAddCell = () => {
    setCellModalOpen(true);
    setCellModalData(null);
  };

  const openEditCell = async (cellId) => {
    const cell = await ApiHelper.fetchCellById(operatorId, cellId);
    setCellModalOpen(true);
    setCellModalData(cell);
  };

  const addCell = async (cellData) => {
    setCellModalOpen(false);
    const success = await ApiHelper.createCell(cellData.operatorId, cellData);
    if (!success) {
      toast.error("Error creating new cell");
    }
    await ApiHelper.fetchCells(operatorId);
  };

  const updateCell = async (cellData) => {
    setCellModalOpen(false);
    const success = await ApiHelper.updateCell(cellData.operatorId, cellData);
    if (!success) {
      toast.error(`Error updating cell: ${cellData.uuId}`);
    }
    await ApiHelper.fetchCells(operatorId);
  };

  const deleteCell = async (cell) => {
    if (!window.confirm(`Delete Cell ${cell.uuid}?`)) return;

    const success = await ApiHelper.deleteCell(operatorId, cell.uuid);
    if (!success) {
      toast.error(`Error deleting cell: ${cell.uuid}`);
    }
    await ApiHelper.fetchCells(operatorId);
  };

  return {
    cellModalOpen,
    cellModalData,
    setCellModalOpen,
    openAddCell,
    openEditCell,
    addCell,
    updateCell,
    deleteCell,
  };
};

const CellOverview = () => {
  const history = useHistory();
  const [searchString, setSearchString] = useState("");

  const user = useSelector((state) => state.auth.user);
  const cells = useSelector((state) => state.cell.cells);

  const {
    cellModalOpen,
    cellModalData,
    setCellModalOpen,
    openAddCell,
    openEditCell,
    addCell,
    updateCell,
    deleteCell,
  } = useCellOperations(user.operatorId);

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([
        ApiHelper.fetchCells(user.operatorId),
        ApiHelper.fetchGateways(user.operatorId),
        ApiHelper.fetchOperators(),
      ]);
    };
    fetchInitialData();
  }, [user.operatorId]);

  const filteredCells = cells.filter(
    (cell) =>
      cell.uuid.includes(searchString) ||
      cell.band.includes(searchString) ||
      cell.status.includes(searchString),
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <div className="overview__searchbar">
                <h4>Small Cells</h4>
                <div className="overview__searchpanel">
                  <SearchBar
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                  <div
                    onClick={() => history.push("/firmware/devices")}
                    className="overview__searchpanel__extra-icon"
                  >
                    <i className="fas fa-th-large" />
                    <span style={{ marginLeft: 5 }}>Firmware</span>
                  </div>
                </div>
              </div>
              <Button
                bsStyle="primary"
                className="overview__button"
                onClick={openAddCell}
              >
                New Cell
              </Button>
            </div>

            <div className="content overview__content">
              <CellsTable
                cells={filteredCells}
                onDelete={deleteCell}
                onEdit={openEditCell}
              />
            </div>
          </div>
        </div>
      </div>

      <CellModal
        open={cellModalOpen}
        setOpen={setCellModalOpen}
        isSuper={user.role === "Admin"}
        cell={cellModalData}
        onModify={updateCell}
        onSubmit={addCell}
      />
    </div>
  );
};

export default CellOverview;
