import axios from "axios";
import config from "../config/config";
import { store } from "../index";
import AuthHelper from "./AuthHelper";
import customHistory from "./history";

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.crossdomain = true;

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    let user = store.getState().auth.user;
    if (user) {
      config.headers.Token = user.accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { status } = error.response;
    if (status === 401) {
      await AuthHelper.logout();
      console.info("unauthenticated, login redirect");
      customHistory.push("/");
      // window.location.reload()
    }
    return Promise.reject(error);
  },
);

export default axios;
