import React from "react";
import { Route } from "react-router-dom";
import CellOverview from "./DevicesOverview";

const Devices = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={CellOverview} />
  </div>
);

export default Devices;
