import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import { ToastContainer } from "react-toastify";
import ApiHelper from "../../util/ApiHelper";
import Footer from "../Main/Footer";
import { signMessage } from "../../util/blockchainUtils";
import { toUtf8String, decodeBase64, ethers } from "ethers";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";

const EnrollSign = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [geolocationLoading, setGeolocationLoading] = useState(true);
  const [geolocationError, setGeolocationError] = useState(false);
  const { address } = useAppKitAccount();
  const history = useHistory();
  const { walletProvider } = useAppKitProvider("eip155");
  const updateWindowDimensions = useCallback(() => {
    setIsWidthBigger(
      (window.innerWidth * 0.67) / window.innerHeight > 650 / 525
    );
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
          setGeolocationLoading(false);
        },
        () => {
          setGeolocationLoading(false);
          setGeolocationError(true);
        }
      );
    } else {
      setGeolocationLoading(false);
      setGeolocationError(true);
    }

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  const handleSubmit = async () => {
    const personalizationData = localStorage.getItem("personalizationData");
    const parsePersonalizationData = JSON.parse(personalizationData);
    const decodeForUser = decodeBase64(parsePersonalizationData);
    try {
      const signedData = await signMessage(
        decodeForUser,
        address,
        walletProvider
      );

      const parseDecodeForUser = toUtf8String(decodeForUser);

      const data = {
        dataToBeSigned: parseDecodeForUser,
        signature: signedData,
      };

      const response = await ApiHelper.finishPersonalization(data);
      console.log("response", response);

      // history.push("/");
    } catch (error) {
      console.error("Error during signing process:", error);
      setErrorMsg("An error occurred during enrollment.");
    } finally {
      setSubmitDisabled(false);
    }
  };

  const onClickBack = () => {
    history.push("/");
  };

  return (
    <div className="Login">
      <ToastContainer />
      <div className="HeroLogoContent">
        <Image
          src={HeroLogo}
          className={isWidthBigger ? "img-width" : "img-height"}
          alt="heroLogo"
        />
      </div>

      <div className="LoginFormContent">
        <div className="BackBtn" onClick={onClickBack}>
          <i className="fas fa-reply" />
        </div>
        <div className="LoginForm">
          <img src={Open6gcLogo} alt="Bloxtel Operator dApp" />
          <h3>Enroll Access Point</h3>

          <div style={{ textAlign: "center", padding: "10px" }}>
            <p>Please open your MetaMask wallet and sign the data.</p>
          </div>

          <span className="error-msg">
            <p>{errorMsg}</p>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <Button onClick={onClickBack}>Cancel</Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={submitDisabled}
            >
              Finish Enrollment
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EnrollSign;
