import React from "react";
import UserModal from "./components/UserModal";
import { customFetch } from "../../util/ApiHelper";
import GenericDataList from "../../components/GenericDataList";

export const UserOverview = () => {
  // Function to fetch user data
  const fetchUsers = async () => {
    return await customFetch(`/operatorUser`, "GET");
  };

  // Function to add a new user; return true on success.
  const addUser = async (userData) => {
    const result = await customFetch(`/operatorUser`, "POST", userData);
    return !!result;
  };

  const getUser = async (userData) => {
    return await customFetch(`/operatorUser/${userData.userId}`);
  };

  // Function to update a user; return true on success.
  const updateUser = async (userData) => {
    const result = await customFetch(
      `/operatorUser/${userData.userId}`,
      "PUT",
      userData,
    );
    return !!result;
  };

  // Function to delete a user; return true on success.
  const deleteUser = async (user) => {
    const result = await customFetch(`/operatorUser/${user.userId}`, "DELETE");
    return !!result;
  };

  const columns = [
    {
      header: "First Name",
      searchable: true,
      field: "firstName",
    },
    {
      header: "Last Name",
      searchable: true,
      field: "lastName",
    },
    {
      header: "Email",
      field: "email",
      searchable: true,
    },
    {
      header: "Created",
      field: "createdDate",
    },
  ];

  return (
    <GenericDataList
      fetchAllData={fetchUsers}
      addData={addUser}
      getData={getUser}
      updateData={updateUser}
      deleteData={deleteUser}
      allowAdd={true}
      allowDelete={true}
      dataName="User"
      deleteItemName="email"
      itemKey="userId"
      columns={columns}
      DetailComponent={UserModal}
    />
  );
};

export default UserOverview;
