import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import PropTypes from "prop-types";
import {
  dSIMBatchSchema,
  dsimBatchEditSchema,
  dSIMBatchUISchema,
} from "../forms";
import SupportedNetworkSliceWidget from "../../../components/NetworkSlice";
import QoSIdentifierWidget from "../../../components/5QLSlice";
import {
  PlanNameWidget,
  OperatorNameWidget,
  OrderNameWidget,
} from "../../../components/IdToName";
import PLMNSelector from "../../../components/PLMNSelector";

class dSIMBatchModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    dsimBatch: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
  };

  currentSchema = dSIMBatchSchema;

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.dsimBatch });
      if (this.props.dsimBatch) {
        const dSIMBatch = this.props.dsimBatch;
        this.currentSchema = dsimBatchEditSchema;
        let formData = {
          batchQuantity: dSIMBatch["batchQuantity"],
          servingPlmnId: dSIMBatch["servingPlmnId"],
          orderId: dSIMBatch["orderId"],
          planId: dSIMBatch["planId"],
          fiveQI: dSIMBatch["fiveQI"],
          networkSlice: {
            sst: dSIMBatch["networkSlice"]["sst"],
            sd: dSIMBatch["networkSlice"]["sd"],
          },
        };
        this.updateFormData(formData).then();
      } else {
        let formData = {};
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    await this.updateFormData(data.formData);
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    // Create a deep copy of the form data to avoid modifying the original
    const formData = JSON.parse(JSON.stringify(result.formData));

    let dsimBatchData = {
      servingPlmnId: formData.servingPlmnId || "",
      orderId: formData.orderId || "",
      batchQuantity: formData.batchQuantity || 1,
      planId: formData.planId || "",
      fiveQI: formData.fiveQI || 9,
      networkSlice: {
        sst: formData.networkSlice?.sst || 1,
        sd: formData.networkSlice?.sd || "000000",
      },
    };

    if (this.state.editMode) {
      this.props.onModify(dsimBatchData);
    } else {
      this.props.onSubmit(dsimBatchData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className="dsims__modal"
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit dSIMs" : "New dSIMs"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            schema={this.currentSchema}
            uiSchema={dSIMBatchUISchema}
            formData={this.state.formData}
            onChange={this.onChange.bind(this)}
            onSubmit={this.onSubmitClick.bind(this)}
            validator={validator}
            fields={{
              SupportedNetworkSliceWidget,
              QoSIdentifierWidget,
              OrderNameWidget,
              PlanNameWidget,
              OperatorNameWidget,
              PLMNSelector,
            }}
            noHtml5Validate={true}
            liveValidate={false}
            showErrorList={false}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default dSIMBatchModal;
