import React, { useState, useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import HeatmapMap from "./Heatmap";

const HeatmapModal = ({ open, setOpen, heatmapData, serverURL }) => {
  if (heatmapData) {
    //console.error("heatmap data", heatmapData);
  }

  if (!heatmapData) {
    return (
      <Modal
        show={open}
        className={"device-modal theme-light"}
        backdrop={"static"}
        onHide={() => setOpen(false)}
      ></Modal>
    );
  }

  return (
    <Modal
      show={open}
      className={"device-modal theme-light"}
      backdrop={"static"}
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Movement of individuals on the site
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="device-modal__header">
                  {/* <div className="device-modal__header__title">Movement of individuals on the site</div> */}
                </div>
                <div className="device-modal__content device-modal__content__border">
                  <HeatmapMap heatmapData={heatmapData} serverURL={serverURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HeatmapModal;
