import React from "react";
import {
  userModalEditSchema,
  userModalSchema,
  userModalUISchema,
} from "../forms";
import GenericDataView from "../../../components/GenericDataView";

const UserModal = ({ open, setOpen, data, onModify, onCreate }) => {
  return (
    <GenericDataView
      dataName="User"
      open={open}
      onCreate={onCreate}
      onModify={onModify}
      data={data}
      setOpen={setOpen}
      superSchema={userModalSchema}
      operatorSchema={userModalSchema}
      editOperatorSchema={userModalEditSchema}
      editSuperSchema={userModalEditSchema}
      uiEditSuperSchema={userModalUISchema}
      uiEditOperatorSchema={userModalUISchema}
      uiOperatorSchema={userModalUISchema}
      uiSuperSchema={userModalUISchema}
    />
  );
};

export default UserModal;
