import actions from '../actions/blockActions';

const initialState = {
  blocks: [],
  blocksMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_BLOCKS:
      nextState.blocks = action.blocks;
      nextState.blocksMap = createBlocksMap(action.blocks);
      return nextState;

    default:
      return state;
  }
}

function createBlocksMap(blocks) {
  let blocksMap = {};
  blocks.forEach(wallets => blocksMap[blocks['id']] = blocks);
  return blocksMap;
}
