import actions from '../actions/cameraActions';

const initialState = {
  cameras: [],
  camerasMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_CAMERAS:
      nextState.cameras = action.cameras;
      nextState.camerasMap = createCamerasMap(action.cameras);
      return nextState;

    default:
      return state;
  }
}

function createCamerasMap(cameras) {
  let camerasMap = {};
  cameras.forEach(cameras => camerasMap[cameras['id']] = cameras);
  return camerasMap;
}
