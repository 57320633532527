import actions from '../actions/invoiceActions';

const initialState = {
  invoices: [],
  invoicesMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_INVOICES:
      nextState.invoices = action.invoices;
      nextState.invoicesMap = createInvoicesMap(action.invoices);
      return nextState;

    default:
      return state;
  }
}

function createInvoicesMap(invoices) {
  let invoicesMap = {};
  invoices.forEach(wallets => invoicesMap[invoices['id']] = invoices);
  return invoicesMap;
}
