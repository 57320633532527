export default class cameraActions {
  static SET_CAMERAS = "CAMERA/SET_CAMERAS";

  static setCameras(cameras) {
    return {
      type: this.SET_CAMERAS,
      cameras: cameras,
    };
  }
}
