import React from "react";
import AccessPointOverview from "./AccessPointsOverview";
import APStatus from "./AccessPointStatus";
import { Switch, Route } from "react-router-dom";
import APLogViewer from "./APLogViewer";

const AccessPoints = ({ match }) => {
  return (
    <div className="content">
      <Switch>
        <Route path={`${match.url}/list`} component={AccessPointOverview} />
        <Route path={`${match.url}/status`} component={APStatus} />
        <Route path={`${match.url}/log`} component={APLogViewer} />
      </Switch>
    </div>
  );
};

export default AccessPoints;
