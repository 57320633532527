import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from 'prop-types';
import { walletModalSchema } from '../../../metadata/index'

class WalletModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    wallet: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
  };

  schema = walletModalSchema;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.wallet });

      if (this.props.wallet) {
        const wallet = this.props.wallet;

        let formData = {
          walletId: wallet['walletId'],
          walletName: wallet['walletName'],
        };

        this.updateFormData(formData).then();
      } else {
        let formData = {
          walletId: "",
          walletName: "",
        };
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;

    if (lastData && lastData.walletId === undefined)
      lastData.walletId = "";
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    let walletData = {
      "walletId": formData["walletId"],
      "walletName": formData["walletName"]
    };

    if(this.state.editMode) {
      this.props.onModify(walletData);
    } else {
      this.props.onSubmit(walletData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {"Edit Wallet"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 &&
            <Form schema={this.schema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)} />
          }
        </Modal.Body>
      </Modal>
    );

  }
}

export default WalletModal;
