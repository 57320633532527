import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import Footer from "../Main/Footer";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import { toast } from "react-toastify";
import { Button } from "../../components/ui/Button";
import { customFetch } from "../../util/ApiHelper";
import Spinner from "../../components/ui/Spinner";
const EnrollLoading = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [error, setError] = useState(false);
  const [geolocationLoading, setGeolocationLoading] = useState(true);
  const [geolocationError, setGeolocationError] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isEnrollmentInProgress, setIsEnrollmentInProgress] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsWidthBigger(
        (window.innerWidth * 0.67) / window.innerHeight > 650 / 525,
      );
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    getGeolocation();
    setSubmitDisabled(true);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkEnrollStatus() {
    const getEnrollStatus = await customFetch("/apenrollmenstatus");
    if (getEnrollStatus.enrollmentFailed === true) {
      setError(true);
      setErrorMsg(
        `Enrollment failed, try again. ${getEnrollStatus.enrollmentError}`,
      );
    } else {
      setIsEnrolled(getEnrollStatus.isEnrolled);
      setIsEnrollmentInProgress(getEnrollStatus.enrollmentInProgress);
    }
  }

  useEffect(() => {
    if (error === false) {
      checkEnrollStatus();

      const intervalId = setInterval(() => {
        checkEnrollStatus();
      }, 4000);

      return () => clearInterval(intervalId);
    }
  }, [error]);

  useEffect(() => {
    if (isEnrolled === true && isEnrollmentInProgress === false) {
      toast.success("Successfully enrolled");
      setTimeout(() => {
        history.push("/");
      }, 2000);
    }
  }, [isEnrolled, isEnrollmentInProgress]);

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
          setGeolocationLoading(false);

          if (isEnrolled === true && isEnrollmentInProgress === false) {
            toast.success("Successfully enrolled");
            setTimeout(() => {
              history.push("/");
            }, 2000);
          }
        },
        () => {
          setGeolocationLoading(false);
          setGeolocationError(true);
        },
      );
    } else {
      setGeolocationLoading(false);
      setGeolocationError(true);
    }
  };

  useEffect(() => {
    if (isEnrolled === true) {
      setTimeout(() => {
        toast.success("Successfully enrolled");
        history.push("/");
      }, 2000);
    }
  }, [isEnrolled, isEnrollmentInProgress]);

  const validateForm = () => {
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationError = validateForm();

    if (validationError) {
      setErrorMsg(validationError);
      return;
    }
    setSubmitDisabled(true);
    setErrorMsg("");
  };

  return (
    <div className="Login">
      <div
        className="HeroLogoContent"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Image
          src={HeroLogo}
          className={isWidthBigger ? "img-width" : "img-height"}
          style={{ height: "100%", objectFit: "cover" }}
          alt="heroLogo"
        />
      </div>

      <div className="LoginFormContent">
        <div className="LoginForm">
          <img src={Open6gcLogo} alt="Bloxtel Operator dApp" />
          <h3>Enroll Access Point</h3>

          <div style={{ textAlign: "center", padding: "10px" }}>
            <p>Your Access Point is being personalized. Please wait.</p>
          </div>

          <span className="error-msg" style={{ padding: "10px" }}>
            <p>{errorMsg}</p>
          </span>

          {!error && (isEnrollmentInProgress || geolocationLoading) && (
            <div style={{ margin: "20px 0" }}>
              <Spinner size="100px" color="border-green-500" />
            </div>
          )}

          {error && (
            <Button onClick={() => history.push("/")} variant="primary">
              Try again
            </Button>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EnrollLoading;
