export default class planActions {
  static SET_PLANS = "PLAN/SET_PLANS";

  static setPlans(plans) {
    return {
      type: this.SET_PLANS,
      plans: plans,
    };
  }
}
