import { IndustryData } from "../../config/industry";
import React, { useEffect, useState } from "react";

// Default image to use when operator image is empty or broken
const DEFAULT_LOGO = "/assets/images/dsim_logo.png";

export let operatorModalSchema = {
  type: "object",
  required: ["businessName", "businessAddress"],
  properties: {
    operatorLogo: {
      type: "string",
      default: DEFAULT_LOGO,
      title: "Operator Logo",
    },
    operatorId: {
      type: "string",
      title: "Operator ID",
      pattern: "^[0-9a-zA-Z-]*$",
      default: "",
      readOnly: true,
    },
    businessName: {
      type: "string",
      title: "Organization Name",
      default: "",
    },
    businessAddress: {
      type: "string",
      title: "Organization Address",
      default: "",
    },
    businessIndustry: {
      type: "string",
      title: "Industry",
      enum: IndustryData,
      default: "",
    },
    subscriptionType: {
      type: "string",
      title: "Subscription Type",
      enum: ["Basic", "Standard", "Advanced"],
      default: "Basic",
      readOnly: true,
    },
    subscriptionStatus: {
      type: "string",
      title: "Subscription Status",
      enum: ["PENDING", "APPROVED"],
      default: "PENDING",
      readOnly: true,
    },
    status: {
      type: "string",
      title: "Status",
      enum: ["INACTIVE", "ACTIVE"],
      default: "INACTIVE",
      readOnly: true,
    },
    walletAddress: {
      type: "string",
      title: "Wallet Address",
      default: "",
      readOnly: true,
    },
  },
};

export let operatorNewModalSchema = {
  type: "object",
  required: ["businessName", "businessAddress"],
  properties: {
    operatorId: {
      type: "string",
      title: "Operator ID",
      pattern: "^[0-9a-zA-Z-]*$",
      default: "",
      readOnly: true,
    },
    businessName: {
      type: "string",
      title: "Organization Name",
      default: "",
    },
    businessAddress: {
      type: "string",
      title: "Organization Address",
      default: "",
    },
    businessIndustry: {
      type: "string",
      title: "Industry",
      enum: IndustryData,
      default: "",
    },
    subscriptionType: {
      type: "string",
      title: "Subscription Type",
      enum: ["Basic", "Standard", "Advanced"],
      default: "Basic",
    },
    subscriptionStatus: {
      type: "string",
      title: "Subscription Status",
      enum: ["PENDING", "APPROVED"],
      default: "PENDING",
    },
    email: {
      type: "string",
      title: "User Email",
      default: "",
    },
    password: {
      type: "string",
      title: "Password",
      default: "",
    },
    status: {
      type: "string",
      title: "Status",
      enum: ["INACTIVE", "ACTIVE"],
      default: "INACTIVE",
    },
    walletAddress: {
      type: "string",
      title: "Wallet Address",
      default: "",
    },
  },
};

export let operatorModalUISchema = {
  operatorId: { "ui:options": { widget: "hidden" } },
  operatorLogo: {
    "ui:widget": (props) => {
      const [file, setFile] = useState(props.value || DEFAULT_LOGO);
      const [imageError, setImageError] = useState(false);

      useEffect(() => {
        if (props.value) {
          setFile(props.value);
          setImageError(false);
        }
      }, [props]);

      // Convert file to base64
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

      const handleChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
          const selectedFile = event.target.files[0];

          try {
            // Create a temporary URL for preview
            const url = URL.createObjectURL(selectedFile);
        setFile(url);
            setImageError(false);

            // Convert to base64 for storage
            const base64 = await convertToBase64(selectedFile);
            props.onChange(base64);
          } catch (error) {
            console.error("Error processing image:", error);
            setImageError(true);
            props.onChange(DEFAULT_LOGO);
          }
        }
      };

      const handleImageError = () => {
        setImageError(true);
        if (props.value !== DEFAULT_LOGO) {
          props.onChange(DEFAULT_LOGO);
        }
      };

      return (
        <div>
          <img
            style={{ margin: 10 }}
            alt="logo"
            width={150}
            src={imageError ? DEFAULT_LOGO : file}
            onError={handleImageError}
          />
          <input
            style={{ margin: 10 }}
            type="file"
            onChange={handleChange}
            accept="image/*"
          />
        </div>
      );
    },
  },
};
