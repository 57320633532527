import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleMobileNavVisibility } from "../../redux/reducers/layout";
// import { Nav, Navbar, NavItem } from "react-bootstrap";
import { Navbar } from "../../components/Navbar";
import { withRouter } from "react-router-dom";
import AuthHelper from "../../util/AuthHelper";
import Profile from "../../components/Profile/Profile";

class Header extends Component {
  static async logout() {
    await AuthHelper.logout();
  }

  render() {
    return (
      // <header className=" text-black shadow-md h-14">
      //   <div className="container mx-auto flex items-center justify-between p-4">
      //     {/* Toggle Button */}
      //     <button
      //       type="button"
      //       className="lg:hidden flex items-center space-y-1 focus:outline-none"
      //       onClick={this.props.toggleMobileNavVisibility}
      //     >
      //       <div className="space-y-1 text-black">
      //         <span className="block h-1 w-6 bg-black"></span>
      //         <span className="block h-1 w-6 bg-black"></span>
      //         <span className="block h-1 w-6 bg-black"></span>
      //       </div>
      //     </button>

      //     <div className="hidden lg:flex items-center space-x-4 text-black">
      //       <Profile role={this.props.user.role} />
      //     </div>
      //   </div>
      // </header>
      <Navbar />
    );
  }
}

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProp)(Header));
