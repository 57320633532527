import React from 'react';
import {Route} from 'react-router-dom';
import PayoutsOverview from './PayoutsOverview';

const Payouts = ({match}) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={PayoutsOverview} />
  </div>
);

export default Payouts;
