import React from "react";

const QoSIdentifierWidget = (props) => {
  const { formData, onChange } = props;

  const qiMapping = {
    1: {
      short: "Conversational Voice",
      long: "Optimized for voice calls with ultra-low latency and minimal jitter.",
      resourceType: "GBR",
      priority: 20,
      packetDelay: "100ms",
      packetErrorRate: "10^-2",
    },
    2: {
      short: "Conversational Video",
      long: "Tailored for video calls with slightly relaxed latency requirements compared to voice.",
      resourceType: "GBR",
      priority: 40,
      packetDelay: "150ms",
      packetErrorRate: "10^-3",
    },
    3: {
      short: "Real-Time Gaming",
      long: "Provides very low latency and jitter, crucial for interactive gaming.",
      resourceType: "GBR",
      priority: 30,
      packetDelay: "50ms",
      packetErrorRate: "10^-3",
    },
    4: {
      short: "Non-Conversational Video (Buffered Streaming)",
      long: "Suited for video streaming where buffering can accommodate higher latency.",
      resourceType: "GBR",
      priority: 50,
      packetDelay: "300ms",
      packetErrorRate: "10^-6",
    },
    5: {
      short: "IMS Signaling",
      long: "Prioritized for IP Multimedia Subsystem signaling with high reliability and low delay.",
      resourceType: "Non-GBR",
      priority: 10,
      packetDelay: "100ms",
      packetErrorRate: "10^-6",
    },
    6: {
      short: "Mission-Critical Applications",
      long: "Designed for services like emergency communications that require very low latency.",
      resourceType: "Non-GBR",
      priority: 60,
      packetDelay: "300ms",
      packetErrorRate: "10^-6",
    },
    7: {
      short: "Non-Interactive Video",
      long: "For video applications where real-time interaction is less critical.",
      resourceType: "Non-GBR",
      priority: 70,
      packetDelay: "100ms",
      packetErrorRate: "10^-3",
    },
    8: {
      short: "Low-Latency Data Applications",
      long: "Supports applications that are sensitive to delay even if they aren't interactive.",
      resourceType: "Non-GBR",
      priority: 80,
      packetDelay: "300ms",
      packetErrorRate: "10^-6",
    },
    9: {
      short: "Default/Best Effort",
      long: "The standard profile for general internet traffic, where best-effort service is acceptable.",
      resourceType: "Non-GBR",
      priority: 90,
      packetDelay: "300ms",
      packetErrorRate: "10^-6",
    },
  };

  const handleSelect = (e) => {
    const selectedQI = parseInt(e.target.value, 10);
    onChange(selectedQI);
  };

  return (
    <div className="qos-identifier-widget">
      <label className="control-label">
        5G Quality of Service Identifier (5QI)*
      </label>
      <select
        onChange={handleSelect}
        value={formData || 9}
        className="form-control"
      >
        <option value="">Select 5QI</option>
        {Object.keys(qiMapping).map((qi) => {
          const qiNum = parseInt(qi, 10);
          const mapping = qiMapping[qiNum];
          return (
            <option key={qiNum} value={qiNum} title={mapping.long}>
              {`5QI ${qiNum} – ${mapping.short}`}
            </option>
          );
        })}
      </select>
      {formData && qiMapping[formData] && (
        <div className="mt-2 text-base text-gray-500">
          <div className="font-semibold text-sm">
            {qiMapping[formData].short}
          </div>
          <div>{qiMapping[formData].long}</div>
        </div>
      )}
    </div>
  );
};

export default QoSIdentifierWidget;
