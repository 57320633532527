import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button, Table, Pagination } from "react-bootstrap";
import ApiHelper from "../../../util/ApiHelper.js";
import SearchBar from '../../../components/FormInputs/SearchBar.js';

import LoggingInfoModal from './LoggingModal.js';
import HeatingModal from './Heatmap/HeatmapModal.js';

const LoggingOverview = () => {
  const [loggingModalOpen, setLoggingModalOpen] = useState(false);
  const [heatingModalOpen, setHeatingModalOpen] = useState(false);
  const [loggingModalData, setLoggingModalData] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [loggingData, setLoggingData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [aimacServerURL, setAimacServerURL] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await getLoggingData();
    };

    fetchData();
    const intervalId = setInterval(() => {
      setIsUpdating(true);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [aimacServerURL]);

  useEffect(() => {
    if (isUpdating) {
      refreshTable();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const getLoggingData = async () => {
    try {
      const aimacUrl = await ApiHelper.getAimacServerIP();
      const baseURL = `http://${aimacUrl.serverIP}:${aimacUrl.serverPort}`;
      const loggingData = await ApiHelper.getCameraLogging(baseURL);

      if (loggingData) {
        setLoggingData(loggingData);
        setAimacServerURL(baseURL);
      } else {
        setLoggingData([]);
      }
    } catch (error) {
      console.error("Error fetching logging data:", error);
      setLoggingData([]);
    }
  };

  const refreshTable = async () => {
    try {
      const loggingData = await ApiHelper.getCameraLogging(aimacServerURL);

      if (loggingData) {
        setLoggingData(loggingData);
      } else {
        setLoggingData([]);
      }
    } catch (error) {
      console.error("Error refreshing table data:", error);
      setLoggingData([]);
    }
  };

  const sortLoggingData = (data) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.dateTime);
      const dateB = new Date(b.dateTime);
      return dateB - dateA; // Descending order
    });
  };

  const handleSearchChange = (event) => {
    setSearchString(event.target.value);
  };

  const handleBack = () => {
    history.push('/cameras');
  };

  // Get paginated data
  const getPaginatedData = () => {
    const sortedData = sortLoggingData(loggingData);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedData.slice(startIndex, endIndex);
  };

  // Pagination controls
  const totalPages = Math.ceil(loggingData.length / itemsPerPage);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const generatePaginationItems = () => {
    const items = [];
    const maxPagesToShow = 5; // Number of pages to show before truncating

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      const start = Math.max(1, currentPage - 2);
      const end = Math.min(totalPages, currentPage + 2);

      if (start > 1) {
        items.push(
          <Pagination.Item
            key={1}
            active={1 === currentPage}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        );
        if (start > 2) {
          items.push(
            <Pagination.Ellipsis key="start-ellipsis" />
          );
        }
      }

      for (let i = start; i <= end; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (end < totalPages) {
        if (end < totalPages - 1) {
          items.push(
            <Pagination.Ellipsis key="end-ellipsis" />
          );
        }
        items.push(
          <Pagination.Item
            key={totalPages}
            active={totalPages === currentPage}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <div className="overview__searchbar">
                <span onClick={handleBack} className='overview_backBtn'>
                  <i className="fas fa-chevron-left"/>
                </span>
                <h4>Camera Logging</h4>
                <div className="overview__searchpanel">
                  <SearchBar value={searchString} onChange={handleSearchChange} />
                  <div onClick={() => setHeatingModalOpen(true)} className='overview__searchpanel__extra-icon'>
                    <i className="fas fa-th-large"/>
                    <span style={{ marginLeft: 5}}>Heatmap</span>
                  </div>
                </div>
              </div>
              <div onClick={refreshTable} className='overview__button refresh__button'>
                <i className="fas fa-sync-alt user-icon"/>
              </div>
            </div>
            <div className="content overview__content">
              <Table className="overview__table" striped bordered condensed hover>
                <thead>
                  <tr>
                    <th>Identifier Number</th>
                    <th>Target Id</th>
                    <th>Event Type</th>
                    <th>Camera</th>
                    <th>Date & Time</th>
                    <th>Location</th>
                    <th>Occurrences</th>
                    <th>Image/Video</th>
                  </tr>
                </thead>
                <tbody>
                  {(loggingData.length > 0) ? getPaginatedData().map((data, i) => (
                    <tr key={data.eventNumber}>
                      <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                      <td>{data.targetID}</td>
                      <td>{data.eventType}</td>
                      <td>{data.camera}</td>
                      <td>{moment(data.dateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{data.location}</td>
                      <td>{data.occurrences}</td>
                      <td><Button bsStyle={"primary"} onClick={() => { setLoggingModalData(data); setLoggingModalOpen(true); }}>View</Button></td>
                    </tr>
                  )) : <tr><td style={{ textAlign: 'center', fontSize: 18, opacity: 0.7 }} colSpan={8}>No records</td></tr>}
                </tbody>
              </Table>
              <div className="pagination-container">
                <Pagination>
                  <Pagination.Prev onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)} />
                  {generatePaginationItems()}
                  <Pagination.Next onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)} />
                </Pagination>
              </div>
              <p>&nbsp;</p><p>&nbsp;</p>
              <p>&nbsp;</p><p>&nbsp;</p>
              <p>&nbsp;</p><p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
      <LoggingInfoModal
        open={loggingModalOpen}
        setOpen={setLoggingModalOpen}
        loggingData={loggingModalData}
        serverURL={aimacServerURL}
      />
      <HeatingModal
        open={heatingModalOpen}
        setOpen={setHeatingModalOpen}
        heatmapData={loggingData}
        serverURL={aimacServerURL}
      />
    </div>
  );
};

export default LoggingOverview;
