import React, { useState } from "react";
import { useAppKit, useAppKitProvider } from "@reown/appkit/react";
import { ConnectButton } from "../ui/ConnectButton";

function WalletInput({ walletAddress, onWalletAddressChange }) {
  const { open } = useAppKit();
  const { walletProvider } = useAppKitProvider("eip155");
  const [useInput, setUseInput] = useState(false); // Estado para alternar entre conectar wallet o ingresar manualmente

  const handleAddressChange = (event) => {
    onWalletAddressChange(event.target.value);
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <div className="flex flex-col  justify-between items-start mb-4">
        <label className="text-gray-700 font-bold">
          Connect your web3 wallet ?
        </label>
        <label className="flex items-center space-x-2">
          <span className="text-sm">
            It will be used to keep ownership of your tokenized assets
          </span>

          <input
            type="checkbox"
            checked={useInput}
            onChange={() => setUseInput(!useInput)}
            className="toggle-switch"
          />
        </label>
      </div>

      {!useInput ? (
        // Modo de ingreso manual
        <section className="flex flex-col"></section>
      ) : (
        // Modo de conectar wallet
        <section
          className="flex flex-col"
          onClick={() => {
            open();
          }}
        >
          {walletAddress ? (
            <>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="wallet"
              >
                Connected Wallet Address
              </label>
              <input
                id="wallet"
                type="text"
                value={walletAddress}
                readOnly
                className="w-full px-3 py-2 border rounded-xl bg-gray-100 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </>
          ) : (
            <section className="flex flex-col justify-center items-center">
              <p className="text-bloxtel-red text-sm">Not connected</p>
              <ConnectButton />
            </section>
          )}
        </section>
      )}
    </div>
  );
}

export default WalletInput;
