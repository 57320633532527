import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, FormControl, FormGroup, Image, Table } from "react-bootstrap";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import { ToastContainer } from "react-toastify";
import ApiHelper from "../../util/ApiHelper";
import Footer from "../Main/Footer";

class Personalize extends Component {
  state = {
    submitDisabled: false,
    errorMsg: "",
    password: "",
    passwordConfirm: "",
    isWidthBigger: false,
    longitude: "",
    latitude: "",
    height: "",
    geolocationLoading: true,
    geolocationError: false,
  };

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.getGeolocation();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      isWidthBigger:
        (window.innerWidth * 0.67) / window.innerHeight > 650 / 525,
    });
  }

  async getGeolocation() {
    try {
      const response = await ApiHelper.getGeolocation();

      const geolocation = response;

      const { longitude, latitude, height } = geolocation;

      if (geolocation) {
        await this.setState({
          longitude,
          latitude,
          height,
          geolocationLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        geolocationLoading: false,
        geolocationError: true,
      });
    }
  }

  validateForm() {
    return "";
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.validateForm()) {
      this.setState({ errorMsg: this.validateForm() });
      return;
    }

    this.setState({ submitDisabled: true, errorMsg: "" });

    const data = {
      longitude: this.state.longitude,
      latitude: this.state.latitude,
      height: this.state.height,
      walletAddress: "0x151a719163d8C040aE3E6391EDA1638fC390Caf1",
    };

    const response = await ApiHelper.startPersonalization(data);
    if (response) {
      console.log("Personalization request submitted successfully:", response);
      // save the response to the local storage
      localStorage.setItem(
        "personalizationData",
        JSON.stringify(response.data_to_be_signed)
      );

      this.props.history.push("/enroll-sign");
    } else {
      console.error("Failed to submit personalization request");
    }

    this.setState({ submitDisabled: false, errorMsg: "" });
  }

  onClickBack = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="Login">
        <ToastContainer />
        <div className="HeroLogoContent">
          <Image
            src={HeroLogo}
            className={`${
              this.state.isWidthBigger ? "img-width" : "img-height"
            }`}
            alt="heroLogo"
          />
        </div>

        <div className="LoginFormContent">
          <div className="BackBtn" onClick={this.onClickBack}>
            <i className="fas fa-reply" />
          </div>
          <div className="LoginForm">
            <img src={Open6gcLogo} alt="Bloxtel Operator dApp" />
            <h3>Enroll Access Point</h3>

            <div style={{ textAlign: "center", padding: "10px" }}>
              <p>
                Your geolocation will be retrieved automatically. Please wait a
                moment.
              </p>
              <p>
                Please provide the geolocation of your access point manually, if
                it takes too long.
              </p>
            </div>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>Longitude:</td>
                  <td>
                    <FormControl
                      type="text"
                      placeholder="Format: 86.9250° E"
                      value={this.state.longitude}
                      onChange={(e) =>
                        this.setState({ longitude: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Latitude:</td>
                  <td>
                    <FormControl
                      type="text"
                      placeholder="Format: 27.9881° N"
                      value={this.state.latitude}
                      onChange={(e) =>
                        this.setState({ latitude: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Height:</td>
                  <td>
                    <FormControl
                      type="text"
                      placeholder="Format: 8,848 m"
                      value={this.state.height}
                      onChange={(e) =>
                        this.setState({ height: e.target.value })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <span className="error-msg">
              <p>{this.state.errorMsg}</p>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <Button onClick={this.onClickBack}>Cancel</Button>
              <Button
                type="submit"
                onClick={this.handleSubmit.bind(this)}
                disabled={this.state.submitDisabled}
              >
                Start Enrollment
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(Personalize));
