
export default class payoutActions {
  static SET_PAYOUTS = 'PAYOUT/SET_PAYOUTS';

  static setPayouts(payouts) {
    return {
      type: this.SET_PAYOUTS,
      payouts: payouts,
    };
  }
}
