import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"; // Cambiado de useNavigate
import { Button, Table } from "react-bootstrap";
import OrderModal from "./components/OrderModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import AppUtils, { formatDate } from "../../util/AppUtils";
import { ethers, Signature } from "ethers";
import Skeleton from "../../components/Skeleton";
import { signTypedDataMessage } from "../../util/blockchainUtils";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";

const OrderOverview = () => {
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [orderModalData, setOrderModalData] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [orderStatus, setOrderStatus] = useState({});
  const { walletProvider } = useAppKitProvider("eip155");
  const { address } = useAppKitAccount();
  const orders = useSelector((state) => state.order.orders);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory(); // Reemplaza a useNavigate

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const orders = await ApiHelper.fetchOrders();
        getOrdersStatus(orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }

      ApiHelper.fetchOperators();
      ApiHelper.fetchPlans(user.operatorId);
    };

    fetchInitialData();
  }, [user.operatorId]);

  const openAddOrder = () => {
    setOrderModalOpen(true);
    setOrderModalData(null);
  };

  const openEditOrder = async (orderId) => {
    const order = await ApiHelper.fetchOrderById(orderId);
    setOrderModalOpen(true);
    setOrderModalData(order);
  };

  const signMessage = async (orderId, amount) => {
    if (orderStatus[orderId] === "Signed, pending") {
      alert("Already signed!");
      return;
    }

    try {
      const deploymentInfo = await ApiHelper.getDeploymentInfo(
        "BloxtelManager"
      );
      if (!deploymentInfo) throw new Error("Failed to get deployment info");

      const { address: BLOXTEL_MANAGER_ADDRESS, chainId: CHAIN_ID } =
        deploymentInfo;

      const types = {
        Mintft: [
          { name: "orderId", type: "string" },
          { name: "amount", type: "uint256" },
        ],
      };

      if (!walletProvider) {
        alert("MetaMask not detected. Please install MetaMask.");
        return;
      }

      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = provider.getSigner();
      const message = { orderId, amount };

      const domain = {
        name: "BloxtelManager",
        version: "1",
        chainId: parseInt(CHAIN_ID),
        verifyingContract: BLOXTEL_MANAGER_ADDRESS,
      };

      const signature = await signTypedDataMessage(
        domain,
        types,
        message,
        address,
        walletProvider
      );

      const parsedSignature = Signature.from(signature);

      const requestData = {
        from: address,
        orderId,
        amount,
        v: parsedSignature.v,
        r: parsedSignature.r,
        s: parsedSignature.s,
      };

      await ApiHelper.postMintFtRequest(requestData);
      fetchOrders();
    } catch (error) {
      console.error("Error signing message", error);
    }
  };

  const isOrderMinted = async (orderId) => {
    const ftRequestStatus = await ApiHelper.getFtRequestStatus(orderId);

    if (!ftRequestStatus.exists) return "Order unsigned";
    if (!ftRequestStatus.minted) return "Order signed, pending execution.";
    return `Order executed. dSIMs ${ftRequestStatus.nftMinted}`;
  };

  const getOrdersStatus = async (orders) => {
    const status = {};
    for (const order of orders) {
      status[order.id] = await isOrderMinted(order.id);
    }
    setOrderStatus(status);
  };

  const handleAddOrder = async (orderData) => {
    setOrderModalOpen(false);
    if (!(await ApiHelper.createOrder(orderData)))
      alert("Error creating new order");
    fetchOrders();
  };

  const handleUpdateOrder = async (orderData) => {
    setOrderModalOpen(false);
    if (!(await ApiHelper.updateOrder(orderData)))
      alert(`Error updating order: ${orderData.id}`);
    fetchOrders();
  };

  const fetchOrders = () => {
    ApiHelper.fetchOrders()
      .then(getOrdersStatus)
      .catch((error) => console.error("Error fetching orders:", error));
  };

  const filteredOrders = orders.filter(
    (order) =>
      order.name.includes(searchString) ||
      order.dsimType.includes(searchString) ||
      order.authMethod.includes(searchString)
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <div className="overview__searchbar">
                <h4>Orders</h4>
                <div className="overview__searchpanel">
                  <SearchBar
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div>
              </div>
              <Button className="overview__button" onClick={openAddOrder}>
                New Order
              </Button>
            </div>
            <div className="content overview__content">
              {orders.length === 0 ? (
                <Skeleton />
              ) : (
                <Table
                  className="overview__table"
                  striped
                  bordered
                  condensed
                  hover
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>dSIM Type</th>
                      <th>Authentication</th>
                      <th>Quantity</th>
                      <th>Remaining</th>
                      <th>Status</th>
                      <th>Blockchain Status</th>
                      <th>Created</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders.map((order) => (
                      <tr key={order.id}>
                        <td>{order.name}</td>
                        <td>{order.dsimType}</td>
                        <td>{order.authMethod}</td>
                        <td>{order.quantity}</td>
                        <td>{order.quantity}</td>
                        <td>
                          <span
                            style={{
                              color: AppUtils.getStatusTextColor(order.status),
                            }}
                          >
                            {order.status}
                          </span>
                        </td>
                        <td>{orderStatus[order.id] || "Fetching..."}</td>
                        <td>{formatDate(order.createdDate)}</td>
                        <td className="flex justify-content-center">
                          <Button
                            variant="info"
                            onClick={() => openEditOrder(order.id)}
                          >
                            View
                          </Button>
                          &nbsp;&nbsp;
                          {orderStatus[order.id] === "Order unsigned" && (
                            <Button
                              variant="info"
                              onClick={() =>
                                signMessage(order.id, order.quantity)
                              }
                            >
                              Sign
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
      <OrderModal
        open={orderModalOpen}
        setOpen={setOrderModalOpen}
        order={orderModalData}
        isSuper={user.role === "Admin"}
        user={user}
        onModify={handleUpdateOrder}
        onSubmit={handleAddOrder}
      />
    </div>
  );
};

export default OrderOverview;
