import React from 'react';
import {Route} from 'react-router-dom';
import OrderOverview from "./OrderOverview";

const Orders = ({match}) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={OrderOverview} />
  </div>
);

export default Orders;
