import actions from "../actions/payoutActions";

const initialState = {
  payouts: [],
  payoutsMap: {},
};

export default function reducer(state = initialState, action) {
  let nextState = { ...state };

  switch (action.type) {
    case actions.SET_PAYOUTS:
      nextState.payouts = action.payouts;
      nextState.payoutsMap = createPayoutsMap(action.payouts);
      return nextState;

    default:
      return state;
  }
}

function createPayoutsMap(payouts) {
  let payoutsMap = {};
  payouts.forEach((payouts) => (payoutsMap[payouts["id"]] = payouts));
  return payoutsMap;
}
