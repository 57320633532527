export default class blockActions {
  static SET_BLOCKS = "BLOCK/SET_BLOCKS";

  static setBlocks(blocks) {
    return {
      type: this.SET_BLOCKS,
      blocks: blocks,
    };
  }
}
