import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import OrderModal from "./components/OrderModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from '../../components/FormInputs/SearchBar.js';
import AppUtils from "../../util/AppUtils";

class OrderOverview extends Component {
  state = {
    orderModalOpen: false,
    orderModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchOrders().then();
    // TODO: fetching all is wrong
    ApiHelper.fetchOperators().then();
    ApiHelper.fetchPlans(this.props.user.operatorId).then();
  }

  openAddOrder() {
    this.setState({
      orderModalOpen: true,
      orderModalData: null,
    });
  }

  async openEditOrder(orderId) {
    const order = await ApiHelper.fetchOrderById(orderId);

    this.setState({
      orderModalOpen: true,
      orderModalData: order,
    });
  }

  async addOrder(orderData) {
    this.setState({ orderModalOpen: false });

    if (!await ApiHelper.createOrder(orderData)) {
      alert("Error creating new order");
    }
    ApiHelper.fetchOrders().then();
  }

  async updateOrder(orderData) {
    this.setState({ orderModalOpen: false });

    const result = await ApiHelper.updateOrder(orderData);

    if (!result) {
      alert("Error updating order: " + orderData.id);
    }
    ApiHelper.fetchOrders().then();
  }

  onChangeSearch(event) {
    // TODO: do real search
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Orders</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                  </div>
                </div>
                <Button bsStyle={"primary"} className="overview__button"
                  onClick={this.openAddOrder.bind(this)}>
                  New Order
                </Button>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>dSIM Type</th>
                      <th>Authentication Method</th>
                      <th>Quantity</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.orders.filter(order =>
                      order.name.includes(this.state.searchString) ||
                      order.dsimType.includes(this.state.searchString) ||
                      order.authMethod.includes(this.state.searchString)
                    ).map(order => (
                      <tr key={order.id}>
                        <td>{order.name}</td>
                        <td>{order.dsimType}</td>
                        <td>{order.authMethod}</td>
                        <td>{order.quantity}</td>
                        <td><span style={{color: AppUtils.getStatusTextColor(order.status)}}>{order.status}</span></td>
                        <td>{order.createdDate}</td>
                        <td style={{ textAlign: 'center' }}>
                        <Button variant="info" onClick={this.openEditOrder.bind(this, order.id)}>View</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <OrderModal open={this.state.orderModalOpen}
          setOpen={val => this.setState({ orderModalOpen: val })}
          order={this.state.orderModalData}
          isSuper={this.props.user.role === "Admin"}
          user={this.props.user}
          onModify={this.updateOrder.bind(this)}
          onSubmit={this.addOrder.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.order.orders,
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(OrderOverview));
