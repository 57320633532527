
export default class phoneActions {
  static SET_PHONES = 'PHONE/SET_PHONES';

  static setPhones(phones) {
    return {
      type: this.SET_PHONES,
      phones: phones,
    };
  }
}
