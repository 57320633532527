import actions from '../actions/dsimActions';

const initialState = {
  dsims: [],
  dsimsMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_DSIMS:
      nextState.dsims = action.dsims;
      nextState.dsimsMap = createdSIMsMap(action.dsims);
      return nextState;

    default:
      return state;
  }
}

function createdSIMsMap(dsims) {
  let dsimsMap = {};
  dsims.forEach(dsims => dsimsMap[dsims['ueId']] = dsims);
  return dsimsMap;
}
