export default class UEInfo {
  
    supi = "";
    status = "";

    constructor(supi, status) {
      this.supi = supi;
      this.status = status;
    }
  }
