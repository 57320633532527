import actions from '../actions/planActions';

const initialState = {
  plans: [],
  plansMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_PLANS:
      nextState.plans = action.plans;
      nextState.plansMap = createPlansMap(action.plans);
      return nextState;

    default:
      return state;
  }
}

function createPlansMap(plans) {
  let plansMap = {};
  plans.forEach(plans => plansMap[plans['id']] = plans);
  return plansMap;
}
