export let apModalSchema = {
  type: "object",
  required: [],
  properties: {
    apid: {
      type: "string",
      title: "ID",
      default: "",
      readOnly: true,
    },
    APID: {
      type: "string",
      title: "Access Point ID",
      default: "",
      readOnly: true,
    },
    latitude: {
      type: "string",
      title: "Latitude",
      default: "",
      readOnly: true,
    },
    longitude: {
      type: "string",
      title: "Longitude",
      default: "",
      readOnly: true,
    },
    height: {
      type: "string",
      title: "Height",
      default: "",
      readOnly: true,
    },
    aPType: {
      type: "string",
      title: "AP Type",
      default: "",
      readOnly: true,
    },
    gNBType: {
      type: "string",
      title: "gNB Type",
      default: "",
      readOnly: true,
    },
    band: {
      type: "integer",
      title: "Band",
      default: 0,
      readOnly: true,
    },
    mcc: {
      type: "string",
      title: "MCC",
      default: "",
      readOnly: true,
    },
    mnc: {
      type: "string",
      title: "MNC",
      default: "",
      readOnly: true,
    },
    pci: {
      type: "integer",
      title: "PCI",
      default: 0,
      readOnly: true,
    },
    amfid: {
      type: "string",
      title: "AMF ID",
      default: "",
      readOnly: true,
    },
    gnbid: {
      type: "string",
      title: "gNB ID",
      default: "",
      readOnly: true,
    },
    tac: {
      type: "integer",
      title: "TAC",
      default: 0,
      readOnly: true,
    },
  },
};

export let apModalUISchema = {
  apid: { "ui:options": { widget: "hidden" } },
  gNBType: { "ui:options": { widget: "hidden" } },
  aPType: { "ui:options": { widget: "hidden" } },
};
