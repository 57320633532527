import React from "react";
import {
  dSIMSchema,
  dSIMUISchema,
  dSIMEditSchema,
  dSIMSuperEditSchema,
  dSIMSubscriberEditSchema,
  dSIMSuperSchema,
} from "../forms";
import GenericDataView from "../../../components/GenericDataView";
import SupportedNetworkSliceWidget from "../../../components/NetworkSlice";
import QoSIdentifierWidget from "../../../components/5QLSlice";
import {
  PlanNameWidget,
  OperatorNameWidget,
  SubscriberNameWidget,
  OrderNameWidget,
} from "../../../components/IdToName";
import PLMNSelector from "../../../components/PLMNSelector";

const dSIMModal = ({ open, setOpen, data, onModify, onCreate }) => {
  return (
    <GenericDataView
      dataName="dSIM"
      open={open}
      onCreate={onCreate}
      onModify={onModify}
      data={data}
      setOpen={setOpen}
      superSchema={dSIMSuperSchema}
      operatorSchema={dSIMSchema}
      editOperatorSchema={dSIMEditSchema}
      editSubscriberSchema={dSIMSubscriberEditSchema}
      editSuperSchema={dSIMSuperEditSchema}
      uiEditSuperSchema={dSIMUISchema}
      uiEditOperatorSchema={dSIMUISchema}
      uiEditSubscriberSchema={dSIMUISchema}
      uiOperatorSchema={dSIMUISchema}
      uiSuperSchema={dSIMUISchema}
      customFormFields={{
        SupportedNetworkSliceWidget,
        QoSIdentifierWidget,
        PlanNameWidget,
        OperatorNameWidget,
        SubscriberNameWidget,
        OrderNameWidget,
        PLMNSelector,
      }}
      customFormContexts={{ data }}
    />
  );
};

export default dSIMModal;
