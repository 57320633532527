import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { cloneDeep } from "lodash/lang";

/**
 * Renders a generic data view component with form capabilities within a modal. This component dynamically adjusts its schema and behavior based on user roles and passed data. It supports both "read-only" and "editable" modes.
 *
 * @param {boolean} open Flag indicating if the modal is open.
 * @param {Function} setOpen Function to control the modal's visibility.
 * @param {Object} [data={}] Data to be displayed or edited in the modal.
 * @param {string} dataName The name of the data being displayed in the modal.
 * @param {boolean} [readOnly=false] When true, the component functions as a view-only modal.
 * @param {Function} fetchDependencies Function to fetch additional dependencies required for the component.
 * @param {Function} [handleDataOnLoad] Optional callback function executed when data is initially loaded. Signature: (data).
 * @param {Function} [handleDataOnChange] Optional callback function executed when form data is changed. Signature: (oldData, newData).
 * @param {Function} onModify Function called when modifying an existing item. Signature: (formData).
 * @param {Function} onCreate Function called when creating a new item. Signature: (formData).
 * @param {Object} operatorSchema Schema for the "OPERATOR" role.
 * @param {Object} superSchema Schema for the "ADMIN" role.
 * @param {Object} subscriberSchema Schema for the "SUBSCRIBER" role.
 * @param {Object} uiOperatorSchema UI schema for the "OPERATOR" role.
 * @param {Object} uiSuperSchema UI schema for the "ADMIN" role.
 * @param {Object} uiSubscriberSchema UI schema for the "SUBSCRIBER" role.
 * @param {Object} editOperatorSchema Schema for editing in the "OPERATOR" role.
 * @param {Object} editSuperSchema Schema for editing in the "ADMIN" role.
 * @param {Object} editSubscriberSchema Schema for editing in the "SUBSCRIBER" role.
 * @param {Object} uiEditOperatorSchema UI schema for editing in the "OPERATOR" role.
 * @param {Object} uiEditSuperSchema UI schema for editing in the "ADMIN" role.
 * @param {Object} uiEditSubscriberSchema UI schema for editing in the "SUBSCRIBER" role.
 * @param {Object} [customFormFields] Custom form fields to extend the default form functionality.
 * @param {Object} [customFormContexts] Custom form contexts to extend the default form functionality, e.g., the data can be passed in as {{ data }} again to have the full context available in custom widgets.
 *
 * @return {JSX.Element} A modal component containing a form to display, edit, or create data based on the provided configurations.
 */
const GenericDataView = ({
  open,
  setOpen,
  data = {},
  dataName,
  readOnly = false,
  fetchDependencies,
  handleDataOnLoad,
  handleDataOnChange,
  onModify,
  onCreate,
  operatorSchema,
  superSchema,
  subscriberSchema,
  uiOperatorSchema,
  uiSuperSchema,
  uiSubscriberSchema,
  editOperatorSchema,
  editSuperSchema,
  editSubscriberSchema,
  uiEditOperatorSchema,
  uiEditSuperSchema,
  uiEditSubscriberSchema,
  customFormFields,
  customFormContexts,
}) => {
  const user = useSelector((state) => state.auth.user);
  const hasMounted = useRef(false);
  const [editMode, setEditMode] = useState(false);
  const [schema, setSchema] = useState(operatorSchema);
  const [uiSchema, setUISchema] = useState(uiOperatorSchema);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (fetchDependencies) {
      fetchDependencies();
    }
  }, []);

  useEffect(() => {
    if (!open) {
      setFormData({});
    }
  }, [open]);

  useEffect(() => {
    if (hasMounted.current) {
      if (data) {
        setEditMode(true);
        if (user.role === "ADMIN") {
          setSchema(editSuperSchema);
          setUISchema(uiEditSuperSchema);
        } else if (user.role === "OPERATOR") {
          setSchema(editOperatorSchema);
          setUISchema(uiEditOperatorSchema);
        } else {
          setSchema(editSubscriberSchema);
          setUISchema(uiEditSubscriberSchema);
        }
        const _data = cloneDeep(data);
        if (handleDataOnLoad) {
          handleDataOnLoad(_data);
        }
        setFormData(_data);
      } else {
        setFormData({});
        setEditMode(false);
        if (user.role === "ADMIN") {
          setSchema(superSchema);
          setUISchema(uiSuperSchema);
        } else if (user.role === "OPERATOR") {
          setSchema(operatorSchema);
          setUISchema(uiOperatorSchema);
        } else {
          setSchema(subscriberSchema);
          setUISchema(uiSubscriberSchema);
        }
      }
    } else {
      hasMounted.current = true;
    }
  }, [data]);

  const onChange = async (data) => {
    const newData = data.formData;
    if (handleDataOnChange) {
      handleDataOnChange(formData, newData);
    }
    setFormData(newData);
  };

  const onSubmitClick = (result) => {
    if (editMode) {
      if (!readOnly) {
        onModify(result.formData);
      } else {
        setOpen(false);
      }
    } else {
      onCreate(result.formData);
    }
  };

  return (
    <Modal
      show={open}
      className={"fields__edit-modal theme-light"}
      backdrop={"static"}
      onHide={setOpen.bind(this, false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {editMode
            ? readOnly
              ? `View ${dataName}`
              : `Edit ${dataName}`
            : `New ${dataName}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          onChange={onChange.bind(this)}
          onSubmit={onSubmitClick.bind(this)}
          validator={validator}
          fields={customFormFields && customFormFields}
          formContext={customFormContexts && customFormContexts}
        />
      </Modal.Body>
    </Modal>
  );
};

export default GenericDataView;
