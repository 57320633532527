import React, { useState } from "react";
import { SidebarIcon, SidebarCloseIcon } from "lucide-react";
import { useAppKit, useAppKitAccount } from "@reown/appkit/react";
import { connect } from "react-redux";

import { toggleMobileNavVisibility } from "../../redux/reducers/layout";

import LocalStorageHelper from "../../util/LocalStorageHelper";
import AuthHelper from "../../util/AuthHelper";
import NavbarDropDownMenu from "./DropDownMenu";

export const Navbar = (props) => {
  const user = LocalStorageHelper.getUserInfo();

  return (
    <nav className="w-[95%] m-auto">
      <section className="flex justify-between items-center p-4 w-full">
        <div className="flex items-center gap-2 text-black">
          <button
            onClick={() => props.setSidebarOpened(!props.sidebarOpened)}
            className="hover:bg-gray-100  rounded-lg transition-colors"
          >
            {props.sidebarOpened ? (
              <SidebarCloseIcon className="text-gray-600" size={24} />
            ) : (
              <SidebarIcon className="text-gray-600" size={24} />
            )}
          </button>
        </div>

        <div className="relative">
          <NavbarDropDownMenu user={user} />
        </div>
      </section>
    </nav>
  );
};

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProp)(Navbar);
