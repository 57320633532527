import React from "react";
import { Route } from "react-router-dom";
import SubscriberOverview from "./SubscriberOverview";

const Subscribers = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={SubscriberOverview} />
  </div>
);

export default Subscribers;
