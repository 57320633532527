import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import SubscriberModal from './components/SubscriberModal';
import ApiHelper from "../../util/ApiHelper";
import AppUtils from '../../util/AppUtils';
import SearchBar from '../../components/FormInputs/SearchBar.js';

class SubscriberOverview extends Component {
  state = {
    subscriberModalOpen: false,
    subscriberModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchSubscribers().then();
  }

  openAddSubscriber() {
    this.setState({
      subscriberModalOpen: true,
      subscriberModalData: null,
    });
  }

  /**
   * @param userId  {string}
   */
  async openEditSubscriber(userId) {
    console.log('subscribers', this.props.subscribers);
    const subscriber = await ApiHelper.fetchSubscriberById(userId);

    this.setState({
      subscriberModalOpen: true,
      subscriberModalData: subscriber,
    });
  }

  async addSubscriber(operatorData) {
    // this.setState({ subscriberModalOpen: false });

    // let result = await ApiHelper.createOperator(operatorData);
    // if (result.status === 200) {
    //   const adminData = {
    //     email: operatorData["email"],
    //     password: operatorData["password"],
    //     role: "ADMIN",
    //   }

    //   const adminResult = await ApiHelper.createUser(result.data.operatorId, adminData);
    //   if (!adminResult) {
    //     alert('Eror in creating default admin user.');
    //   }

    // } else {
    //   alert("Error creating new operator");
    // }
    // ApiHelper.fetchOperators().then();
  }

  /**
   * @param subscriberData
   */
  async updateSubscriber(subscriberData) {
    this.setState({ subscriberModalOpen: false });

    const result = await ApiHelper.updateSubscriber(subscriberData);

    if (!result) {
      alert("Error updating subscriber: " + subscriberData["userId"]);
    }
    ApiHelper.fetchSubscribers().then();
  }

  /**
  * @param subscriber  {Subscriber}
   */
  async deleteSubscriber(subscriber) {
    if (!window.confirm(`Delete operator ${subscriber.id}?`))
      return;

    const result = await ApiHelper.deleteSubscriber(subscriber.id);
    ApiHelper.fetchSubscribers().then();
    if (!result) {
      alert("Error deleting subscriber: " + subscriber.id);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Subscribers</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                  </div>
                </div>
                <Button bsStyle={"primary"} className="overview__button"
                  onClick={this.openAddSubscriber.bind(this)}>
                  New Subscriber
                </Button>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th style={{ width: 400 }}>ID</th>
                      <th>Name</th>
                      <th>Created On</th>
                      <th colSpan={2}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.subscribers.filter(subscriber =>
                      subscriber.id.includes(this.state.searchString)
                    ).map(subscriber => (
                      <tr key={subscriber.id}>
                        <td>{subscriber.id}</td>
                        <td>{subscriber.name}</td>
                        <td>{subscriber.createdDate}</td>
                        <td><span style={{color: AppUtils.getStatusTextColor(subscriber.status)}}>{subscriber.status}</span></td>
                        <td style={{ textAlign: 'center' }}>
                          <Button variant="danger" onClick={this.deleteSubscriber.bind(this, subscriber)}>Delete</Button>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="info" onClick={this.openEditSubscriber.bind(this, subscriber.id)}>View</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <SubscriberModal open={this.state.subscriberModalOpen}
          setOpen={val => this.setState({ subscriberModalOpen: val })}
          subscriber={this.state.subscriberModalData}
          onModify={this.updateSubscriber.bind(this)}
          onSubmit={this.addSubscriber.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  subscribers: state.subscriber.subscribers,
});

export default withRouter(connect(mapStateToProps)(SubscriberOverview));
