import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  payoutModalSchema,
  payoutModalUISchema,
  payoutModalSuperSchema,
  payoutModalSuperUISchema,
  payoutModalSuperEditUISchema
} from '../../../metadata/index'
class PayoutModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isSuper: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    payout: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
  };

  schema = this.props.isSuper ?  payoutModalSuperSchema : payoutModalSchema;
  uiSchema = this.props.isSuper ?  payoutModalSuperUISchema : payoutModalUISchema;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.payout });

      if (this.props.payout) {
        const payout = this.props.payout;

        if (this.props.isSuper) {
          this.uiSchema = payoutModalSuperEditUISchema;
        }

        let formData = {
          payoutsId: payout['payoutsId'],
          operatorName: this.props.operators.filter((operator) => operator.id === payout['operatorId'])[0]?.name || "",
          operatorId: payout['operatorId'],
          payerName: payout['payerName'],
          payoutType: payout['payoutType'],
          payoutAmountCurrency: payout['payoutAmountCurrency'],
          payoutAmount: Number(payout['payoutAmount']),
          status: payout['status'],
          createdDate: payout["createdDate"],
          modifiedDate: payout["modifiedDate"],
        };

        this.updateFormData(formData).then();
      } else {
        let formData = {
          payoutId: "",
        };
        this.uiSchema = payoutModalSuperUISchema;
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    if (lastData && lastData.payoutId === undefined)
      lastData.payoutId = "";

    if(newData && lastData && lastData.operatorName !== newData.operatorName) {
      const selectedOperator = this.props.operators.filter((operator) => operator.name === newData.operatorName);
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper ? formData["operatorId"] : this.props.user.operatorId;

    let payoutData = {
      "payoutsId": formData["payoutsId"],
      "operatorId": operatorId,
      "payerName": formData["payerName"],
      "payeeName": formData["operatorName"],
      "payoutType": formData["payoutType"],
      "payoutAmountCurrency": formData["payoutAmountCurrency"],
      "payoutAmount": formData["payoutAmount"]?.toString(),
      "status": formData["status"],
      "createdDate": formData["createdDate"],
      "modifiedDate": formData["modifiedDate"],
    };

    if(this.state.editMode) {
      this.props.onModify(payoutData);
    } else {
      this.props.onSubmit(payoutData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit Payout" : "New Payout"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 &&
            <Form schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)} />
          }
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  operators: state.operator.operators,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(PayoutModal));

