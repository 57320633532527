import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  cellModalSchema,
  cellModalUISchema,
  cellModalSuperSchema,
  cellModalSuperUISchema,
  cellModalSuperEditUISchema,
} from "../../../metadata/index";
import validator from "@rjsf/validator-ajv8";

class CellModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isSuper: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    cell: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
  };

  schema = this.props.isSuper ? cellModalSuperSchema : cellModalSchema;
  uiSchema = this.props.isSuper ? cellModalSuperUISchema : cellModalUISchema;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.cell });

      if (this.props.cell) {
        const cell = this.props.cell;

        if (this.props.isSuper) {
          this.uiSchema = cellModalSuperEditUISchema;
        }

        let formData = {
          uuID: cell["uuID"],
          operatorName:
            this.props.operators.filter(
              (operator) => operator.id === cell["operatorId"],
            )[0]?.name || "",
          operatorId: cell["operatorId"],
          name: cell["name"],
          plmn: Number(cell["plmn"]),
          lac: cell["lac"],
          cellID: cell["cellID"],
          radioType: cell["radioType"],
          cellBand: cell["cellBand"],
          cellLocation: {
            latitude: Number(cell["latitude"]),
            longitude: Number(cell["longitude"]),
          },
          altitude: Number(cell["altitude"]) || undefined,
          azimuth: cell["azimuth"] || undefined,
          height: cell["height"] || undefined,
          beamwidth: cell["beamwidth"] || undefined,
          downtilt: cell["downtilt"] || undefined,
          gain: cell["gain"] || undefined,
          gatewayId: cell["gatewayId"],
          gatewayName:
            this.props.gateways.filter(
              (gateway) => gateway.id === cell["gatewayId"],
            )[0]?.name || "",
          imei: cell["imei"],
          status: cell["status"],
          createdDate: cell["createdDate"],
          modifiedDate: cell["modifiedDate"],
        };

        this.updateFormData(formData).then();
      } else {
        let formData = {
          cellId: "",
          gatewayName: "",
        };
        this.uiSchema = cellModalSuperUISchema;
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    if (lastData && lastData.cellId === undefined) lastData.cellId = "";

    if (newData && lastData && lastData.operatorName !== newData.operatorName) {
      const selectedOperator = this.props.operators.filter(
        (operator) => operator.name === newData.operatorName,
      );
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }

    if (newData && lastData && lastData.gatewayName !== newData.gatewayName) {
      const selectedGateway = this.props.gateways.filter(
        (gateway) => gateway.name === newData.gatewayName,
      );

      if (selectedGateway.length > 0) {
        newData.gatewayId = selectedGateway[0].id;
      } else {
        newData.gatewayId = "";
      }
      this.updateFormData(newData).then();
    }
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper
      ? formData["operatorId"]
      : this.props.user.operatorId;

    let cellData = {
      uuID: formData["uuID"],
      name: formData["name"],
      operatorId: operatorId,
      plmn: formData["plmn"].toString(),
      lac: formData["lac"],
      cellID: formData["cellID"],
      radioType: formData["radioType"],
      cellBand: formData["cellBand"],
      latitude: formData["cellLocation"]["latitude"].toString(),
      longitude: formData["cellLocation"]["longitude"].toString(),
      altitude: formData["altitude"]?.toString(),
      azimuth: formData["azimuth"]?.toString(),
      height: formData["height"]?.toString(),
      beamwidth: formData["beamwidth"]?.toString(),
      downtilt: formData["downtilt"]?.toString(),
      gain: formData["gain"]?.toString(),
      imei: formData["imei"],
      gatewayId: formData["gatewayId"],
      gatewayName: formData["gatewayName"],
      status: formData["status"],
      createdDate: formData["createdDate"],
      modifiedDate: formData["modifiedDate"],
    };

    if (this.state.editMode) {
      this.props.onModify(cellData);
    } else {
      this.props.onSubmit(cellData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit Cell" : "New Cell"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 && (
            <Form
              schema={this.schema}
              validator={validator}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  operators: state.operator.operators,
  gateways: state.gateway.gateways,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(CellModal));
