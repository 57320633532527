import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import Form from '@rjsf/core';
import applyNav from 'rjsf-tabs/lib/applyNav'
import validator from '@rjsf/validator-ajv8';
import {
  orderModalSchema,
  orderModalUISchema,
  orderModalSuperSchema,
  orderModalEditUISchema,
  orderModalSuperUISchema,
  orderModalSuperEditUISchema,
  orderModalEditSchema,
  orderModalSuperEditSchema,
} from '../forms'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

let FormWithNav = applyNav(Form)

class OrderModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    order: PropTypes.object,
    user: PropTypes.object,
    isSuper: PropTypes.bool.isRequired,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
  };

  schema = this.props.isSuper ?  orderModalSuperSchema : orderModalSchema;
  uiSchema = this.props.isSuper ?  orderModalSuperUISchema : orderModalUISchema;

  async loadFormData() {
    const order = this.props.order;
    let formData = {
      orderId: order['orderId'],
      name: order['name'],
      orderType: order['orderType'],
      orderQuantity: order['orderQuantity'],
      dsimType: order['dsimType'],
      authMethod: order['authMethod'],
      planName: this.props.plans.filter((plan) => plan.id === order['planId'])[0]?.name || "",
      planId: order['planId'],
      status: order['status'],
      createdDate: order["createdDate"],
      operatorId: order['operatorId'],
      tokenData: {
        tokenAddress: order["tokenData"]["tokenAddress"],
        smartContractTokenId: order["tokenData"]["smartContractTokenId"],
        tokenId: order["tokenData"]["tokenId"],
        tokenType: order["tokenData"]["tokenType"],
        ownerID: order["tokenData"]["ownerID"],
        ownerType: order["tokenData"]["ownerType"],
        balance: order["tokenData"]["balance"],
        smartContract: order["tokenData"]["smartContract"],
        artworkLink: order["tokenData"]["artworkLink"],
        qrCode: order["tokenData"]["tokenId"]
      }
    };
    if (this.props.isSuper) {
      this.schema = orderModalSuperEditSchema;
      this.uiSchema = orderModalSuperEditUISchema;
    } else {
      this.schema = orderModalEditSchema;
      this.uiSchema = orderModalEditUISchema;
    }
    this.updateFormData(formData).then();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.order });
      if (this.props.order) {
        this.loadFormData();
      } else {
        let formData = {
          orderId: "",
        };
        if (this.props.isSuper) {
          this.schema = orderModalSuperSchema
          this.uiSchema = orderModalSuperUISchema;
        } else {
          formData['operatorId'] = this.props.user.operatorId
        }
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    if(newData && lastData && lastData.operatorName !== newData.operatorName) {
      const selectedOperator = this.props.operators.filter((operator) => operator.name === newData.operatorName);
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }
    if(newData && lastData && lastData.planName !== newData.planName) {
      const selectedPlan = this.props.plans.filter((plan) => plan.name === newData.planName);
      if (selectedPlan.length > 0) {
        newData.planId = selectedPlan[0].id;
      } else {
        newData.planId = "";
      }
      this.updateFormData(newData).then();
    }
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper ? formData["operatorId"] : this.props.user.operatorId;

    let orderData = {
      "orderId": formData["orderId"],
      "name": formData["name"],
      "orderType": formData["orderType"],
      "orderQuantity": formData["orderQuantity"],
      "dsimType": formData['dsimType'],
      "authMethod": formData['authMethod'],
      "planId": formData["planId"],
      "operatorId": operatorId,
    };

    if(this.state.editMode) {
      // no update supported currently
      // this.props.onModify(orderData);
    } else {
      this.props.onSubmit(orderData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "View Order" : "New Order"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
                       validator={validator}/>
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  operators: state.operator.operators,
  plans: state.plan.plans,
});

export default withRouter(connect(mapStateToProps)(OrderModal));
