import actions from '../actions/walletActions';

const initialState = {
  wallets: [],
  walletsMap: {}
};

export default function reducer(state = initialState, action) {
  let nextState = {...state};

  switch (action.type) {
    case actions.SET_WALLETS:
      nextState.wallets = action.wallets;
      nextState.walletsMap = createWalletsMap(action.wallets);
      return nextState;

    default:
      return state;
  }
}

function createWalletsMap(wallets) {
  let walletsMap = {};
  wallets.forEach(wallets => walletsMap[wallets['id']] = wallets);
  return walletsMap;
}
