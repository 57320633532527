import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import InvoiceModal from "./components/InvoiceModal";
import ApiHelper from "../../util/ApiHelper";
import AppUtils, { formatDateOnly } from "../../util/AppUtils";
import SearchBar from "../../components/FormInputs/SearchBar.js";

class InvoiceOverview extends Component {
  state = {
    invoiceModalOpen: false,
    invoiceModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchInvoices(this.props.user.operatorId).then();
    ApiHelper.fetchOrders().then();
    ApiHelper.fetchOperators().then();
  }

  openAddInvoice() {
    this.setState({
      invoiceModalOpen: true,
      invoiceModalData: null,
    });
  }

  async openEditInvoice(invoiceId) {
    const invoice = await ApiHelper.fetchInvoiceById(
      this.props.user.operatorId,
      invoiceId,
    );

    this.setState({
      invoiceModalOpen: true,
      invoiceModalData: invoice,
    });
  }

  async addInvoice(invoiceData) {
    this.setState({ invoiceModalOpen: false });

    if (
      !(await ApiHelper.createInvoice(invoiceData["operatorId"], invoiceData))
    ) {
      alert("Error creating new invoice");
    }
    ApiHelper.fetchInvoices(this.props.user.operatorId).then();
  }

  async payInvoice() {
    this.setState({ invoiceModalOpen: false });

    ApiHelper.fetchInvoices(this.props.user.operatorId).then();
  }

  async updateInvoice(invoiceData) {
    this.setState({ invoiceModalOpen: false });

    const result = await ApiHelper.updateInvoice(
      invoiceData["operatorId"],
      invoiceData,
    );

    if (!result) {
      alert("Error updating invoice: " + invoiceData["ueId"]);
    }
    ApiHelper.fetchInvoices(this.props.user.operatorId).then();
  }

  async deleteInvoice(invoice) {
    if (!window.confirm(`Delete invoice ${invoice.id}?`)) return;

    const result = await ApiHelper.deleteInvoice(
      this.props.user.operatorId,
      invoice.id,
    );
    ApiHelper.fetchInvoices(this.props.user.operatorId).then();
    if (!result) {
      alert("Error deleting invoice: " + invoice.id);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Invoices</h4>
                  <div className="overview__searchpanel">
                    <SearchBar
                      value={this.state.searchString}
                      onChange={this.onChangeSearch.bind(this)}
                    />
                  </div>
                </div>
                {this.props.user.role === "Admin" && (
                  <Button
                    bsStyle={"primary"}
                    className="overview__button"
                    onClick={this.openAddInvoice.bind(this)}
                  >
                    New Invoice
                  </Button>
                )}
              </div>
              <div className="content overview__content">
                <Table
                  className="overview__table"
                  striped
                  bordered
                  condensed
                  hover
                >
                  <thead>
                    <tr>
                      <th style={{ width: 400 }}>ID</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th colSpan={2}>Paid On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.invoices
                      .filter(
                        (invoice) =>
                          invoice.id.includes(this.state.searchString) ||
                          invoice.amount.includes(this.state.searchString) ||
                          invoice.status.includes(this.state.searchString),
                      )
                      .map((invoice) => (
                        <tr key={invoice.id}>
                          <td>{invoice.id}</td>
                          <td>{invoice.amount}</td>
                          <td>
                            <span
                              style={{
                                color: AppUtils.getStatusTextColor(
                                  invoice.status,
                                ),
                              }}
                            >
                              {invoice.status}
                            </span>
                          </td>
                          <td>{formatDateOnly(invoice.createdDate)}</td>
                          <td>{invoice.paidDate}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button
                              variant="danger"
                              onClick={this.deleteInvoice.bind(this, invoice)}
                            >
                              Delete
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                              variant="info"
                              onClick={this.openEditInvoice.bind(
                                this,
                                invoice.id,
                              )}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <InvoiceModal
          open={this.state.invoiceModalOpen}
          setOpen={(val) => this.setState({ invoiceModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          invoice={this.state.invoiceModalData}
          onModify={this.updateInvoice.bind(this)}
          onPay={this.payInvoice.bind(this)}
          onSubmit={this.addInvoice.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: state.invoice.invoices,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(InvoiceOverview));
