// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uri-widget {
  display: block;
  margin-top: 0.5rem;
}

.uri-widget a {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 0.6px solid rgba(37, 38, 95, 0.2);
  border-radius: 2px;
  text-decoration: none; /* Optional: if you don't want the URL underlined */
}

.uri-widget a:hover {
  color: #0d6efd;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Operators/components/UriWidget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,4BAA4B;EAC5B,yCAAyC;EACzC,kBAAkB;EAClB,qBAAqB,EAAE,mDAAmD;AAC5E;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".uri-widget {\n  display: block;\n  margin-top: 0.5rem;\n}\n\n.uri-widget a {\n  display: block;\n  padding: 0.375rem 0.75rem;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.5;\n  background-color: #f5f5f5;\n  background-clip: padding-box;\n  border: 0.6px solid rgba(37, 38, 95, 0.2);\n  border-radius: 2px;\n  text-decoration: none; /* Optional: if you don't want the URL underlined */\n}\n\n.uri-widget a:hover {\n  color: #0d6efd;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
