import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../../Auth/CheckoutForm";
import ApiHelper from "../../../util/ApiHelper";
import validator from "@rjsf/validator-ajv8";
import { UriWidget } from "./UriWidget";
import "./UriWidget.css";
import {
  operatorModalSchema,
  operatorModalUISchema,
  operatorNewModalSchema,
} from "../forms";
import { toast } from "react-toastify";

// Default image to use when operator image is empty or broken
const DEFAULT_LOGO = "/assets/images/dsim_logo.png";

const widgets = {
  UriWidget: UriWidget,
};

class OperatorModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    operator: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
  };

  uiSchema = operatorModalUISchema;

  async setStripePromise() {
    const res = await ApiHelper.getStripePublicKey();
    this.setState({ stripePromise: loadStripe(res?.publicKey) });
  }

  componentDidMount() {
    this.setStripePromise();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.operator });

      if (this.props.operator) {
        const operator = this.props.operator;

        let formData = {
          operatorId: operator["operatorId"],
          businessName: operator["businessName"],
          businessAddress: operator["businessAddress"],
          businessIndustry: operator["businessIndustry"],
          status: operator["status"],
          operatorLogo: operator["operatorLogo"] || DEFAULT_LOGO,
          subscriptionType: operator["subscriptionType"],
          subscriptionStatus: operator["subscriptionStatus"],
          walletAddress: operator["walletAddress"],
        };

        this.updateData(formData, null, null).then();
      } else {
        let formData = {
          operatorId: "",
          operatorName: "",
          businessIndustry: "Accounting",
          operatorLogo: DEFAULT_LOGO,
        };
        this.updateData(formData, {}, "").then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;

    if (lastData && lastData.operatorId === undefined) lastData.operatorId = "";
  }

  async updateData(formData, blockchainData, explorerAccountUrl) {
    await this.setState({
      formData: formData,
    });
  }

  async onSubmitClick(result) {
    const formData = result.formData;

    let operatorData = {
      operatorId: formData["operatorId"],
      businessName: formData["businessName"],
      businessAddress: formData["businessAddress"],
      businessIndustry: formData["businessIndustry"],
      email: this.props.operator ? this.props.operator["email"] : "",
      password: this.props.operator ? this.props.operator["password"] : "",
      passwordConfirm: this.props.operator
        ? this.props.operator["passwordConfirm"]
        : "",
      status: formData["status"],
      operatorLogo: formData["operatorLogo"] || DEFAULT_LOGO,
      subscriptionType: formData["subscriptionType"],
      subscriptionStatus: formData["subscriptionStatus"],
    };

    try {
      if (this.state.editMode) {
        await this.props.onModify(operatorData);
        toast.success("Operator successfully updated");
      } else {
        await this.props.onSubmit(operatorData);
        toast.success("Operator successfully created");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"operator-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "View Operator" : "New Operator"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.editMode && (
            <Form
              schema={
                this.state.editMode
                  ? operatorModalSchema
                  : operatorNewModalSchema
              }
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              widgets={widgets}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
              validator={validator}
            />
          )}
          {!this.state.editMode && this.state.stripePromise && (
            <div className="Login Login__admin">
              <div className="LoginFormContent LoginFormContent__admin LoginFormContent__noBackgroundColor">
                <div className="LoginForm LoginForm__admin">
                  <Elements stripe={this.state.stripePromise}>
                    <CheckoutForm
                      isAdmin={true}
                      onClose={this.props.setOpen.bind(this, false)}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default OperatorModal;
