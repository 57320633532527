import React, { useState } from "react";
import { Menu, UserCircle2Icon } from "lucide-react";
import { useAppKit, useAppKitAccount } from "@reown/appkit/react";
import { connect } from "react-redux";

import { toggleMobileNavVisibility } from "../../redux/reducers/layout";

import { truncateAddress } from "../../util/blockchainUtils";
import LocalStorageHelper from "../../util/LocalStorageHelper";
import AuthHelper from "../../util/AuthHelper";
import { ConnectButton } from "../ui/ConnectButton";

export const Navbar = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { open, close } = useAppKit();
  const { isConnected, address } = useAppKitAccount();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSidebar = () => {
    props.setSidebarOpened(!props.sidebarOpened);
  };

  async function logout() {
    await AuthHelper.logout();
  }

  const user = LocalStorageHelper.getUserInfo();

  console.log({ user });

  return (
    <nav className="bg-white shadow-md">
      <section className="flex justify-between items-center p-4 w-11/12">
        <div className="flex items-center gap-2 text-black">
          <Menu className="cursor-pointer " onClick={toggleSidebar} />
        </div>
        <div className="relative">
          <div className="flex items-center gap-2">
            Welcome, {user.name} {user.lastName}
            <button
              onClick={toggleMenu}
              aria-label="Toggle menu"
              className="focus:outline-none"
            >
              <UserCircle2Icon className="w-8 h-8 text-black" />
            </button>
          </div>
          {isMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-xl shadow-lg z-50">
              <ul className="flex flex-col">
                {isConnected && <w3m-account-button balance="hide" />}
                {!isConnected && <ConnectButton />}

                <li className="p-2 hover:bg-gray-100">
                  <a href="#profile" className="text-black">
                    Profile
                  </a>
                </li>
                <li className="p-2 hover:bg-gray-100">
                  <a href="#settings" className="text-black">
                    Settings
                  </a>
                </li>
                <li className="p-2 hover:bg-gray-100" onClick={() => logout()}>
                  <a href="#logout" className="text-black">
                    Log out
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </section>
    </nav>
  );
};
const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
