import React from "react";
import { customFetch } from "../../util/ApiHelper";
import GenericDataList from "../../components/GenericDataList";
import AccessPointModal from "./components/AccessPointModal";

export const AccessPointOverview = () => {
  const fetch = async () => {
    return await customFetch(`/ap`, "GET");
  };

  const get = async (data) => {
    return await customFetch(`/ap/${data.apid}`);
  };

  const columns = [
    {
      header: "Access Point Serial Number",
      searchable: true,
      field: "APID",
    },
    {
      header: "Latitude",
      field: "latitude",
    },
    {
      header: "Longitude",
      field: "longitude",
    },
    {
      header: "Height",
      field: "height",
    },
    {
      header: "Band",
      field: "band",
    },
    {
      header: "MCC",
      field: "mcc",
    },
    {
      header: "MNC",
      field: "mnc",
    },
    {
      header: "Created",
      field: "createdDate",
    },
  ];

  return (
    <GenericDataList
      fetchAllData={fetch}
      getData={get}
      allowDelete={false}
      allowAdd={false}
      dataName="Access Point"
      itemKey="apId"
      columns={columns}
      DetailComponent={AccessPointModal}
    />
  );
};

export default AccessPointOverview;
