import React from "react";
import { Route } from "react-router-dom";
import PhoneOverview from "./PhoneOverview";

const Phones = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={PhoneOverview} />
  </div>
);

export default Phones;
