import { store } from "../index";
import authActions from "../redux/actions/authActions";
import User from "../models/User";
import ApiHelper from "./ApiHelper";

export default class AuthHelper {
  /**
   * @param username  {string}
   * @param password  {string}
   * @throws {Error}  error message string
   * @return {Promise<(boolean|string)>} true for success, string for error message
   */
  static async login(username, password, isSubscriber) {
    let result = "Error";

    let response = await ApiHelper.login(
      { username: username, password: password },
      isSubscriber
    );
    if (response === undefined) {
      return result;
    }

    if (response.status === 200) {
      let user = new User(
        username,
        response.data.firstName,
        response.data.lastName,
        response.data.access_token,
        response.data.userId,
        username,
        response.data.operatorId,
        response.data.role
      );
      store.dispatch(authActions.setUser(user));
      result = "Success";
    }

    if (response.status === 409) {
      result = "Permission";
    }

    return result;
  }

  /**
   * @return {Promise<boolean>} success or not
   */
  static async logout() {
    store.dispatch(authActions.logout());
    return true;
  }
}
