import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
  gatewayModalSchema,
  gatewayModalUISchema,
  gatewayModalSuperSchema,
  gatewayModalSuperUISchema,
  gatewayModalSuperEditUISchema
} from '../../../metadata/index'
import ApiHelper from "../../../util/ApiHelper";

class GatewayModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isSuper: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    gateway: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
    registerIP: "",
    verifierIP: "",
  };

  schema = gatewayModalSchema;

  schema = this.props.isSuper ?  gatewayModalSuperSchema : gatewayModalSchema;
  uiSchema = this.props.isSuper ?  gatewayModalSuperUISchema : gatewayModalUISchema;

  async loadIPAddressesFromEnv() {
    const registerIP = await ApiHelper.getRegisterIP();
    const verifierIP = await ApiHelper.getVerifierIP();

    this.setState({
      registerIP: registerIP.registerIP + ":" + registerIP.registerPort,
      verifierIP: verifierIP.verifierIP + ":" + verifierIP.verifierPort,
    });

  }

  componentDidMount() {
    this.loadIPAddressesFromEnv();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.gateway });

      if (this.props.gateway) {
        const gateway = this.props.gateway;

        if (this.props.isSuper) {
          this.uiSchema = gatewayModalSuperEditUISchema;
        }

        let formData = {
          gatewayId: gateway['gatewayId'],
          gatewayName: gateway['gatewayName'],
          operatorName: this.props.operators.filter((operator) => operator.id === gateway['operatorId'])[0]?.name || "",
          operatorId: gateway['operatorId'],
          gatewayIp: gateway['gatewayIp'],
          // gatewayLocation: {
          //   latitude: Number(gateway['latitude']),
          //   longitude: Number(gateway['longitude'])
          // },
          imei: gateway['imei'],
          allowSharing: gateway['allowSharing'],
          status: gateway["status"],
          registerIP: this.state.registerIP,
          verifierIP: this.state.verifierIP,
          createdDate: gateway["createdDate"],
          modifiedDate: gateway["modifiedDate"],
        };

        this.updateFormData(formData).then();
      } else {
        let formData = {
          gatewayId: "",
          gatewayName: "",
          registerIP: this.state.registerIP,
          verifierIP: this.state.verifierIP,
        };
        this.uiSchema = gatewayModalSuperUISchema;
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    if (lastData && lastData.gatewayId === undefined)
      lastData.gatewayId = "";

    if(newData && lastData && lastData.operatorName !== newData.operatorName) {
      const selectedOperator = this.props.operators.filter((operator) => operator.name === newData.operatorName);
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper ? formData["operatorId"] : this.props.user.operatorId;

    let gatewayData = {
      "gatewayId": formData["gatewayId"],
      "gatewayName": formData["gatewayName"],
      "operatorId": operatorId,
      "gatewayIp": formData["gatewayIp"],
      // "latitude": formData["gatewayLocation"]["latitude"].toString(),
      // "longitude": formData["gatewayLocation"]["longitude"].toString(),
      "imei": formData["imei"],
      "allowSharing": formData["allowSharing"],
      "status": formData["status"],
      "createdDate": formData["createdDate"],
      "modifiedDate": formData["modifiedDate"],
    };

    if(this.state.editMode) {
      this.props.onModify(gatewayData);
    } else {
      this.props.onSubmit(gatewayData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit Gateway" : "New Gateway"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 &&
            <Form schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)} />
          }
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  operators: state.operator.operators,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(GatewayModal));
