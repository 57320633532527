import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  FormControl,
  FormGroup,
  Image,
  Table,
  ProgressBar,
} from "react-bootstrap";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import { ToastContainer } from "react-toastify";
import Footer from "../Main/Footer";

class EnrollLoading extends Component {
  state = {
    submitDisabled: false,
    errorMsg: "",
    password: "",
    passwordConfirm: "",
    isWidthBigger: false,
    longitude: "",
    latitude: "",
    height: "",
    geolocationLoading: true,
    geolocationError: false,
    progress: 0, // New state for progress bar
    showProgressBar: false, // To conditionally render the progress bar
  };

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.getGeolocation();
    this.setState({
      submitDisabled: true,
      errorMsg: "",
      showProgressBar: true,
      progress: 0,
    });
    this.startProgressBar();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    clearInterval(this.progressInterval); // Clear the interval on unmount
  }

  updateWindowDimensions() {
    this.setState({
      isWidthBigger:
        (window.innerWidth * 0.67) / window.innerHeight > 650 / 525,
    });
  }

  async getGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
            geolocationLoading: false,
          });
        },
        () => {
          this.setState({ geolocationLoading: false, geolocationError: true });
        }
      );
    } else {
      this.setState({ geolocationLoading: false, geolocationError: true });
    }
  }

  validateForm() {
    return "";
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.validateForm()) {
      this.setState({ errorMsg: this.validateForm() });
      return;
    }

    this.setState({
      submitDisabled: true,
      errorMsg: "",
      showProgressBar: true,
      progress: 0,
    });

    // Start the progress bar
    this.startProgressBar();

    const urlData = this.props.match.url.replace(/^.*[\\\/]/, "");

    const data = {
      url: urlData,
      password: this.state.password,
      longitude: this.state.longitude,
      latitude: this.state.latitude,
      height: this.state.height,
    };

    this.props.history.push("/enroll-loading");

    setTimeout(() => {
      this.setState({
        submitDisabled: false,
        showProgressBar: false,
        progress: 100,
      });
    }, 10000);
  }

  startProgressBar() {
    const duration = 60000; // 1 minute
    const interval = 100; // Update every 100ms
    const increment = (100 / duration) * interval;

    this.progressInterval = setInterval(() => {
      this.setState((prevState) => {
        const newProgress = prevState.progress + increment;
        if (newProgress >= 100) {
          clearInterval(this.progressInterval);
          return { progress: 100, showProgressBar: false };
        }
        return { progress: newProgress };
      });
    }, interval);
    window.location.href = "/";
  }

  onClickBack = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="Login">
        <ToastContainer />
        <div className="HeroLogoContent">
          <Image
            src={HeroLogo}
            className={`${
              this.state.isWidthBigger ? "img-width" : "img-height"
            }`}
            alt="heroLogo"
          />
        </div>

        <div className="LoginFormContent">
          <div className="BackBtn" onClick={this.onClickBack}>
            <i className="fas fa-reply" />
          </div>
          <div className="LoginForm">
            <img src={Open6gcLogo} alt="Bloxtel Operator dApp" />
            <h3>Enroll Access Point</h3>

            <div style={{ textAlign: "center", padding: "10px" }}>
              <p>Your Access Point is being personalized. Please wait.</p>
            </div>

            <span className="error-msg">
              <p>{this.state.errorMsg}</p>
            </span>

            {this.state.showProgressBar && (
              <div style={{ paddingTop: "20px" }}>
                <ProgressBar
                  now={this.state.progress}
                  label={`${Math.round(this.state.progress)}%`}
                  animated
                  striped
                  variant="success"
                />
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(EnrollLoading));
