import React, { Component } from "react";
import { Modal, Table, Tabs, Tab } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import SearchBar from "../../../components/FormInputs/SearchBar.js";
import ApiHelper from "../../../util/ApiHelper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import validator from "@rjsf/validator-ajv8";
import {
  cameraModalSchema,
  cameraModalUISchema,
  cameraModalSuperSchema,
  cameraModalSuperUISchema,
  cameraModalSuperEditUISchema,
} from "../forms";
import { formatDate } from "../../../util/AppUtils.js";

class CameraModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    isSuper: PropTypes.bool.isRequired,
    camera: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    eventsData: undefined,
    actionsData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
    activeTabKey: "details",
    eventsSearchString: "",
    actionsSearchString: "",
    rtmpServerBaseURL: "",
    rtmpDefaultChannel: "",
    aimacServerUrl: "",
  };

  schema = this.props.isSuper ? cameraModalSuperSchema : cameraModalSchema;
  uiSchema = this.props.isSuper
    ? cameraModalSuperUISchema
    : cameraModalUISchema;

  cameraVideo = null;

  async loadEventsAndActionsData() {
    const eventsData = await ApiHelper.fetchCameraEvents();
    const actionsData = await ApiHelper.fetchCameraActions();
    const baseUrl = await ApiHelper.getRTMPServerURL();
    const defaultChannel = await ApiHelper.getRTMPDefaultChannel();

    this.setState({
      eventsData: eventsData,
      actionsData: actionsData,
      rtmpServerBaseURL: baseUrl.rtmpServerUrlBase,
      rtmpDefaultChannel: defaultChannel.rtmpDefaultChannel,
    });
  }

  componentDidMount() {
    this.loadEventsAndActionsData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.cameraVideo = document.getElementById("stream-video");

    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.camera });

      if (this.props.camera) {
        const camera = this.props.camera;

        if (this.props.isSuper) {
          this.uiSchema = cameraModalSuperEditUISchema;
        }
        let formData = {
          uuId: camera["uuId"],
          operatorName:
            this.props.operators.filter(
              (operator) => operator.id === camera["operatorId"]
            )[0]?.name || "",
          operatorId: camera["operatorId"],
          label: camera["label"],
          description: camera["description"],
          cameraType: camera["cameraType"],
          manufacturerName: camera["manufacturerName"],
          modelName: camera["modelName"],
          imei: camera["imei"],
          dsimId: camera["dsimId"],
          dsimAuthMethod:
            this.props.dsims.filter((dsim) => dsim.id === camera["dsimId"])[0]
              ?.authMethod || "",
          sliceId: camera["sliceId"],
          // osId: camera['osId'],
          // osAppId: camera['osAppId'],
          // osAppIdType: camera['osAppIdType'],
          // appId: camera['appId'],
          channel: camera["channel"],
          key: camera["key"],
          streamURL: camera["streamURL"],
          rule: camera["rule"] || [],
          status: camera["status"],
          createdDate: camera["createdDate"],
          modifiedDate: camera["modifiedDate"],
        };

        this.updateFormData(formData).then();
      } else {
        let formData = {
          dsimId: "",
          channel: this.state.rtmpDefaultChannel,
          key: "",
          streamURL:
            this.state.rtmpServerBaseURL +
            "/" +
            this.state.rtmpDefaultChannel +
            "/",
          rule: [],
        };

        this.uiSchema = cameraModalSuperUISchema;

        this.setState({
          activeTabKey: "details",
          formData: formData,
        });
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    if (lastData && lastData.channel !== newData.channel) {
      const streamURl =
        this.state.rtmpServerBaseURL +
        "/" +
        newData.channel +
        "/" +
        newData.key;
      // newData.streamURL = streamURl;
      await this.updateFormData(newData);
      const acInput = document.getElementById("root_channel");
      acInput.selectionStart = acInput.selectionEnd = acInput.value.length;
      acInput.focus();
    }

    if (lastData && lastData.key !== newData.key) {
      const streamURl =
        this.state.rtmpServerBaseURL +
        "/" +
        newData.channel +
        "/" +
        newData.key;
      // newData.streamURL = streamURl;
      await this.updateFormData(newData);
      const acInput = document.getElementById("root_key");
      acInput.selectionStart = acInput.selectionEnd = acInput.value.length;
      acInput.focus();
    }

    if (
      newData &&
      lastData &&
      newData.operatorName &&
      lastData.operatorName !== newData.operatorName
    ) {
      const selectedOperator = this.props.operators.filter(
        (operator) => operator.name === newData.operatorName
      );
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }

    if (newData && lastData && lastData.dsimId !== newData.dsimId) {
      const selecteddSIM = this.props.dsims.filter(
        (dsim) => dsim.id === newData.dsimId
      );

      if (selecteddSIM.length > 0) {
        newData.dsimAuthMethod = selecteddSIM[0].authMethod;
      } else {
        newData.dsimAuthMethod = "";
      }
      this.updateFormData(newData).then();
    }
  }

  async updateFormData(newData) {
    const aimacUrl = await ApiHelper.getAimacServerIP();
    let serverURL = "";
    if (this.props.camera) {
      serverURL = `http://${aimacUrl.serverIP}:${aimacUrl.serverPort}/${this.props.camera.channel}/${this.props.camera.key}`;
    }
    this.setState({
      formData: newData,
      aimacServerUrl: serverURL,
    });
  }

  onFullScreenClick() {
    if (this.cameraVideo) {
      if (this.cameraVideo.webkitSupportsFullscreen) {
        this.cameraVideo.webkitEnterFullscreen();
        return;
      }

      this.cameraVideo.requestFullscreen();
    }
  }

  onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper
      ? formData["operatorId"]
      : this.props.user.operatorId;

    let cameraData = {
      uuId: formData["uuId"],
      operatorId: operatorId,
      label: formData["label"],
      description: formData["description"],
      cameraType: formData["cameraType"],
      manufacturerName: formData["manufacturerName"],
      modelName: formData["modelName"],
      imei: formData["imei"],
      dsimId: formData["dsimId"],
      sliceId: formData["sliceId"],
      osId: formData["osId"],
      osAppId: formData["osAppId"],
      osAppIdType: formData["osAppIdType"],
      appId: formData["appId"],
      channel: formData["channel"],
      key: formData["key"],
      streamURL: formData["streamURL"],
      status: formData["status"],
      rule: formData["rule"],
      createdDate: formData["createdDate"],
      modifiedDate: formData["modifiedDate"],
    };

    if (this.state.editMode) {
      this.props.onModify(cameraData);
    } else {
      this.props.onSubmit(cameraData);
    }
  }

  onEventsChangeSearch(event) {
    this.setState({ eventsSearchString: event.target.value });
  }

  onActionsChangeSearch(event) {
    this.setState({ actionsSearchString: event.target.value });
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"camera-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit Camera" : "New Camera"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 && this.state.editMode ? (
            <Tabs
              activeKey={this.state.activeTabKey}
              onSelect={(key) => this.setState({ activeTabKey: key })}
              id="sub-modal-details-tab"
              className="camera-modal--tab"
            >
              <Tab
                eventKey="details"
                title="Details"
                className="camera-modal--tab--content"
              >
                <div className="stream-video">
                  <div
                    onClick={this.onFullScreenClick.bind(this)}
                    className="stream-video--btn"
                  >
                    <i className="fas fa-expand" />
                  </div>
                  <iframe
                    id="stream-video"
                    title="camera-view"
                    className="stream-video--video"
                    src={this.state.aimacServerUrl}
                  />
                </div>
                <Form
                  schema={this.schema}
                  uiSchema={this.uiSchema}
                  formData={this.state.formData}
                  onChange={this.onChange.bind(this)}
                  onSubmit={this.onSubmitClick.bind(this)}
                  validator={validator}
                />
              </Tab>
              <Tab
                eventKey="events"
                title="Events"
                className="dsims__modal--tab--content"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="content overview__content">
                      <div style={{ marginBottom: 20, marginTop: 20 }}>
                        <SearchBar
                          value={this.state.eventsSearchString}
                          onChange={this.onEventsChangeSearch.bind(this)}
                        />
                      </div>
                      <Table
                        className="overview__table"
                        striped
                        bordered
                        condensed
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.eventsData
                            .filter((data) =>
                              data.type.includes(this.state.eventsSearchString)
                            )
                            .map((data) => (
                              <tr key={data.type}>
                                <td>{data.type}</td>
                                <td>{formatDate(data.createdDate)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="actions"
                title="Actions"
                className="dsims__modal--tab--content"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="content overview__content">
                      <div style={{ marginBottom: 20, marginTop: 20 }}>
                        <SearchBar
                          value={this.state.actionsSearchString}
                          onChange={this.onActionsChangeSearch.bind(this)}
                        />
                      </div>
                      <Table
                        className="overview__table"
                        striped
                        bordered
                        condensed
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.actionsData
                            .filter((data) =>
                              data.type.includes(this.state.actionsSearchString)
                            )
                            .map((data) => (
                              <tr>
                                <td>{data.type}</td>
                                <td>{formatDate(data.createdDate)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          ) : (
            <Form
              schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
              validator={validator}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  operators: state.operator.operators,
  dsims: state.dsim.dsims,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(CameraModal));
