import React from "react";
import { useAppKit } from "@reown/appkit/react";

export const ConnectButton = () => {
  const { open, close } = useAppKit();

  return (
    <button
      className="bg-[#796BFB] p-4 text-white px-4 py-2 rounded-xl w-10/12"
      onClick={open}
    >
      Connect Wallet
    </button>
  );
};
