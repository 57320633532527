import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  subscriberModalSchema,
  subscriberModalUISchema,
} from "../../../metadata/index";
import { CheckoutFormSubscriber } from "../../Auth/CheckoutFormSubscriber";
import ApiHelper from "../../../util/ApiHelper";
import { ToastContainer } from "react-toastify";
import validator from "@rjsf/validator-ajv8";

class SubscriberModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    subscriber: PropTypes.object,
    editMode: PropTypes.bool.isRequired,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    formData: undefined,
    rerenderCounter: 0,
  };

  schema = subscriberModalSchema;
  uiSchema = subscriberModalUISchema;

  async setStripePromise() {
    const res = await ApiHelper.getStripePublicKey();
    this.setState({ stripePromise: loadStripe(res?.publicKey) });
  }

  componentDidMount() {
    this.setStripePromise();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.props.subscriber) {
        const subscriber = this.props.subscriber;
        let formData = {
          userId: subscriber["userId"],
          email: subscriber["email"],
          password: subscriber["password"],
          firstName: subscriber["firstName"],
          lastName: subscriber["lastName"],
          phoneNumber: subscriber["phoneNumber"],
          status: subscriber["status"],
          subscriberLogo: subscriber["subscriberLogo"],
          createdDate: subscriber["createdDate"],
          modifiedDate: subscriber["modifiedDate"],
          walletAddress: subscriber["walletAddress"],
        };
        this.updateFormData(formData).then();
      } else {
        let formData = {
          subscriberId: "",
        };
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {}

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    let subscriberData = {
      userId: formData["userId"],
      firstName: formData["firstName"],
      lastName: formData["lastName"],
      phoneNumber: formData["phoneNumber"],
      email: formData["email"],
      password: formData["password"],
      status: formData["status"],
      subscriberLogo: formData["subscriberLogo"],
      createdDate: formData["createdDate"],
      modifiedDate: formData["modifiedDate"],
    };

    if (this.props.editMode) {
      this.props.onModify(subscriberData);
    } else {
      this.props.onSubmit(subscriberData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.props.editMode ? "View Subscriber" : "New Subscriber"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 && this.props.editMode && (
            <Form
              schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
              validator={validator}
            />
          )}
          {this.state.rerenderCounter % 2 === 0 &&
            !this.props.editMode &&
            this.state.stripePromise && (
              <div className="Login Login__admin">
                <div className="LoginFormContent LoginFormContent__admin">
                  <div className="LoginForm LoginForm__admin">
                    <Elements stripe={this.state.stripePromise}>
                      <CheckoutFormSubscriber
                        isAdmin={true}
                        onClose={this.props.setOpen.bind(this, false)}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default SubscriberModal;
