
export default class requestActions {
  static REQUEST_FAILED = 'REQUEST/FAILED';
  static REQUEST_SUCCESS = 'REQUEST/SUCCESS';

  static requestFailed(error) {
    return {
      type: this.REQUEST_FAILED,
      failed: true,
      error: error
    };
  }

  static requestSuccess() {
    return {
      type: this.REQUEST_SUCCESS,
      failed: false,
      error: null
    };
  }
}
