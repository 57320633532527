import React from 'react';
import {Route} from 'react-router-dom';
import WalletOverview from "./WalletOverview";

const Wallets = ({match}) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={WalletOverview} />
  </div>
);

export default Wallets;
