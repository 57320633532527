import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { LogViewer, LogViewerSearch } from '@patternfly/react-log-viewer';
import { Toolbar, ToolbarContent, ToolbarItem, Tooltip } from '@patternfly/react-core';
import DownloadIcon from '@patternfly/react-icons/dist/esm/icons/download-icon';
import "@patternfly/react-core/dist/styles/base.css";
import GatewayModal from "./components/GatewayModal";
import ApiHelper from "../../util/ApiHelper";
import AppUtils from '../../util/AppUtils';
import SearchBar from '../../components/FormInputs/SearchBar.js';
import Directory from './routes/directory/directory';
import { CloudRegistrarMockupData, CloudVerifierMockupData } from '../../config/keylimeMockupData';

const VIEW_TYPE = {
  FILE_VIEW: "FILE_VIEW",
  LOGS_VIEW: "LOGS_VIEW",
  MAIN_VIEW: "MAIN_VIEW"
}

class GatewayOverview extends Component {
  state = {
    gatewayModalOpen: false,
    gatewayModalData: null,
    viewType: VIEW_TYPE.MAIN_VIEW,
    searchString: "",
    logContent: "",
    gatewayId: "",
    fileName: "",
  };

  async readFile(name) {
    const res = await ApiHelper.getRemoteLoggingServerIP();
    const remoteLoggingServerIP = `http://${res.serverIP}:${res.serverPort}`

    const response = await fetch(remoteLoggingServerIP+`/api/retrievelos/${name}`, {
      headers: {
        Accept: "application/json",
      },
    });

    const json = await response.json();

    this.setState({
      logContent: json.fileContent,
    })
  }

  async updateGatewaysList() {
    const res = await ApiHelper.fetchGateways(this.props.user.operatorId).then();

    const registrarList = CloudRegistrarMockupData.results.uuids;
    const verifierList = CloudVerifierMockupData.results.uuids;

    if (res) {
      this.props.gateways.forEach(async (gateway, index) => {
        let currentStatus = "UNPROVISIONED";
        const isRegistered = registrarList.filter((id) => id === gateway.id).length > 0;
        const isVerified = verifierList.filter((id) => id === gateway.id).length > 0;

        if (!isRegistered && !isVerified) {
          return;
        }

        if (isRegistered) {
          currentStatus = "PROVISIONED";
        }

        if (isVerified) {
          currentStatus = "TRUSTED";
        }
        
        const cgateway = await ApiHelper.fetchGatewayById(this.props.user.operatorId, gateway.id);
        cgateway.status = currentStatus;

        await ApiHelper.updateGateway(this.props.user.operatorId, cgateway);
        ApiHelper.fetchGateways(this.props.user.operatorId).then();
        
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line
      let gatewayId = this.props.match.url.replace(/^.*[\\\/]/, '');

      if (gatewayId.includes("log_")) {
        this.setState({
          viewType: VIEW_TYPE.LOGS_VIEW,
          fileName: gatewayId.replace("log_", "")
        })

        this.readFile(gatewayId.replace("log_", ""));
      }
    }
  }

  componentDidMount() {
    // eslint-disable-next-line
    let gatewayId = this.props.match.url.replace(/^.*[\\\/]/, '');
    if (gatewayId === "gateways") {
      this.setState({
        viewType: VIEW_TYPE.MAIN_VIEW,
      })
      
      ApiHelper.fetchOperators().then();
  
      this.updateGatewaysList();
    } else if (gatewayId === "logs") {
      this.setState({
        viewType: VIEW_TYPE.LOGS_VIEW,
      })
    } else {
      this.setState({
        gatewayId: gatewayId,
        viewType: VIEW_TYPE.FILE_VIEW,
      })
    }
  }

  openAddGateway() {
    this.setState({
      gatewayModalOpen: true,
      gatewayModalData: null,
    });
  }

  openLogsView(id) {
    // this.setState({
    //   viewType: VIEW_TYPE.FILE_VIEW,
    // })
    this.props.history.push(`/gateways/${id}`);
    // this.readFile();
  }

  /**
   * @param gatewayId  {string}
   */
  async openEditGateway(gatewayId) {
    const gateway = await ApiHelper.fetchGatewayById(this.props.user.operatorId, gatewayId);

    this.setState({
      gatewayModalOpen: true,
      gatewayModalData: gateway,
    });
  }

  async addGateway(gatewayData) {
    this.setState({ gatewayModalOpen: false });

    if (!await ApiHelper.createGateway(gatewayData['operatorId'], gatewayData)) {
      alert("Error creating new gateway");
    }

    this.updateGatewaysList();
  }

  /**
   * @param gatewayData
   */
  async updateGateway(gatewayData) {
    this.setState({ gatewayModalOpen: false });

    const result = await ApiHelper.updateGateway(gatewayData['operatorId'], gatewayData);

    if (!result) {
      alert("Error updating gateway: " + gatewayData["ueId"]);
    }
    ApiHelper.fetchGateways(this.props.user.operatorId).then();
  }

  /**
  * @param gateway  {Gateway}
   */
  async deleteGateway(gateway) {
    if (!window.confirm(`Delete Gateway ${gateway.id}?`))
      return;

    const result = await ApiHelper.deleteGateway(this.props.user.operatorId, gateway.id);
    ApiHelper.fetchGateways(this.props.user.operatorId).then();
    if (!result) {
      alert("Error deleting gateway: " + gateway.id);
    }
  }

  openFirmware() {
    this.props.history.push('/firmware/devices');
  }
  
  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  onClickBackFileSystem() {
    this.props.history.push(`/gateways/${this.state.gatewayId}`);
    this.setState({
      viewType: VIEW_TYPE.FILE_VIEW,
    })
  }

  onClickBackGatewayList() {
    this.props.history.push("/gateways");
  }

  onDownloadClick() {
    const element = document.createElement('a');
    const dataToDownload = [this.state.logContent];
    const file = new Blob(dataToDownload, { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `open6gc.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              {this.state.viewType === VIEW_TYPE.FILE_VIEW && (
                <>
                  <div className="header overview__header">
                    <div className="overview__searchbar">
                      <span className='overview_backBtn'>
                        <i onClick={this.onClickBackGatewayList.bind(this)} className="fas fa-chevron-left"/>
                      </span>
                      <h4>Access Gateway: {this.state.gatewayId}</h4>
                    </div>
                  </div>
                  <Directory path="/" />
                </>
              )}
              {this.state.viewType === VIEW_TYPE.LOGS_VIEW && (
                <div>
                  <div className="header overview__header">
                    <div className="overview__searchbar">
                      <span className='overview_backBtn'>
                        <i onClick={this.onClickBackFileSystem.bind(this)} className="fas fa-chevron-left"/>
                      </span>
                      <h4>Access Gateway: {this.state.gatewayId}</h4>
                      <div style={{opacity: 0.8}}>{this.state.fileName}</div>
                    </div>
                  </div>
                  {this.state.logContent ? (
                    <div className="dsims__logs">
                      <LogViewer
                        data={this.state.logContent}
                        theme='dark'
                        toolbar={
                          <Toolbar>
                            <ToolbarContent>
                              <ToolbarItem>
                                <LogViewerSearch placeholder="Search" />
                              </ToolbarItem>
                              <ToolbarItem align={{ default: 'alignRight'}}>
                                <Tooltip position="top" content={<div>Download</div>}>
                                  <Button onClick={this.onDownloadClick.bind(this)} variant="plain" aria-label="Download current logs">
                                    <DownloadIcon />
                                  </Button>
                                </Tooltip>
                              </ToolbarItem>
                            </ToolbarContent>
                          </Toolbar>
                        }
                      />
                    </div>
                  ) : (
                    <h4 style={{marginLeft: 25}}>No logs</h4>
                  )}
                </div>
              )}
              {this.state.viewType === VIEW_TYPE.MAIN_VIEW && (
                <>
                  <div className="header overview__header">
                    <div className="overview__searchbar">
                      <h4>Access Gateways</h4>
                      <div className="overview__searchpanel">
                        <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                        <div onClick={this.openFirmware.bind(this)} className='overview__searchpanel__extra-icon'>
                          <i className="fas fa-th-large"/>
                          <span style={{ marginLeft: 5}}>Firmware</span>
                        </div>
                      </div>
                    </div>
                    <Button bsStyle={"primary"} className="overview__button"
                      onClick={this.openAddGateway.bind(this)}>
                      New Gateway
                    </Button>
                  </div>
                  <div className="content overview__content">
                    <Table className="overview__table" striped bordered condensed hover>
                      <thead>
                        <tr>
                          <th style={{ width: 220 }}>ID</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th colSpan={2}>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.gateways.filter(gateway =>
                          gateway.id.includes(this.state.searchString) ||
                          gateway.name.includes(this.state.searchString) ||
                          gateway.status.includes(this.state.searchString)
                        ).map(gateway => (
                          <tr key={gateway.id}>
                            <td>{gateway.id}</td>
                            <td>{gateway.name}</td>
                            <td><span style={{color: AppUtils.getStatusTextColor(gateway.status)}}>{gateway.status}</span></td>
                            <td>{gateway.createdDate}</td>
                            <td style={{ textAlign: 'center' }}>
                              <Button variant="danger" onClick={this.deleteGateway.bind(this, gateway)}>Delete</Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Button variant="info" onClick={this.openEditGateway.bind(this, gateway.id)}>View</Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Button variant="info" onClick={this.openLogsView.bind(this, gateway.id)}>Logs</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <p>&nbsp;</p><p>&nbsp;</p>
                    <p>&nbsp;</p><p>&nbsp;</p>
                    <p>&nbsp;</p><p>&nbsp;</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <GatewayModal open={this.state.gatewayModalOpen}
          setOpen={val => this.setState({ gatewayModalOpen: val })}
          isSuper={this.props.user.role === "Admin"}
          gateway={this.state.gatewayModalData}
          onModify={this.updateGateway.bind(this)}
          onSubmit={this.addGateway.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gateways: state.gateway.gateways,
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(GatewayOverview));
