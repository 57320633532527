import React from "react";

export const SidebarData = [
  {
    title: "Operator",
    icon: <i className="fa fa-globe" />,

    iconClosed: <i className="fas fa-caret-down" />,
    iconOpened: <i className="fas fa-caret-up" />,

    subNav: [
      {
        title: "Operator details",
        path: "/operators",
        icon: <i className="fa fa-user-cog" />,
      },
      {
        title: "Users",
        path: "/users/",
        icon: <i className="fa fa-user" />,
      },
    ],
  },
  {
    title: "Subscribers",
    path: "/subscribers",
    icon: <i className="fas fa-user-secret" />,
  },
  {
    title: "Plans",
    path: "/plans",
    icon: <i className="fa fa-newspaper" />,
  },
  {
    title: "Orders",
    path: "/orders",
    icon: <i className="fa fa-cloud-upload-alt" />,
  },

  {
    title: "dSIMs",
    path: "/dsim",
    icon: <i className="fa fa-microchip" />,
  },
  {
    title: "Charging",
    path: "/charging",
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    title: "Network Equipment",
    icon: <i className="fa fa-project-diagram" />,
    iconClosed: <i className="fas fa-caret-down" />,
    iconOpened: <i className="fas fa-caret-up" />,

    subNav: [
      {
        title: "Access Points",
        path: "/accesspoints/list",
        icon: <i className="fa fa-server" />,
      },
      {
        title: "Access Point Status",
        path: "/accesspoints/status",
        icon: <i className="fa fa-tachometer-alt" />,
      },
      {
        title: "Access Point Logs",
        path: "/accesspoints/log",
        icon: <i className="fa fa-file-alt" />,
      },
    ],
  },
  {
    title: "User Equipment",
    icon: <i className="fas fa-users-cog" />,

    iconClosed: <i className="fas fa-caret-down" />,
    iconOpened: <i className="fas fa-caret-up" />,

    subNav: [
      // {
      //   title: 'Phones',
      //   path: '/phones',
      //   icon: <i className="fa fa-mobile"/>
      // },
      {
        title: "Cameras",
        path: "/cameras",
        icon: <i className="fa fa-camera" />,
      },
    ],
  },
  // {
  //   title: "Invoices",
  //   path: "/invoices",
  //   icon: <i className="fa fa-file-invoice-dollar" />,
  //   disabled: true,
  // },
  // {
  //   title: "Payouts",
  //   path: "/payouts",
  //   icon: <i className="fas fa-piggy-bank" />,
  //   disabled: true,
  // },
  // {
  //   title: "Analytics",
  //   path: "/analytics",
  //   icon: <i className="fa fa-chart-line" />,
  //   disabled: true,
  // },

  // {
  //   title: "Coverage Map",
  //   path: "/map",
  //   icon: <i className="fa fa-map-marker-alt" />,
  //   disabled: true,
  // },
  // {
  //   title: "Block Explorer",
  //   path: "/blocks",
  //   icon: <i className="fa fa-cubes" />,
  //   disabled: true,
  // },
];

export const SidebarDataSubscriber = [
  {
    title: "Network Status",
    path: "/",
    icon: <i className="fa fa-tachometer-alt" />,
  },
  {
    title: "Coverage Map",
    path: "/map",
    icon: <i className="fa fa-map-marker-alt" />,
  },
  {
    title: "Block Explorer",
    path: "/blocks",
    icon: <i className="fa fa-cubes" />,
  },
  {
    title: "Plans",
    path: "/plans",
    icon: <i className="fa fa-newspaper" />,
  },
  {
    title: "dSIMs",
    path: "/dsim",
    icon: <i className="fa fa-microchip" />,
  },
  {
    title: "Invoices",
    path: "/invoices",
    icon: <i className="fa fa-file-invoice-dollar" />,
  },
];
