import React from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import { MenuIcon } from "lucide-react";
import { useAppKitAccount } from "@reown/appkit/react";
import { ConnectButton } from "../../ui/ConnectButton";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/Avatar";
import { DropdownMenuSeparator } from "../../ui/DropdownMenu";
import AuthHelper from "../../../util/AuthHelper";
import { useState, useEffect } from "react";
import ApiHelper from "../../../util/ApiHelper";

const NavbarDropDownMenu = (user) => {
  const { isConnected } = useAppKitAccount();
  async function logout() {
    await AuthHelper.logout();
  }

  user = user.user;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          aria-label="Open menu"
          className="focus:outline-none p-2 bg-gray-100 rounded-xl  flex items-center gap-2"
        >
          <MenuIcon className="w-5 h-5 text-black" />
          {/* <Avatar className="bg-gray-100">
            <AvatarFallback>
              {user?.name?.charAt(0)} 
              {user?.lastName?.charAt(0)}
            </AvatarFallback>
          </Avatar> */}
          {user?.firstName}
          {"  "} {user?.lastName}
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="bg-white border border-gray-200 rounded-xl shadow-md z-50 p-2">
        {isConnected && !user.walletIsCustodial && (
          <DropdownMenuItem className="border-none hover:border-none">
            <w3m-account-button balance="hide" />
          </DropdownMenuItem>
        )}

        {!isConnected && !user.walletIsCustodial && (
          <>
            <DropdownMenuSeparator className="bg-gray-200" />

            <DropdownMenuItem className="border-none hover:border-none">
              <ConnectButton />
            </DropdownMenuItem>
          </>
        )}
        <DropdownMenuItem className="p-2 hover:bg-gray-100 rounded-md">
          Profile
        </DropdownMenuItem>

        <DropdownMenuItem
          className="p-2 hover:bg-gray-100 rounded-md"
          onClick={logout}
        >
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NavbarDropDownMenu;
