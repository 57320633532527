import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { customFetch, BASE_URL } from "../../util/ApiHelper";
import { toast } from "react-toastify";
import PricingTable from "./PricingTable/PricingTable";
import {
  businessModalSchema,
  businessModalUISchema,
  operatorUserModalSchema,
  operatorUserModalUISchema,
} from "./forms";

export const CheckoutForm = (props) => {
  const [submitDisabled, SetSubmitDisabled] = useState(false);
  const [errorMsg, SetErrorMsg] = useState("");
  const [plan, setPlan] = useState("");
  const [businessFormData, setBusinessFormData] = useState(undefined);
  const [infoFormData, setInfoFormData] = useState(undefined);
  const [email, setEmail] = useState("");
  const [availableInfoSubmit, setAvailableInfoSubmit] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(undefined);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const PageLevel = {
    BUSINESS_PAGE: 0,
    PLAN_PAGE: 1,
    INFO_PAGE: 2,
  };

  const businessSchema = businessModalSchema;
  const businessUISchema = businessModalUISchema;

  const operatorUserSchema = operatorUserModalSchema;
  const operatorUserUISchema = operatorUserModalUISchema;

  let businessFormRef, infoFormRef;

  const [page, SetPage] = useState(PageLevel.BUSINESS_PAGE);

  const [operatorLogo, setOperatorLogo] = useState("");
  const [operatorLogoFile, setOperatorLogoFile] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const validateFormBusinessPage = () => {
    if (businessFormRef) {
      businessFormRef.submit();
    }
  };

  const validatePayment = async (businessName, username) => {
    console.log("validatePayment", username);
    try {
      const resMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name: businessName,
          email: username,
        },
      });
      if (!resMethod || resMethod.error) {
        SetErrorMsg("Please input valid fields for payment");
        return false;
      }
      setPaymentMethod(resMethod);
      SetErrorMsg("");
      return true;
    } catch (err) {
      console.log(err, "error in stripe function");
    }
  };

  useEffect(() => {
    if (paymentMethod) {
      if (infoFormRef) {
        infoFormRef.submit();
        setAvailableInfoSubmit(false);
      }
    }
  }, [paymentMethod]);

  const validateFormInfoPage = () => {
    if (infoFormRef && !availableInfoSubmit) {
      infoFormRef.submit();
      return;
    }

    validatePayment(businessFormData.organizationName, email);
  };

  const onLeftBtnClick = () => {
    SetPage(page - 1);
  };

  const onRightBtnClick = () => {
    if (page === PageLevel.BUSINESS_PAGE) {
      validateFormBusinessPage();
    }

    if (page === PageLevel.PLAN_PAGE && plan) {
      SetPage(PageLevel.INFO_PAGE);
    }
  };

  const onSetPlan = (method) => {
    setPlan(method);
    SetPage(PageLevel.INFO_PAGE);
  };

  const handleChangeLogo = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setOperatorLogo(url);

      const reader = new FileReader();
      reader.onloadend = () => {
        setOperatorLogoFile(reader.result);
      };
      reader.readAsDataURL(file);
      SetErrorMsg("");
    }
  };

  const onSubmitBusinessForm = (data) => {
    const formData = data.formData;
    setBusinessFormData(formData);
    SetPage(PageLevel.PLAN_PAGE);
  };

  const onChangeInfoForm = (data) => {
    const formData = data.formData;
    setEmail(formData.userName);
    if (
      formData.firstName &&
      formData.lastName &&
      formData.password &&
      formData.passwordConfirm
    ) {
      setAvailableInfoSubmit(true);
      if (formData.password !== formData.passwordConfirm) {
        SetErrorMsg("Password not match");
      } else {
        SetErrorMsg("");
      }
    } else {
      setAvailableInfoSubmit(false);
    }
  };

  const onSubmitInfoForm = async (infoData) => {
    const formData = infoData.formData;
    setInfoFormData(formData);
    SetSubmitDisabled(true);
    SetErrorMsg("");

    const data = {
      businessName: businessFormData.organizationName,
      businessAddress: businessFormData.organizationAddress,
      businessIndustry: businessFormData.industry,
      email: formData.email,
      operatorLogo: operatorLogoFile,
      paymentMethod: paymentMethod.paymentMethod.id,
      subscriptionType: plan,
      initialUser: {
        email: formData.email,
        password: formData.password,
        passwordConfirm: formData.passwordConfirm,
        firstName: formData.firstName,
        lastName: formData.lastName,
        title: formData.title,
        phoneNumber: formData.phoneNumber,
      },
    };

    const operatorResult = await customFetch("/operator", "POST", data);
    if (operatorResult) {
      toast.success("Successfully created operator.");
      history.push("/");
    }

    SetSubmitDisabled(false);
  };

  return (
    <>
      {submitDisabled && <div className="sp loading" />}
      {!submitDisabled && (
        <>
          <div className="arrowBtn-content">
            <button
              onClick={onLeftBtnClick}
              className="arrow-btn"
              disabled={props.isAdmin || page === PageLevel.BUSINESS_PAGE}
            >
              <i className="fas fa-arrow-circle-left" />
            </button>
            <button
              onClick={onRightBtnClick}
              className="arrow-btn"
              disabled={
                page === PageLevel.INFO_PAGE ||
                (page === PageLevel.PLAN_PAGE && !plan)
              }
            >
              <i className="fas fa-arrow-circle-right" />
            </button>
          </div>
          <div>
            {errorMsg && (
              <span className="error-msg">
                <p>{errorMsg}&nbsp;</p>
              </span>
            )}

            {page === PageLevel.BUSINESS_PAGE && (
              <>
                <div className="first-page">
                  <Form
                    schema={businessSchema}
                    uiSchema={businessUISchema}
                    formData={businessFormData}
                    onSubmit={onSubmitBusinessForm}
                    validator={validator}
                    children={true}
                    ref={(form) => {
                      businessFormRef = form;
                    }}
                  />
                </div>
                <div className="zero-page">
                  <div className="logo-text">Upload Operator Logo</div>
                  {!operatorLogo && (
                    <div
                      style={{
                        width: 150,
                        height: 150,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "8px",
                        border: "1px dashed #ccc",
                      }}
                    >
                      <svg
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14" />
                        <circle cx="8.5" cy="8.5" r="1.5" />
                        <rect x="3" y="3" width="18" height="18" rx="2" />
                      </svg>
                    </div>
                  )}
                  {operatorLogo && (
                    <img
                      style={{ margin: 10 }}
                      alt="artImg"
                      width={150}
                      src={operatorLogo}
                    />
                  )}
                  <div className="select-logo">
                    <input
                      id="file"
                      style={{ margin: 10, display: "none" }}
                      type="file"
                      onChange={handleChangeLogo}
                    />
                    <div>
                      <label htmlFor="file">
                        <div
                          style={{
                            border: "1px solid black",
                            padding: "2px 5px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                        >
                          Choose
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {page === PageLevel.PLAN_PAGE && (
              <div
                className="second-page"
                style={{
                  position: "relative",
                  width: isMobile ? "100%" : "180vw",
                  marginLeft: isMobile ? "0" : "calc(-90vw + 50%)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    width: "100%",
                    transform: isMobile ? "scale(0.9)" : "none",
                    gap: isMobile ? "1rem" : "0",
                  }}
                >
                  <PricingTable onSetPlan={onSetPlan} plan={plan} />
                </div>
              </div>
            )}

            {page === PageLevel.INFO_PAGE && (
              <div
                className="third-page"
                style={{
                  width: isMobile ? "100%" : "",
                  marginLeft: isMobile ? "0" : "",
                  padding: isMobile ? "10px" : "0",
                }}
              >
                <Form
                  schema={operatorUserSchema}
                  uiSchema={operatorUserUISchema}
                  formData={infoFormData}
                  onChange={onChangeInfoForm}
                  onSubmit={onSubmitInfoForm}
                  validator={validator}
                  children={true}
                  ref={(form) => {
                    infoFormRef = form;
                  }}
                  className="wide-form"
                />
                <div className="font-bold">
                  Card Details (Powered by Stripe)
                </div>

                <CardElement className="form-control" id="card-element" />

                <button
                  className="bg-[#5846f9] p-4 text-white px-4 py-2 rounded-xl w-full mt-10"
                  onClick={validateFormInfoPage}
                >
                  Sign up
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
