import { BrowserProvider } from "ethers";

export const validateWalletAddress = (walletAddress) => {
  return walletAddress.length === 42 && walletAddress.startsWith("0x");
};

export const signMessage = async (message, walletAddress, provider) => {
  const ethersProvider = new BrowserProvider(provider);
  const signer = await ethersProvider.getSigner();
  const signature = await signer.signMessage(message);
  return signature;
};

export const signTypedDataMessage = async (
  domains,
  types,
  message,
  walletAddress,
  provider
) => {
  const ethersProvider = new BrowserProvider(provider);
  const signer = await ethersProvider.getSigner();
  const signature = await signer.signTypedData(domains, types, message);
  return signature;
};

export function truncateAddress(address) {
  if (!address) return "";
  return address.length > 2 * 6 + 2
    ? `${address.slice(0, 6)}...${address.slice(-6)}`
    : address;
}
