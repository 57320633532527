import React from 'react';

export function UriWidget(props) {
  return (
    <div className="form-group">
      <div className="uri-widget">
        <a href={props.value} id="explorer-link" target="_blank" rel="noopener noreferrer">
          {props.value}
        </a>
      </div>
    </div>
  );
}