export default class invoiceActions {
  static SET_INVOICES = "INVOICE/SET_INVOICES";

  static setInvoices(invoices) {
    return {
      type: this.SET_INVOICES,
      invoices: invoices,
    };
  }
}
