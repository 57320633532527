import React from "react";
import { Route } from "react-router-dom";
import InvoiceOverview from "./InvoiceOverview";

const Invoices = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={InvoiceOverview} />
  </div>
);

export default Invoices;
