import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Table} from "react-bootstrap";
import DSIMBatchModal from "./components/dSIMBatchModal";
import DSIMModal from "./components/dSIMModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from '../../components/FormInputs/SearchBar.js';
import {downloadModalSchema} from "./forms";
import DownloadModal from './components/DownloadModal.js';

class dSIMOverview extends Component {
    state = {
        downloadModalOpen: false,
        dsimBatchModalOpen: false,
        dsimModalOpen: false,
        tokenId: null,
        dsimBatchModalData: null,
        dsimModalData: null,
        searchString: "",
        schema: "",
        subscribers: []
    };

    componentDidMount() {
        ApiHelper.fetchdSIMs().then();
        // TODO: fetching all is wrong
        ApiHelper.fetchOrders().then();
        ApiHelper.fetchOperators().then();
    }

    openAdddSIMBatch() {
        this.setState({
            dsimBatchModalOpen: true,
            dsimBatchModalData: null,
        });
    }

    async transferdSIM(tokenId, data, blockchainAddress) {
        await ApiHelper.transferdSIM(tokenId, data, blockchainAddress);
        this.setState({
            transferModalOpen: false,
        });
    }

    /**
     * @param dsimId  {number}
     */
    async openEditdSIM(id) {
        const dsim = await ApiHelper.fetchdSIMById(id);

        this.setState({
            dsimModalOpen: true,
            dsimModalData: dsim,
        });
    }

    async openDownloadModal(tokenId) {

        const subscribers = await ApiHelper.getSubscribers()

        if (!subscribers) {
            alert("No subscribers found");
            return;
        }

        downloadModalSchema.properties.subscriber.enum = [];

        let updatedSchema = { ...downloadModalSchema };

        subscribers.forEach((subscriber, index) => {

            let subscriberString = subscriber.firstName + " " + subscriber.lastName + " (" + subscriber.userId + ")";
            updatedSchema.properties.subscriber.enum.push(subscriberString);

            if(index === 0) {
                downloadModalSchema.properties.subscriber.default = subscriberString;
            }

        });

        this.setState({
            downloadModalOpen: true,
            tokenId: tokenId,
            schema: updatedSchema,
            subscribers: subscribers,
        });
    }

    async openTransferModal(tokenId) {
        this.setState({
            transferModalOpen: true,
            tokenId: tokenId,
        });
    }

    async adddSIMBatch(dsimBatchData) {
        this.setState({dsimBatchModalOpen: false});
        if (!await ApiHelper.createdSIMBatch(dsimBatchData)) {
            alert("Error creating new dSIM batch");
        }
        ApiHelper.fetchdSIMs().then();
    }

    /**
     * @param dsimData
     */
    async updatedSIM(dsimData) {
        this.setState({dsimModalOpen: false});
        if (this.props.user.role !== "SUBSCRIBER") {
            const result = await ApiHelper.updatedSIM(dsimData);
            if (!result) {
                alert("Error updating dsim: " + dsimData.id);
            }
            ApiHelper.fetchdSIMs().then();
        }
    }

    async adddSIM(dsimData) {
        this.setState({dsimModalOpen: false});
        // TODO: support single sim creation
    }

    async updatedSIMBatch(dsimBatchData) {
        this.setState({dsimBatchModalOpen: false});
        // TODO: support update
    }

    onChangeSearch(event) {
        this.setState({searchString: event.target.value});
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header overview__header">
                                <div className="overview__searchbar">
                                    <h4>dSIMs</h4>
                                    <div className="overview__searchpanel">
                                        <SearchBar value={this.state.searchString}
                                                   onChange={this.onChangeSearch.bind(this)}/>
                                    </div>
                                </div>
                                {this.props.user.role !== "SUBSCRIBER" &&
                                    <Button bsStyle={"primary"} className="overview__button"
                                            onClick={this.openAdddSIMBatch.bind(this)}>
                                        New dSIM Batch
                                    </Button>
                                }
                            </div>
                            <div className="content overview__content">
                                <Table className="overview__table" striped bordered condensed hover>
                                    <thead>
                                    <tr>
                                        <th>ICCID</th>
                                        <th>IMSI</th>
                                        <th>Serving PLMN</th>
                                        <th>Authentication Method</th>
                                        <th>dSIM Type</th>
                                        <th>Created On</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.dsims.filter(dsim =>
                                        dsim.iccid.includes(this.state.searchString) ||
                                        dsim.imsi.includes(this.state.searchString) ||
                                        dsim.authMethod.includes(this.state.searchString) ||
                                        dsim.servingPlmnId.includes(this.state.searchString) ||
                                        dsim.dsimType.includes(this.state.searchString)
                                    ).map(dsim => (
                                        <tr key={dsim.id}>
                                            <td>{dsim.iccid}</td>
                                            <td>{dsim.imsi}</td>
                                            <td>{dsim.servingPlmnId}</td>
                                            <td>{dsim.authMethod}</td>
                                            <td>{dsim.dsimType}</td>
                                            <td>{dsim.createdDate}</td>
                                            <td>
                                                <Button variant="info"
                                                        onClick={this.openEditdSIM.bind(this, dsim.id)}>{this.props.user.role === "SUBSCRIBER" ? 'View' : 'Edit'}</Button>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {this.props.user.role !== "SUBSCRIBER" &&
                                                    <Button variant="info" onClick={this.openDownloadModal.bind(this, dsim.id, dsim.network)}>Transfer</Button>
                                                }
                                            </td>

                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>

                                <p>&nbsp;</p><p>&nbsp;</p>
                                <p>&nbsp;</p><p>&nbsp;</p>
                                <p>&nbsp;</p><p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>

                <DSIMBatchModal open={this.state.dsimBatchModalOpen}
                                setOpen={val => this.setState({dsimBatchModalOpen: val})}
                                dsimBatch={this.state.dsimBatchModalData}
                                onModify={this.updatedSIMBatch.bind(this)}
                                onSubmit={this.adddSIMBatch.bind(this)}/>

                <DSIMModal open={this.state.dsimModalOpen}
                           setOpen={val => this.setState({dsimModalOpen: val})}
                           isSuper={this.props.user.role === "Admin"}
                           isOperator={this.props.user.role === "OPERATOR"}
                           user={this.props.user}
                           dsim={this.state.dsimModalData}
                           onSubmit={this.adddSIM.bind(this)}
                           onModify={this.updatedSIM.bind(this)}/>
                
                <DownloadModal open={this.state.downloadModalOpen}
                               setOpen={val => this.setState({downloadModalOpen: val})}
                               dsimId={this.state.tokenId}
                               onSubmit={this.transferdSIM.bind(this)}
                               schema={this.state.schema}
                               subscribers={this.state.subscribers}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    dsims: state.dsim.dsims,
    user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(dSIMOverview));
