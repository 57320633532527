import React from "react";
import PlanModal from "./components/PlanModal";
import { customFetch } from "../../util/ApiHelper";
import GenericDataList from "../../components/GenericDataList";

export const PlanOverview = () => {
  const fetch = async () => {
    return await customFetch(`/plan`, "GET");
  };

  const add = async (data) => {
    const result = await customFetch(`/plan`, "POST", data);
    return !!result;
  };

  const get = async (data) => {
    return await customFetch(`/plan/${data.planId}`);
  };

  const update = async (data) => {
    const result = await customFetch(`/plan/${data.planId}`, "PUT", data);
    return !!result;
  };

  const _delete = async (data) => {
    const result = await customFetch(`/plan/${data.planId}`, "DELETE");
    return !!result;
  };

  const columns = [
    {
      header: "Plan Name",
      searchable: true,
      field: "planName",
    },
    {
      header: "Data Limit",
      field: "dataLimit",
    },
    {
      header: "Quota (MB)",
      field: "quota",
    },
    {
      header: "Price ($)",
      field: "price",
    },
    {
      header: "Created",
      field: "createdDate",
    },
  ];

  return (
    <GenericDataList
      fetchAllData={fetch}
      addData={add}
      getData={get}
      updateData={update}
      deleteData={_delete}
      dataName="Plan"
      deleteItemName="planName"
      itemKey="planId"
      columns={columns}
      DetailComponent={PlanModal}
    />
  );
};

export default PlanOverview;
