import React from "react";
import { Route } from "react-router-dom";
import dSIMOverview from "./dSIMOverview";

const dSIMs = ({ match }) => (
  <div className="content">
    <Route exact path={`${match.url}/`} component={dSIMOverview} />
  </div>
);

export default dSIMs;
