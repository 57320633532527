import {cloneDeep} from "lodash/lang";
import {tokenDataModalSchema} from "../dSIMs/forms";
import {operatorIdWidget, planIdWidget, qrWidget} from "../../components/FormWidgets";

export let orderModalSchema= {
    type: "object",
    required: [
        "orderQuantity",
        "name",
        "orderType",
        "dsimType",
        "authMethod",
        "planName",
    ],
    properties: {
        orderId: {
            type: "string",
            title: "Order ID",
            default: "",
            readOnly: true
        },
        name: {
            type: "string",
            title: "Name",
            default: "",
        },
        orderType: {
            type: "string",
            title: "Order Type",
            default: "dSIM Purchase",
            enum: ["dSIM Purchase"],
            readOnly: true,
        },
        dsimType: {
            type: "string",
            title: "dSIM Type",
            default: "ELECTRONIC",
            enum: ["PHYSICAL", "ELECTRONIC"],
        },
        authMethod: {
            type: "string",
            title: "Authentication Mode",
            default: "6G_AKA",
            enum: ["6G_AKA", "5G_AKA"],
        },
        orderQuantity: {
            type: "integer",
            title: "Order Quantity",
            default: 1,
            maximum: 10000,
            minimum: 1,
        },
        unitPrice: {
            type: "integer",
            title: "Unit Price ($)",
            default: 0,
            minimum: 0,
            maximum: 100,
            readOnly: true,
        },
        planName : {
            type: "string",
            title: "Plan Name",
            default: "",
        },
        planId: {
            type: "string",
            title: "Plan ID",
            default: "",
            readOnly: true,
        },
        status: {
            type: "string",
            title: "Order Status",
            default: "PENDING",
            enum: ["PENDING", "FULFILLED", "CANCELED"],
            readOnly: true,
        }
    },
};

export let orderModalEditSchema = cloneDeep(orderModalSchema);
orderModalEditSchema.properties.orderType.readOnly = true
orderModalEditSchema.properties.dsimType.readOnly = true
orderModalEditSchema.properties.authMethod.readOnly = true
orderModalEditSchema.properties.orderQuantity.readOnly = true
orderModalEditSchema.properties.planName.readOnly = true
orderModalEditSchema.properties.name.readOnly = true
orderModalEditSchema.properties.tokenData = tokenDataModalSchema

export let orderModalSuperSchema = cloneDeep(orderModalSchema);
orderModalSuperSchema.required.push("operatorName");
orderModalSuperSchema.properties.operatorName = {
    type: "string",
    title: "Operator Name",
    default: "",
}
orderModalSuperSchema.properties.operatorId = {
    type: "string",
    title: "Operator ID",
    default: "",
    readOnly: true,
}


export let orderModalSuperEditSchema = cloneDeep(orderModalEditSchema);
// to have right order remove it
delete orderModalSuperEditSchema.properties.tokenData
orderModalSuperEditSchema.properties.operatorId = {
    type: "string",
    title: "Operator ID",
    readOnly: true
}
orderModalSuperEditSchema.properties.operatorName = {
    type: "string",
    title: "Operator Name",
    readOnly: true
}
orderModalSuperEditSchema.properties.tokenData = tokenDataModalSchema

export let orderModalUISchema = {
    orderId: {"ui:options": { 'widget': 'hidden'} },
    status: {"ui:options": { 'widget': 'hidden'} },
};
orderModalUISchema.planName = planIdWidget(false);

export let orderModalEditUISchema = {
    status: {"ui:options": { 'widget': 'hidden'} },
    orderType: {"ui:widget": 'hidden', "nav": "Order"},
    dsimType: {"nav": "Order"},
    authMethod: {"nav": "Order"},
    orderQuantity: {"nav": "Order"},
    name: {"nav": "Order"},
    planId: {"nav": "Order"}
}
orderModalEditUISchema.planName = planIdWidget(true);
orderModalEditUISchema.planName.nav = "Order"
orderModalEditUISchema.tokenData = {
    qrCode: qrWidget(),
    "nav": "Token"
}

export let orderModalSuperEditUISchema = cloneDeep(orderModalEditUISchema);
orderModalSuperEditUISchema.operatorName = operatorIdWidget(true);

export let orderModalSuperUISchema = {
    orderId: {"ui:options": { 'widget': 'hidden'} },
    status: {"ui:options": { 'widget': 'hidden'} },
    orderType: {"ui:options": { 'widget': 'hidden'} },
};
orderModalSuperUISchema.planName = planIdWidget(false);
orderModalSuperUISchema.operatorName = operatorIdWidget(false);
