import { cloneDeep } from "lodash/lang";
import { qrWidget } from "../../components/FormWidgets";
import DSIMLogo from "../../assets/images/dsim_logo.png";
import PLMNSelector from "../../components/PLMNSelector";

export const dSIMBatchSchema = {
  type: "object",
  required: [
    "orderId",
    "batchQuantity",
    "servingPlmnId",
    "fiveQI",
    "planId",
    "networkSlice",
  ],
  properties: {
    orderId: {
      type: "string",
      title: "Order ID",
      default: "",
    },
    planId: {
      type: "string",
      title: "Plan ID",
      default: "",
    },
    batchQuantity: {
      type: "integer",
      default: 1,
      minimum: 1,
      title: "Batch Quantity",
    },
    servingPlmnId: {
      type: "string",
      default: "",
      pattern: "^[0-9]*$",
      minLength: 5,
      maxLength: 6,
      title: "Serving PLMN ID",
    },
    fiveQI: {
      type: "integer",
      default: 9,
      title: "5QI",
    },
    networkSlice: {
      type: "object",
      title: "Network Slice",
      required: ["sst", "sd"],
      properties: {
        sst: {
          type: "integer",
          title: "SST",
          minimum: 0,
          maximum: 255,
        },
        sd: {
          type: "string",
          title: "SD",
          pattern: "^[A-Fa-f0-9]{6}$",
        },
      },
    },
  },
};

export const dsimBatchEditSchema = cloneDeep(dSIMBatchSchema);
dsimBatchEditSchema.properties.batchQuantity.readOnly = true;
dsimBatchEditSchema.properties.orderId.readOnly = true;

export const dSIMBatchUISchema = {
  orderId: {
    "ui:field": "OrderNameWidget",
  },
  fiveQI: {
    "ui:field": "QoSIdentifierWidget",
  },
  servingPlmnId: {
    "ui:field": "PLMNSelector",
  },
  networkSlice: {
    "ui:field": "SupportedNetworkSliceWidget",
  },
  planId: {
    "ui:field": "PlanNameWidget",
  },
};

export const dSIMSchema = {
  type: "object",
  required: [
    "iccid",
    "imsi",
    "servingPlmnId",
    "dsimType",
    "authMethod",
    "orderId",
    "planId",
    "fiveQI",
    "networkSlice",
  ],
  properties: {
    dsimId: {
      type: "string",
      title: "dSIM ID",
      pattern: "^[0-9a-zA-Z-]*$",
      default: "",
      readOnly: true,
    },
    planId: {
      type: "string",
      title: "Plan ID",
      default: "",
    },
    orderId: {
      type: "string",
      title: "Order ID",
      default: "",
    },
    iccid: {
      type: "string",
      title: "ICCID",
      pattern: "^[0-9]*$",
      minLength: 19,
      maxLength: 20,
      default: "",
    },
    imsi: {
      type: "string",
      default: "",
      pattern: "^[0-9]*$",
      length: 15,
      title: "IMSI",
    },
    servingPlmnId: {
      type: "string",
      default: "",
      pattern: "^[0-9]*$",
      minLength: 5,
      maxLength: 6,
      title: "Serving PLMN ID",
    },
    dsimType: {
      type: "string",
      title: "dSIM Type",
      default: "ELECTRONIC",
      enum: ["PHYSICAL", "ELECTRONIC"],
    },
    authMethod: {
      type: "string",
      title: "Authentication Mode",
      default: "6G_AKA",
      enum: ["6G_AKA", "5G_AKA"],
    },
    activationCode: {
      type: "string",
      title: "Activation Code",
      default: "",
      readOnly: true,
    },
    fiveQI: {
      type: "integer",
      default: 9,
      title: "5QI",
    },
    networkSlice: {
      type: "object",
      title: "Network Slice",
      required: ["sst", "sd"],
      properties: {
        sst: {
          type: "integer",
          title: "SST",
          minimum: 0,
          maximum: 255,
        },
        sd: {
          type: "string",
          title: "SD",
          pattern: "^[A-Fa-f0-9]{6}$",
        },
      },
    },
  },
};

export const dSIMSuperSchema = cloneDeep(dSIMSchema);
dSIMSuperSchema.required.push("operatorId");
dSIMSuperSchema.properties.operatorId = {
  type: "string",
  title: "Operator ID",
  default: "",
};

export const dSIMEditSchema = cloneDeep(dSIMSchema);
dSIMEditSchema.properties.iccid.readOnly = true;
dSIMEditSchema.properties.dsimType.readOnly = true;
dSIMEditSchema.properties.servingPlmnId.readOnly = true;
dSIMEditSchema.properties.authMethod.readOnly = true;
dSIMEditSchema.properties.imsi.readOnly = true;
dSIMEditSchema.properties.orderId.readOnly = true;
dSIMEditSchema.properties.subscriberId = {
  type: "string",
  title: "Subscriber ID",
  default: "",
  readOnly: true,
};

export const dSIMSubscriberEditSchema = cloneDeep(dSIMEditSchema);
delete dSIMSubscriberEditSchema.definitions;
delete dSIMSubscriberEditSchema.properties.orderId;
delete dSIMSubscriberEditSchema.properties.planId;
delete dSIMSubscriberEditSchema.properties.networkSlice;
delete dSIMSubscriberEditSchema.properties.fiveQI;
delete dSIMSubscriberEditSchema.properties.servingPlmnId;
delete dSIMSubscriberEditSchema.properties.subscriberId;

export const dSIMSuperEditSchema = cloneDeep(dSIMSuperSchema);
dSIMSuperSchema.properties.operatorId.readOnly = true;

export const dSIMUISchema = {
  activationCode: qrWidget(DSIMLogo),
  dsimId: {
    "ui:options": { widget: "hidden" },
  },
  operatorId: {
    "ui:field": "OperatorNameWidget",
  },
  planId: {
    "ui:field": "PlanNameWidget",
  },
  orderId: {
    "ui:field": "OrderNameWidget",
  },
  servingPlmnId: {
    "ui:field": "PLMNSelector",
  },
  fiveQI: {
    "ui:field": "QoSIdentifierWidget",
  },
  networkSlice: {
    "ui:field": "SupportedNetworkSliceWidget",
  },
  subscriberId: {
    "ui:field": "SubscriberNameWidget",
  },
};

export let transferModalSchema = {
  type: "object",
  required: ["sourceChain", "destChain"],
  properties: {
    sourceChain: {
      type: "string",
      title: "Source Blockchain",
      default: "Fabric",
      enum: ["Fabric", "Ethereum"],
      readOnly: true,
    },
    destChain: {
      type: "string",
      title: "Destination Blockchain",
      default: "Fabric",
      enum: ["Fabric", "Ethereum"],
    },
  },
};

export let downloadModalSchema = {
  type: "object",
  required: ["subscriber"],
  properties: {
    subscriber: {
      type: "string",
      title: "Subscriber name",
      enum: [],
      default: undefined,
    },
  },
};

export let transferModalUISchema = {};
