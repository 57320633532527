import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import Skeleton from "./ui/Skeleton";
import SearchBar from "./FormInputs/SearchBar.js";
import NotFound from "./NotFound";
import { formatDateOnly } from "../util/AppUtils";
import { DeleteModal } from "../components/DeleteModal";

/**
 * GenericDataList is a configurable component for managing and displaying a list of data items.
 * It provides functionality for CRUD (Create, Read, Update, Delete) operations, filtering,
 * and customizable UI components such as a DetailComponent for viewing and editing data.
 *
 * @param {Function} fetchAllData - The function to fetch and return all data. Returns false if an error occurs.
 * @param {Function} addData - The optional function to add a new data item. Returns false if an error occurs.
 * @param {Function} getData - The function to fetch details for a specific data item. Returns false if an error occurs.
 * @param {Function} updateData - The optional function to update a data item. Returns false if an error occurs.
 * @param {Function} deleteData - The optional function to delete a data item. Returns false if an error occurs.
 * @param {boolean} noDetails Flag to indicate that no detail view is provided.
 * @param {boolean} noActions Flag to indicate that no actions are shown.
 * @param {boolean} [allowAdd=true] - A flag to enable or disable the ability to add new data items.
 * @param {boolean} [allowDelete=true] - A flag to enable or disable the ability to delete data items.
 * @param {boolean} [noOpenDetailOnAdd=false] - A flag to prevent opening detail modal when adding new items.
 * @param {string} [notFoundText] - Optional text displayed when no data is found.
 * @param {string} [notFoundButtonText] - Optional text for the button to add new data when no data is found.
 * @param {string} [notFoundDescription] - Optional description text for when no data is found.
 * @param {string} [addButtonText] - Optional text for the button to add new items.
 * @param {string} [dataName="Item"] - The name of the data being listed, used dynamically in UI text.
 * @param {(string|Function)} [deleteItemName] - The display name for delete confirmation dialogs, either a string property name or a function that returns a value based on an item.
 * @param {(string|Function)} itemKey - The key or function used to uniquely identify each data item.
 * @param {Array.<{header: string, field?: string, render?: Function, searchable?: boolean}>} columns - An array defining the headers and properties to display in the table.
 * @param {Function} [filterFunction] - An optional custom filter function to filter data based on a search string.
 * @param {React.Component} DetailComponent - A React component for viewing/editing data.
 * @param {Object} [detailProps] - Additional properties passed to the DetailComponent.
 * @param {Function} [customActions] - An optional function to render additional custom actions/buttons for each row.
 * @param {boolean} [refetch] custom flag to trigger the refetch of the list.
 */
const GenericDataList = ({
  fetchAllData,
  addData,
  getData,
  updateData,
  deleteData,
  allowAdd = true,
  allowDelete = true,
  noDetails = false,
  noActions = false,
  noOpenDetailOnAdd = false,
  notFoundText,
  notFoundButtonText,
  notFoundDescription,
  addButtonText,
  dataName = "Item",
  deleteItemName,
  itemKey,
  columns,
  filterFunction,
  DetailComponent,
  detailProps,
  customActions,
  refetch,
  customGlobalActions,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [internalRefetch, setInternalRefetch] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Default filter: search only within columns that are marked as searchable
  const defaultFilterFunction = (item, search) => {
    return columns.some((col) => {
      if (col.searchable && col.field && item[col.field]) {
        return item[col.field]
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      }
      return false;
    });
  };

  // Use the provided filter function if available, otherwise use defaultFilterFunction
  const effectiveFilterFunction = filterFunction || defaultFilterFunction;

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const result = await fetchAllData();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [internalRefetch, refetch]);

  const openAdd = () => {
    if (noOpenDetailOnAdd) {
      // Only call addData without opening the modal if noOpenDetailOnAdd is true
      addData();
    } else {
      setModalData(null);
      setModalOpen(true);
    }
  };

  const openEdit = async (item) => {
    const dataDetails = await getData(item);
    if (dataDetails) {
      setModalData(dataDetails);
      setModalOpen(true);
    }
  };

  const handleAddData = async (itemData) => {
    const success = await addData(itemData);
    if (success) {
      setModalOpen(false);
      setInternalRefetch((prev) => !prev);
    }
  };

  const handleUpdateData = async (itemData) => {
    const success = await updateData(itemData);
    if (success) {
      setModalOpen(false);
      setInternalRefetch((prev) => !prev);
    }
  };

  const handleDeleteData = async (item) => {
    setItemToDelete(item);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!itemToDelete) return;

    const success = await deleteData(itemToDelete);
    if (success) {
      setInternalRefetch((prev) => !prev);
    }
    setDeleteModalOpen(false);
    setItemToDelete(null);
  };

  const onChangeSearch = (event) => {
    setSearchString(event.target.value);
  };

  const filteredData = data
    ? data.filter((item) =>
        searchString ? effectiveFilterFunction(item, searchString) : true,
      )
    : [];

  const getItemDisplayName = (item) => {
    if (typeof deleteItemName === "function") {
      return deleteItemName(item);
    }
    return item[deleteItemName] || "this item";
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <div className="overview__searchbar">
                <h4>{dataName} List</h4>
                <div className="overview__searchpanel">
                  <SearchBar value={searchString} onChange={onChangeSearch} />
                </div>
              </div>
              {allowAdd && (
                <Button
                  variant="primary"
                  className="overview__button"
                  onClick={openAdd}
                >
                  {addButtonText ?? `Add ${dataName}`}
                </Button>
              )}
              {customGlobalActions && customGlobalActions()}
            </div>
            <div className="content overview__content">
              {loading ? (
                <Skeleton />
              ) : filteredData.length === 0 ? (
                allowAdd && (
                  <NotFound
                    text={notFoundText ?? `No ${dataName} found`}
                    buttonText={
                      notFoundButtonText ?? `Create a new ${dataName}`
                    }
                    description={
                      notFoundDescription ?? `You can create a new ${dataName}`
                    }
                    onCreateBatch={openAdd}
                  />
                )
              ) : (
                <Table
                  className="overview__table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      {columns.map((col, index) => (
                        <th key={index}>{col.header}</th>
                      ))}
                      {!noActions && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      filteredData.map((item) => {
                        const key =
                          typeof itemKey === "function"
                            ? itemKey(item)
                            : item[itemKey];
                        return (
                          <tr key={key}>
                            {columns.map((col, index) => (
                              <td key={index}>
                                {col.render
                                  ? col.render(item)
                                  : col.field
                                    ? col.field === "createdDate"
                                      ? formatDateOnly(item[col.field])
                                      : item[col.field]
                                    : null}
                              </td>
                            ))}
                            {!noActions && (
                              <td
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  padding: "10px",
                                  border: "none",
                                }}
                              >
                                {allowDelete && (
                                  <Button
                                    variant="danger"
                                    onClick={() => handleDeleteData(item)}
                                  >
                                    Delete
                                  </Button>
                                )}
                                {!noDetails && (
                                  <Button
                                    variant="info"
                                    onClick={() => openEdit(item)}
                                  >
                                    View
                                  </Button>
                                )}
                                {/* Render custom actions if provided */}
                                {customActions && customActions(item)}
                              </td>
                            )}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
      {DetailComponent && (
        <DetailComponent
          open={modalOpen}
          setOpen={setModalOpen}
          data={modalData}
          onModify={handleUpdateData}
          onCreate={handleAddData}
          {...detailProps}
        />
      )}
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setItemToDelete(null);
        }}
        onConfirm={confirmDelete}
        title={`Delete ${dataName}`}
        description={`This action cannot be undone.`}
        itemName={itemToDelete ? getItemDisplayName(itemToDelete) : ""}
        isAllDelete={false}
      />
    </div>
  );
};

export default GenericDataList;
