export const CloudRegistrarMockupData = {
    "results": {
      "uuids": [
        "5e600bce-a5cb-4f5a-bf08-46d0b45081c5",
        "6dab10e4-6619-4ff9-9062-ee6ad23ec24d",
        "d432fbb3-d2f1-4a97-9ef7-75bd81c00000",
        "9ac03694-a1cc-4d49-b64d-6db93a7e7d56"
      ]
    }
};

export const CloudVerifierMockupData = {
    "results": {
      "uuids": [
        "5e600bce-a5cb-4f5a-bf08-46d0b45081c5",
        "6dab10e4-6619-4ff9-9062-ee6ad23ec24d",
        "d432fbb3-d2f1-4a97-9ef7-75bd81c00000",
        "002493a8-b70d-49a9-95e3-1b172956bf9e"
      ]
    }
};
