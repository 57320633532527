import React, { useEffect, useState } from "react";
import { customFetch } from "../util/ApiHelper";

/**
 * This function generates a custom widget for rendering a name selector associated with an ID field.
 * The widget fetches items asynchronously and updates form data dynamically.
 *
 * @param {boolean} disabled - Indicates whether the widget should be disabled or not.
 * @param {string} label - The label.
 * @param {string} idName - The key name representing the unique identifier in the data to render.
 * @param {string} dataIdName - The key name representing the unique identifier attribute of each fetched item.
 * @param {string} dataNameName - The key name representing the name attribute of each fetched item.
 * @param {Function} getItems - A function to asynchronously fetch the list of items.
 *
 * @return {Object} An object containing the custom UI widget definition for name selection.
 */
const NameIdWidget = (
  props,
  label,
  idName,
  dataIdName = idName,
  dataNameName,
  getItems,
) => {
  const { formData, onChange, disabled, formContext, required, readonly } =
    props;
  const { data } = formContext;
  const [items, setItems] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(props.value || "");

  const fetchItems = async () => {
    const items = await getItems();
    if (items) {
      setItems(items);
      if (data) {
        const item = items.find((item) => item[dataIdName] === data[idName]);
        if (item) {
          setSelectedItemName(item[dataNameName]);
        }
      }
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const onChangeName = (event) => {
    const name = event.target.value;
    setSelectedItemName(name);
    if (name) {
      const item = items.find((item) => item[dataNameName] === name);
      if (item) {
        setSelectedItemId(item[dataIdName]);
        onChange(item[dataIdName]);
      }
    } else {
      setSelectedItemId("");
      onChange("");
    }
  };

  return (
    <div className="id-name-widget">
      <label className="control-label">
        {label}
        {required && <span className="required">*</span>}
      </label>
      <select
        disabled={readonly}
        onChange={onChangeName}
        value={selectedItemName}
        id={"root_" + dataNameName}
        className="form-control"
        required={required}
        readOnly={readonly}
      >
        <option key="empty" value=""></option>
        {items.map((item) => (
          <option key={item[dataIdName]} value={item[dataNameName]}>
            {item[dataNameName]}
          </option>
        ))}
      </select>
      <input
        type="hidden"
        id={"root_" + idName}
        name={"root_" + idName}
        value={selectedItemId}
      />
    </div>
  );
};

export function PlanNameWidget(props) {
  return NameIdWidget(props, "Plan", "planId", "planId", "planName", () =>
    customFetch("/plan"),
  );
}

export function OperatorNameWidget(disabled) {
  return NameIdWidget(
    disabled,
    "Operator",
    "operatorId",
    "operatorId",
    "businessName",
    () => customFetch("/operator"),
  );
}

export function OrderNameWidget(disabled) {
  return NameIdWidget(disabled, "Order", "orderId", "orderId", "name", () =>
    customFetch("/order"),
  );
}

export function SubscriberNameWidget(disabled) {
  return NameIdWidget(
    disabled,
    "Subscriber",
    "subscriberId",
    "userId",
    "email",
    () => customFetch("/subscriber"),
  );
}
