import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import DSIMBatchModal from "./components/dSIMBatchModal";
import DSIMModal from "./components/dSIMModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import { downloadModalSchema } from "./forms";
import DownloadModal from "./components/DownloadModal.js";
import { BrowserProvider, ethers, Signature } from "ethers";
import { formatDate } from "../../util/AppUtils";
import Skeleton from "../../components/Skeleton";
import { signTypedDataMessage } from "../../util/blockchainUtils";
import {
  useAppKit,
  useAppKitAccount,
  useAppKitProvider,
} from "@reown/appkit/react";
import { toast, ToastContainer } from "react-toastify";

const DSIMOverview = () => {
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [dsimBatchModalOpen, setDSIMBatchModalOpen] = useState(false);
  const [dsimModalOpen, setDSIMModalOpen] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [dsimBatchModalData, setDSIMBatchModalData] = useState(null);
  const [dsimModalData, setDSIMModalData] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [schema, setSchema] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [dsimStatus, setDSIMStatus] = useState({});
  const [imsi, setIMSI] = useState("");
  const { walletProvider } = useAppKitProvider("eip155");
  const { address } = useAppKitAccount();

  const dsims = useSelector((state) => state.dsim.dsims);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    ApiHelper.fetchdSIMs().then((dsims) => {
      getDsimsStatus(dsims);
    });
    ApiHelper.fetchOrders();
    ApiHelper.fetchOperators();
  }, []);

  const getDsimsStatus = async (dsims) => {
    const statuses = {};
    for (const dsim of dsims) {
      const status = await isDsimMinted(dsim.id);
      statuses[dsim.id] = status;
    }
    setDSIMStatus(statuses);
  };

  const isDsimMinted = async (dsimId) => {
    const randomStatus = Math.floor(Math.random() * 3);
    return (
      ["Unsigned", "Signed, pending", "Minted"][randomStatus] || "Unsigned"
    );
  };

  const signMessage = async (orderId, tokenId) => {
    if (["Signed, pending", "Minted"].includes(dsimStatus[orderId])) {
      alert("Already signed!");
      return;
    }
    const deploymentInfo = await ApiHelper.getDeploymentInfo("BloxtelManager");
    if (!deploymentInfo) {
      console.error("Failed to get deployment info");
      return;
    }

    const { address: BLOXTEL_MANAGER_ADDRESS, chainId: CHAIN_ID } =
      deploymentInfo;

    if (!window.ethereum) {
      alert("MetaMask not detected. Please install MetaMask.");
      return;
    }

    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      const message = {
        orderId,
        tokenId,
        description: `Authorize the minting of 1 dSIM NFT token with ID ${tokenId} and order ID ${orderId}`,
      };

      const domain = {
        name: "BloxtelManager",
        version: "1",
        chainId: 84532,
        verifyingContract: BLOXTEL_MANAGER_ADDRESS,
      };

      const types = {
        Mintnft: [
          { name: "orderId", type: "string" },
          { name: "tokenId", type: "uint256" },
          { name: "description", type: "string" },
        ],
      };

      const signature = await signTypedDataMessage(
        domain,
        types,
        message,
        await signer.getAddress(),
        walletProvider
      );

      const parsedSignature = Signature.from(signature);

      const requestData = {
        from: await signer.getAddress(),
        orderId,
        tokenId,
        v: parsedSignature.v,
        r: parsedSignature.r,
        s: parsedSignature.s,
      };
      const response = await ApiHelper.postMintNftRequest(requestData);
      console.log("requestData", requestData);
      console.log("response", response);
      toast.success("dSIM signed successfully");
    } catch (error) {
      console.error("Error signing message", error);
    }
  };

  const handleSearchChange = (event) => setSearchString(event.target.value);

  const navigateToDetail = (path) => {
    history.push(path); // Navigate using history
  };

  return (
    <div className="container-fluid w-screen">
      <div className="row">
        <ToastContainer />
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <div className="overview__searchbar">
                <h4>dSIMs</h4>
                <div className="overview__searchpanel">
                  <SearchBar
                    value={searchString}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              {user.role !== "SUBSCRIBER" && (
                <Button
                  bsStyle="primary"
                  className="overview__button"
                  onClick={() => setDSIMBatchModalOpen(true)}
                >
                  New dSIM Batch
                </Button>
              )}
            </div>
            <div className="content overview__content">
              {dsims.length === 0 ? (
                <Skeleton />
              ) : (
                <Table
                  className="overview__table"
                  striped
                  bordered
                  condensed
                  hover
                >
                  <thead className="overview__table-header">
                    <tr>
                      <th>Order ID</th>
                      <th>ICCID</th>
                      <th>IMSI</th>
                      <th>Serving PLMN</th>
                      <th>Authentication Method</th>
                      <th>dSIM Type</th>
                      <th>Created</th>
                      <th>Blockchain Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dsims
                      .filter((dsim) =>
                        [
                          dsim.iccid,
                          dsim.imsi,
                          dsim.authMethod,
                          dsim.servingPlmnId,
                          dsim.dsimType,
                        ].some((field) => field.includes(searchString))
                      )
                      .map((dsim) => (
                        <tr key={dsim.id}>
                          <td>{dsim.orderId.substring(0, 8)}</td>
                          <td>{dsim.iccid}</td>
                          <td>{dsim.imsi}</td>
                          <td>{dsim.servingPlmnId}</td>
                          <td>{dsim.authMethod}</td>
                          <td>{dsim.dsimType}</td>
                          <td>{formatDate(dsim.createdDate)}</td>
                          <td>{dsimStatus[dsim.id] || "Fetching..."}</td>
                          <td className="gap-3 flex">
                            <Button
                              variant="info"
                              onClick={() => setDSIMModalOpen(true)}
                            >
                              {user.role === "SUBSCRIBER" ? "View" : "Edit"}
                            </Button>
                            {user.role !== "SUBSCRIBER" && (
                              <Button
                                variant="info"
                                onClick={() => setDownloadModalOpen(true)}
                              >
                                Transfer
                              </Button>
                            )}
                            {dsimStatus[dsim.id] === "Unsigned" && (
                              <Button
                                variant="info"
                                onClick={() =>
                                  signMessage(dsim.orderId, dsim.imsi)
                                }
                              >
                                Sign
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
      <DSIMBatchModal
        open={dsimBatchModalOpen}
        setOpen={setDSIMBatchModalOpen}
        dsimBatch={dsimBatchModalData}
      />
      <DSIMModal
        open={dsimModalOpen}
        setOpen={setDSIMModalOpen}
        isSuper={user.role === "Admin"}
        isOperator={user.role === "OPERATOR"}
        user={user}
        dsim={dsimModalData}
      />
      <DownloadModal
        open={downloadModalOpen}
        setOpen={setDownloadModalOpen}
        dsimId={tokenId}
        imsi={imsi}
        schema={schema}
        subscribers={subscribers}
      />
    </div>
  );
};

export default DSIMOverview;
