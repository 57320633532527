import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import PhoneModal from "./components/PhoneModal";
import ApiHelper from "../../util/ApiHelper";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import { formatDateOnly } from "../../util/AppUtils";
import { toast } from "react-toastify";

const usePhoneManagement = (operatorId) => {
  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const [phoneModalData, setPhoneModalData] = useState(null);

  const openAddPhone = useCallback(() => {
    setPhoneModalOpen(true);
    setPhoneModalData(null);
  }, []);

  const openEditPhone = useCallback(
    async (uuid) => {
      try {
        const phone = await ApiHelper.fetchPhoneById(operatorId, uuid);
        setPhoneModalOpen(true);
        setPhoneModalData(phone);
      } catch (error) {
        console.error("Error fetching phone:", error);
        toast.error("Error fetching phone details");
      }
    },
    [operatorId],
  );

  const addPhone = useCallback(
    async (phoneData) => {
      try {
        setPhoneModalOpen(false);
        const success = await ApiHelper.createPhone(
          phoneData.operatorId,
          phoneData,
        );
        if (!success) {
          throw new Error("Failed to create phone");
        }
        await ApiHelper.fetchPhones(operatorId);
      } catch (error) {
        console.error("Error creating phone:", error);
        toast.error("Error creating new phone");
      }
    },
    [operatorId],
  );

  const updatePhone = useCallback(
    async (phoneData) => {
      try {
        setPhoneModalOpen(false);
        const success = await ApiHelper.updatePhone(
          phoneData.operatorId,
          phoneData,
        );
        if (!success) {
          throw new Error("Failed to update phone");
        }
        await ApiHelper.fetchPhones(operatorId);
      } catch (error) {
        console.error("Error updating phone:", error);
        // alert(`Error updating phone: ${phoneData.uuid}`);
        toast.error(`Error updating phone: ${phoneData.uuid}`);
      }
    },
    [operatorId],
  );

  const deletePhone = useCallback(
    async (phone) => {
      if (!window.confirm(`Delete Phone ${phone.uuid}?`)) return;

      try {
        const success = await ApiHelper.deletePhone(operatorId, phone.uuid);
        if (!success) {
          throw new Error("Failed to delete phone");
        }
        await ApiHelper.fetchPhones(operatorId);
      } catch (error) {
        console.error("Error deleting phone:", error);
        toast.error(`Error deleting phone: ${phone.uuid}`);
      }
    },
    [operatorId],
  );

  return {
    phoneModalOpen,
    setPhoneModalOpen,
    phoneModalData,
    openAddPhone,
    openEditPhone,
    addPhone,
    updatePhone,
    deletePhone,
  };
};

const PhoneOverview = () => {
  const [searchString, setSearchString] = useState("");
  const user = useSelector((state) => state.auth.user);
  const phones = useSelector((state) => state.phone.phones);

  const {
    phoneModalOpen,
    setPhoneModalOpen,
    phoneModalData,
    openAddPhone,
    openEditPhone,
    addPhone,
    updatePhone,
    deletePhone,
  } = usePhoneManagement(user.operatorId);

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([
        ApiHelper.fetchPhones(user.operatorId),
        ApiHelper.fetchOperators(),
        ApiHelper.fetchdSIMs(),
      ]);
    };
    fetchInitialData();
  }, [user.operatorId]);

  const filteredPhones = phones.filter(
    (phone) =>
      phone.imei.includes(searchString) ||
      phone.manufacturerName.includes(searchString) ||
      phone.modelName.includes(searchString),
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <div className="overview__searchbar">
                <h4>Phones</h4>
                <div className="overview__searchpanel">
                  <SearchBar
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div>
              </div>
              <Button
                bsStyle="primary"
                className="overview__button"
                onClick={openAddPhone}
              >
                New Phone
              </Button>
            </div>
            <div className="content overview__content">
              <Table
                className="overview__table"
                striped
                bordered
                condensed
                hover
              >
                <thead>
                  <tr>
                    <th>IMEI</th>
                    <th>Manufacturer/Model</th>
                    <th colSpan={2}>Created</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPhones.map((phone) => (
                    <tr key={phone.imei}>
                      <td>{phone.imei}</td>
                      <td>
                        {phone.manufacturerName}/{phone.modelName}
                      </td>
                      <td>{formatDateOnly(phone.createdDate)}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="danger"
                          onClick={() => deletePhone(phone)}
                        >
                          Delete
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          variant="info"
                          onClick={() => openEditPhone(phone.uuid)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <PhoneModal
        open={phoneModalOpen}
        setOpen={setPhoneModalOpen}
        isSuper={user.role === "Admin"}
        phone={phoneModalData}
        onModify={updatePhone}
        onSubmit={addPhone}
      />
    </div>
  );
};

export default PhoneOverview;
