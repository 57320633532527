import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import ApiHelper from "../../util/ApiHelper";
import Bloxteldapplogo from "../../assets/images/bloxtel_icon.png";
import { AppSidebar } from "./Sidebar.jsx";
import { SidebarData, SidebarDataSubscriber } from "./SidebarData";

export function MainSidebar({ setSidebarOpened, sidebarOpened }) {
  const [plan, setPlan] = useState("");
  const [status, setStatus] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);

  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchOperatorData = async () => {
      try {
        const operator = await ApiHelper.fetchOperatorById(user.operatorId);
        setPlan(operator.subscriptionType);
        setStatus(operator.subscriptionStatus);
      } catch (error) {
        console.error("Failed to fetch operator data", error);
      }
    };
    if (user.operatorId) {
      fetchOperatorData();
    }
  }, [user.operatorId]);

  const filterSidebarItems = () => {
    if (user.role === "SUBSCRIBER") {
      return SidebarDataSubscriber;
    }
    return SidebarData.filter((item) => {
      if (user.role === "OPERATOR" && item.title === "Operators") return false;
      if (user.role === "Admin" && item.title === "Users") return false;
      if (
        user.role === "OPERATOR" &&
        plan === "Basic" &&
        item.title === "User Equipment"
      )
        return false;
      if (
        (user.role === "OPERATOR" &&
          plan !== "Advanced" &&
          item.title === "Payouts") ||
        (item.title === "Payouts" &&
          plan === "Advanced" &&
          status === "PENDING")
      ) {
        return false;
      }
      return true;
    });
  };

  const sidebarItems = filterSidebarItems();

  const handleToggleDropdown = (index) => {
    setOpenDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="z-100 relative">
      <AppSidebar
        sidebarOpened={sidebarOpened}
        setSidebarOpened={setSidebarOpened}
        className="z-100 relative"
      >
        <div className="flex h-screen flex-col text-lg">
          <div className="flex items-center gap-4 px-4 py-3 border-b border-gray-200">
            {!sidebarOpened && (
              <i
                onClick={() => setSidebarOpened(true)}
                className="fas fa-bars text-gray-600 hover:text-gray-800 cursor-pointer text-xl"
              />
            )}
            <img src={Bloxteldapplogo} alt="logo" className="w-8 h-8" />
            {sidebarOpened && (
              <span className="font-semibold text-gray-800">
                {user.role === "SUBSCRIBER"
                  ? "Subscriber dApp"
                  : "Operator dApp"}
              </span>
            )}
          </div>

          <nav className="flex-1 overflow-hidden [scrollbar-width:none] [-ms-overflow-style:none] [&::-webkit-scrollbar]:hidden [&::-webkit-scrollbar]:w-0 [&::-webkit-scrollbar]:h-0 [&::-webkit-scrollbar]:bg-transparent">
            <div className="flex flex-col space-y-1 p-2 h-full mt-10 gap-3">
              {sidebarItems.map((item, index) => {
                const hasChildren = item.subNav && item.subNav.length > 0;

                if (hasChildren) {
                  return (
                    <div key={item.title}>
                      <button
                        type="button"
                        className={`
                          w-full group flex items-center px-4 py-2
                          text-xl font-medium rounded-lg gap-4
                          transition-all duration-200 relative
                          no-underline
                          ${
                            item.disabled
                              ? "text-red-500 cursor-not-allowed hover:bg-transparent no-underline"
                              : "text-gray-800 no-underline hover:no-underline hover:text-white hover:bg-violet-700"
                          }
                        `}
                        onClick={() =>
                          !item.disabled && handleToggleDropdown(index)
                        }
                        disabled={item.disabled}
                      >
                        <div className="flex-shrink-0">
                          {React.cloneElement(item.icon, {
                            className: `${
                              item.disabled
                                ? "text-red-500"
                                : "text-gray-500 group-hover:text-gray-900"
                            } h-5 w-5`,
                          })}
                        </div>

                        <span>{item.icon}</span>

                        <span className="flex-1 text-left">{item.title}</span>

                        {sidebarOpened && !item.disabled && (
                          <i
                            className={`fas fa-caret-${
                              openDropdown === index ? "up" : "down"
                            } text-gray-400 group-hover:text-gray-500`}
                          />
                        )}
                        {item.disabled && (
                          <div className="absolute inset-0 rounded-lg bg-gray-50/50">
                            <span className="absolute right-4 text-xs text-red-500">
                              Disabled
                            </span>
                          </div>
                        )}
                      </button>

                      {openDropdown === index && sidebarOpened && (
                        <div className="flex flex-col ml-4 border-l">
                          {item.subNav.map((subItem) => {
                            const isSubActive =
                              location.pathname === subItem.path;
                            return (
                              <Link
                                key={subItem.title}
                                to={subItem.path}
                                className={`
                                  group gap-4 flex items-center py-2 pl-4
                                  rounded-lg transition-all duration-200
                                  no-underline
                                  ${
                                    isSubActive
                                      ? "bg-violet-700 text-white no-underline"
                                      : "text-gray-800 no-underline hover:no-underline hover:text-white hover:bg-violet-700"
                                  }
                                `}
                              >
                                <div
                                  className="flex-shrink-0 mr-2"
                                  style={{
                                    color: isSubActive ? "#fff" : undefined,
                                  }}
                                >
                                  {React.cloneElement(subItem.icon, {
                                    className: `h-4 w-4 ${
                                      isSubActive
                                        ? "text-white"
                                        : "text-gray-500 group-hover:text-gray-900"
                                    }`,
                                  })}
                                </div>
                                <span
                                  style={{
                                    color: isSubActive ? "#fff" : undefined,
                                  }}
                                >
                                  {subItem.icon}
                                </span>

                                <span
                                  style={{
                                    color: isSubActive ? "#fff" : undefined,
                                  }}
                                >
                                  {subItem.title}
                                </span>
                              </Link>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                }

                return (
                  <Link
                    key={item.title}
                    to={item.path}
                    onClick={(e) => item.disabled && e.preventDefault()}
                    style={{
                      color: item.path === location.pathname ? "white" : "",
                      textDecoration:
                        item.path === location.pathname ? "none" : "",
                    }}
                    className={`
                      group flex items-center px-4 py-2 text-xl rounded-lg gap-4
                      transition-all duration-200 relative no-underline
                      ${
                        item.disabled
                          ? "text-red-500 cursor-not-allowed hover:bg-transparent"
                          : "text-gray-800 no-underline hover:no-underline hover:bg-violet-700 hover:text-white"
                      }
                      ${
                        item.path === location.pathname
                          ? "bg-violet-700 text-white hover:bg-violet-700 hover:text-white"
                          : ""
                      }
                    `}
                  >
                    <div className="flex-shrink-0">
                      {React.cloneElement(item.icon, {
                        className: `${
                          item.disabled
                            ? "text-red-500"
                            : "text-gray-500 group-hover:text-gray-900"
                        } h-5 w-5`,
                      })}
                    </div>

                    <span>{item.icon}</span>

                    <span className="flex-1 text-left">{item.title}</span>

                    {item.disabled && (
                      <span className="absolute right-4 text-xs text-red-500">
                        (Disabled)
                      </span>
                    )}
                  </Link>
                );
              })}
            </div>
          </nav>
        </div>
      </AppSidebar>
    </div>
  );
}
