import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import OperatorModal from "./components/OperatorModal";
import ApiHelper from "../../util/ApiHelper";
import AppUtils from '../../util/AppUtils';
import SearchBar from '../../components/FormInputs/SearchBar.js';

class OperatorOverview extends Component {
  state = {
    operatorModalOpen: false,
    operatorModalData: null,
    searchString: "",
  };

  componentDidMount() {
    // eslint-disable-next-line
    let operatorId = this.props.match.url.replace(/^.*[\\\/]/, '');
    ApiHelper.fetchOperators().then();

    if (operatorId !== "operators") {
      this.openEditOperator(operatorId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line
      let operatorId = this.props.match.url.replace(/^.*[\\\/]/, '');

      if (operatorId !== "operators") {
        this.openEditOperator(operatorId);
      }
    }
  }

  openAddOperator() {
    this.setState({
      operatorModalOpen: true,
      operatorModalData: null,
    });
  }

  /**
   * @param operatorId  {string}
   */
  async openEditOperator(operatorId) {
    const operator = await ApiHelper.fetchOperatorById(operatorId);

    this.setState({
      operatorModalOpen: true,
      operatorModalData: operator,
    });
  }

  async addOperator(operatorData) {
    this.setState({ operatorModalOpen: false });

    let result = await ApiHelper.createOperator(operatorData);
    if (result.status === 200) {
      const adminData = {
        email: operatorData["email"],
        password: operatorData["password"],
        role: "ADMIN",
      }

      const adminResult = await ApiHelper.createUser(result.data.operatorId, adminData);
      if (!adminResult) {
        alert('Eror in creating default admin user.');
      }

    } else {
      alert("Error creating new operator");
    }
    ApiHelper.fetchOperators().then();
  }

  /**
   * @param operatorData
   */
  async updateOperator(operatorData) {
    this.setState({ operatorModalOpen: false });

    const result = await ApiHelper.updateOperator(operatorData);

    if (!result) {
      alert("Error updating operator: " + operatorData["ueId"]);
    }
    ApiHelper.fetchOperators().then();
    this.props.history.push(`/operators`);

    // if (operatorData.subscriptionStatus === "APPROVED") {
    //   const res = await ApiHelper.fetchNotificationById(operatorData["operatorId"]);
    //   const notiData = res.filter((noti) => noti.type === "Subscription" && noti.status === "PENDING")
    //
    //   if (notiData.length > 0) {
    //     const data = notiData[0];
    //     data["status"] = "Solved";
    //     await ApiHelper.updateNotification(data);
    //   }
    //   console.error("notiData", notiData);
    // }
  }

  /**
  * @param operator  {Operator}
   */
  async deleteOperator(operator) {
    if (!window.confirm(`Delete operator ${operator.id}?`))
      return;

    const result = await ApiHelper.deleteOperator(operator.id);
    ApiHelper.fetchOperators().then();
    if (!result) {
      alert("Error deleting operator: " + operator.id);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Operators</h4>
                  <div className="overview__searchpanel">
                    <SearchBar value={this.state.searchString} onChange={this.onChangeSearch.bind(this)} />
                  </div>
                </div>
                <Button bsStyle={"primary"} className="overview__button"
                  onClick={this.openAddOperator.bind(this)}>
                  New Operator
                </Button>
              </div>
              <div className="content overview__content">
                <Table className="overview__table" striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th style={{ width: 220 }}>ID</th>
                      <th>Name</th>
                      <th>Subscription Type</th>
                      <th>Subscription Status</th>
                      <th>Created On</th>
                      <th colSpan={2}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.operators.filter(operator =>
                      operator.id.includes(this.state.searchString) ||
                      operator.name.includes(this.state.searchString)
                    ).map(operator => (
                      <tr key={operator.id}>
                        <td>{operator.id}</td>
                        <td><font color="blue"><u><Link to={"/users/"+operator.id}>{operator.name}</Link></u></font></td>
                        <td>{operator.subscriptionType}</td>
                        <td><span style={{color: AppUtils.getStatusTextColor(operator.subscriptionStatus)}}>{operator.subscriptionStatus}</span></td>
                        <td>{operator.createdDate}</td>
                        <td><span style={{color: AppUtils.getStatusTextColor(operator.status)}}>{operator.status}</span></td>
                        <td style={{ textAlign: 'center' }}>
                          <Button variant="danger" onClick={this.deleteOperator.bind(this, operator)}>Delete</Button>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="info" onClick={this.openEditOperator.bind(this, operator.id)}>View</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
                <p>&nbsp;</p><p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <OperatorModal open={this.state.operatorModalOpen}
          setOpen={val => this.setState({ operatorModalOpen: val })}
          operator={this.state.operatorModalData}
          onModify={this.updateOperator.bind(this)}
          onSubmit={this.addOperator.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  operators: state.operator.operators,
});

export default withRouter(connect(mapStateToProps)(OperatorOverview));
