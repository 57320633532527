import Http from "./Http";
import { store } from "../index";
import dsimActions from "../redux/actions/dsimActions";
import dSIM from "../models/dSIM";
import operatorActions from "../redux/actions/operatorActions";
import Operator from "../models/Operator";
import subscriberActions from "../redux/actions/subscriberActions";
import Subscriber from "../models/Subscriber";
import gatewayActions from "../redux/actions/gatewayActions";
import Gateway from "../models/Gateway";
import cellActions from "../redux/actions/cellActions";
import Cell from "../models/Cell";
import orderActions from "../redux/actions/orderActions";
import Order from "../models/Order";
import phoneActions from "../redux/actions/phoneActions";
import Phone from "../models/Phone";
import cameraActions from "../redux/actions/cameraActions";
import Camera from "../models/Camera";
import planActions from "../redux/actions/planActions";
import Plan from "../models/Plan";
import payoutActions from "../redux/actions/payoutActions";
import Payout from "../models/Payout";
import invoiceActions from "../redux/actions/invoiceActions";
import Invoice from "../models/Invoice";
import blockActions from "../redux/actions/blockActions";
import Block from "../models/Block";
import userActions from "../redux/actions/userActions";
import User from "../models/User";
import axios from "axios";
import LocalStorageHelper from "./LocalStorageHelper";
import { toast } from "react-toastify";
import config from "../config/config";
import AuthHelper from "../util/AuthHelper";
import customHistory from "./history";

class ApiHelper {
  static async fetchChargingRecord() {
    try {
      let response = await Http.get(`charging-record`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(
        "ApiHelper :: fetchChargingRecord() ERROR:: error = " + error,
      );
      console.error(error);
    }
    return false;
  }

  static async fetchChargingData(chargingMethod) {
    try {
      let response = await Http.get(`charging-data/${chargingMethod}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("ApiHelper :: fetchChargingData() ERROR:: error = " + error);
      console.error(error);
    }
    return false;
  }
  //<editor-fold defaultstate="collapsed" desc="sims">

  static async fetchdSIMs() {
    try {
      store.dispatch(dsimActions.setdSIMs([])); // Clear previous data
      const response = await customFetch(`/dsim`, "GET");
      let dsims = [];
      if (response) {
        dsims = response.map(
          (val) =>
            new dSIM(
              val["dsimId"],
              val["servingPlmnId"],
              val["iccid"],
              val["imsi"],
              val["dsimType"],
              val["authMethod"],
              val["createdDate"],
              val["modifiedDate"],
              val["orderId"],
            ),
        );
      }
      store.dispatch(dsimActions.setdSIMs(dsims)); // Dispatch to the store
      return dsims; // Return the dSIMs array
    } catch (error) {
      console.error(error); // Log any errors
    }

    return []; // Return an empty array in case of an error
  }

  static async transferdSIM(dsimId, transferData, blockchainAddress) {
    try {
      let response = await Http.post(`dsim/transfer/${dsimId}`, transferData);
      console.log("transferdSIM response.status = " + response.status);

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log("ApiHelper :: createdSIM() ERROR:: error = " + error);

      console.error(error);
    }

    return false;
  }

  //</editor-fold>

  static async login(loginRequest, isSubscriber) {
    try {
      let response = await Http.post(
        `${isSubscriber ? "loginsubscriber" : "login"}`,
        loginRequest,
      );
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async forgotPassword(request) {
    console.log("ApiHelper :: ForgotPassword()");
    try {
      let response = await Http.post(`forgotpassword`, request);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async resetPassword(request) {
    console.log("ApiHelper :: ResetPassword()");
    try {
      let response = await Http.post(`resetpassword`, request);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async fetchNotificaitons() {
    // try {
    //   let response = await Http.get('notification');
    //   if (response.status === 200 && response.data) {
    //     return response.data;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

    return false;
  }

  static async fetchNotificationById(id) {
    // try {
    //   let response = await Http.get(`notification/${id}`);
    //   if (response.status === 200 && response.data) {
    //     return response.data;
    //   }
    // } catch (error) {
    // }

    return false;
  }

  static async createNotification(notificationData) {
    try {
      let response = await Http.post("notification", notificationData);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async updateNotification(notificationData) {
    try {
      let response = await Http.put(
        `notification/${notificationData["notificationId"]}`,
        notificationData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteNotification(id) {
    try {
      let response = await Http.delete(`notification/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchOperators() {
    try {
      let ops = [];
      let response = await customFetch(`/operator`, "GET");
      if (response && Array.isArray(response)) {
        ops = response.map(
          (val) =>
            new Operator(
              val["operatorId"],
              val["businessName"],
              val["operatorLogo"],
              val["subscriptionType"],
              val["subscriptionStatus"],
              val["status"],
              val["createdDate"],
            ),
        );
      }
      store.dispatch(operatorActions.setOperators(ops));
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  static async fetchOperatorById(id) {
    return await customFetch(`/operator/${id}`);
  }

  static async updateOperator(operatorData) {
    try {
      let response = await Http.put(
        `operator/${operatorData["operatorId"]}`,
        operatorData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteOperator(id) {
    try {
      let response = await Http.delete(`operator/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchSubscribers() {
    try {
      store.dispatch(subscriberActions.setSubscribers([]));
      let response = await Http.get("subscriber");
      if (response.status === 200 && response.data) {
        const subscribers = response.data.map(
          (val) =>
            new Subscriber(
              val["userId"],
              val["firstName"] + " " + val["lastName"],
              val["subscriberLogo"],
              val["status"],
              val["createdDate"],
            ),
        );
        store.dispatch(subscriberActions.setSubscribers(subscribers));
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async createSubscriber(subscriberData) {
    try {
      subscriberData["passwordConfirm"] = subscriberData["password"];
      let response = await customFetch("/subscriber", "POST", subscriberData);

      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async updateSubscriber(subscriberData) {
    try {
      subscriberData["passwordConfirm"] = subscriberData["password"];
      let response = await Http.put(
        `subscriber/${subscriberData["userId"]}`,
        subscriberData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteSubscriber(id) {
    try {
      let response = await Http.delete(`subscriber/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchOperatorUsers() {
    try {
      let users = [];
      const response = await customFetch(`/operatorUser`, "GET");
      if (response && Array.isArray(response)) {
        users = response.map(
          (val) =>
            new User(
              val["firstName"],
              val["lastName"],
              val["userId"],
              val["email"],
              val["operatorId"],
              val["role"],
              val["status"],
            ),
        );
      }
      store.dispatch(userActions.setUsers(users));
      return true;
    } catch (error) {}

    return false;
  }

  //**********************************************************************
  //*********************** ACCESS GATEWAYS ******************************
  //**********************************************************************
  static async fetchGateways(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchGateways ()");
      store.dispatch(gatewayActions.setGateways([]));
      let response = await Http.get(`operator/${operatorId}/gateway`);
      if (response.status === 200 && response.data) {
        const gateways = response.data.map(
          (val) =>
            new Gateway(
              val["gatewayId"],
              val["gatewayName"],
              val["gatewayIp"],
              val["latitude"],
              val["longitude"],
              val["status"],
              val["createdDate"],
            ),
        );
        store.dispatch(gatewayActions.setGateways(gateways));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async getPricePerGB() {
    try {
      let response = await Http.get(`pricePerGB`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getCameraLogging(baseUrl) {
    try {
      const response = await fetch(`${baseUrl}/logging`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const result = await response.json();

      if (response.status === 200) return result;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getCameraHeatmapData(baseUrl) {
    try {
      const response = await fetch(`${baseUrl}/heatmap`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const result = await response.json();

      if (response.status === 200) return result;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** Small Cells **********************************
  //**********************************************************************
  static async fetchCells(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchCells ()");
      store.dispatch(cellActions.setCells([]));
      let response = await Http.get(`operator/${operatorId}/cell`);
      if (response.status === 200 && response.data) {
        const cells = response.data.map(
          (val) =>
            new Cell(
              val["uuID"],
              val["plmn"],
              val["lac"],
              val["cellID"],
              val["radioType"],
              val["cellBand"],
              val["latitude"],
              val["longitude"],
              val["status"],
              val["createdDate"],
            ),
        );
        store.dispatch(cellActions.setCells(cells));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchCellsForMap(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchCellsForMap ()");
      store.dispatch(cellActions.setCells([]));
      let response = await Http.get(`operator/${operatorId}/mapCell`);

      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Getting error on fetchCellsForMap()", error);
    }

    return false;
  }

  static async fetchCellById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/cell/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createCell(operatorId, cellData) {
    try {
      let response = await Http.post(`operator/${operatorId}/cell`, cellData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateCell(operatorId, cellData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/cell/${cellData["uuID"]}`,
        cellData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteCell(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/cell/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //<editor-fold defaultstate="collapsed" desc="orders">
  static async fetchOrders() {
    try {
      let response = await customFetch(`/order`, "GET");
      let orders = [];
      if (response) {
        orders = response.map(
          (val) =>
            new Order(
              val["orderId"],
              val["name"],
              val["orderType"],
              val["orderQuantity"],
              val["remaining"],
              val["status"],
              val["createdDate"],
              val["modifiedDate"],
              val["dsimType"],
              val["authMethod"],
            ),
        );
      }
      store.dispatch(orderActions.setOrders(orders));
      return orders;
    } catch (error) {
      console.error(error);
    }

    return [];
  }

  //</editor-fold>

  //**********************************************************************
  //*********************** PHONES ***************************************
  //**********************************************************************
  static async fetchPhones(operatorId) {
    try {
      store.dispatch(phoneActions.setPhones([]));
      let response = await Http.get(`operator/${operatorId}/phone`);
      if (response.status === 200 && response.data) {
        const phones = response.data.map(
          (val) =>
            new Phone(
              val["uuId"],
              val["imei"],
              val["manufacturerName"],
              val["modelName"],
              val["createdDate"],
            ),
        );
        store.dispatch(phoneActions.setPhones(phones));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchPhoneById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/phone/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createPhone(operatorId, phoneData) {
    try {
      let response = await Http.post(`operator/${operatorId}/phone`, phoneData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updatePhone(operatorId, phoneData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/phone/${phoneData["uuId"]}`,
        phoneData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deletePhone(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/phone/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** CAMERAS ***************************************
  //**********************************************************************
  static async fetchCameras(operatorId) {
    try {
      store.dispatch(cameraActions.setCameras([]));
      let response = await Http.get(`operator/${operatorId}/camera`);
      if (response.status === 200 && response.data) {
        const cameras = response.data.map(
          (val) =>
            new Camera(
              val["uuId"],
              val["label"],
              val["description"],
              val["imei"],
              val["manufacturerName"],
              val["modelName"],
              val["streamURL"],
              val["key"],
              val["channel"],
              val["status"],
              val["createdDate"],
            ),
        );
        store.dispatch(cameraActions.setCameras(cameras));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchCameraById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/camera/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createCameraToAimacServer(baseUrl, cameraData) {
    try {
      const response = await fetch(`${baseUrl}/camera`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(cameraData),
      });
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateCameraToAimacServer(baseUrl, cameraData) {
    try {
      const response = await fetch(`${baseUrl}/camera`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(cameraData),
      });
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteCameraToAimacServer(baseUrl, id) {
    try {
      const data = {
        uuId: id,
      };
      const response = await fetch(`${baseUrl}/camera`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async createCamera(operatorId, cameraData) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/camera`,
        cameraData,
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateCamera(operatorId, cameraData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/camera/${cameraData["uuId"]}`,
        cameraData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteCamera(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/camera/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** PLANS ****************************************
  //**********************************************************************

  static async fetchPlans() {
    let response = await customFetch(`/plan`, "GET");
    let plans = [];
    if (response != null && Array.isArray(response)) {
      plans = response.map(
        (val) =>
          new Plan(
            val["planId"],
            val["planName"],
            val["description"],
            val["dataLimit"],
            val["price"],
            val["quota"],
            val["createdDate"],
          ),
      );
      store.dispatch(planActions.setPlans(plans));
    }
  }

  //**********************************************************************
  //*********************** Payouts ****************************************
  //**********************************************************************
  static async fetchPayouts(operatorId) {
    try {
      store.dispatch(payoutActions.setPayouts([]));
      let response = await Http.get(`operator/${operatorId}/payouts`);
      if (response.status === 200 && response.data) {
        const payouts = response.data.map(
          (val) =>
            new Payout(
              val["payoutsId"],
              val["payerName"],
              val["payeeName"],
              val["payoutType"],
              val["payoutAmountCurrency"],
              val["payoutAmount"],
              val["status"],
              val["createdDate"],
            ),
        );

        store.dispatch(payoutActions.setPayouts(payouts));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async createPayouts(operatorId, payoutData) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/payouts`,
        payoutData,
      );
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updatePayouts(operatorId, payoutData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/payouts/${payoutData["payoutsId"]}`,
        payoutData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deletePayouts(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/payouts/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** INVOICES **************************************
  //**********************************************************************
  static async fetchInvoices(operatorId) {
    try {
      store.dispatch(invoiceActions.setInvoices([]));
      let response = await Http.get(`operator/${operatorId}/invoice`);
      if (response.status === 200 && response.data) {
        const invoices = response.data.map(
          (val) =>
            new Invoice(
              val["invoiceId"],
              val["invoiceAmount"],
              val["status"],
              val["createdDate"],
              val["invoicePaidDate"],
            ),
        );
        store.dispatch(invoiceActions.setInvoices(invoices));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchInvoiceById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/invoice/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createInvoice(operatorId, invoiceData) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/invoice`,
        invoiceData,
      );
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateInvoice(operatorId, invoiceData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/invoice/${invoiceData["invoiceId"]}`,
        invoiceData,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteInvoice(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/invoice/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async payInvoice(operatorId, invoiceId) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/invoice/${invoiceId}`,
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getPaymentDetailsById(id, isSubscriber) {
    try {
      axios.defaults.headers.common["Token"] = "admin";
      let response = await Http.get(
        `${isSubscriber ? "subscriber" : "operator"}/${id}/payment`,
      );

      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getFtRequestStatus(orderId) {
    try {
      let response = await Http.get(`execution-request/status/${orderId}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("getFtRequestStatus error", error);
    }

    return false;
  }

  static async getNftRequestStatus(dsimId, imsi) {
    try {
      let response = await Http.get(
        `mint-nft-request/status/${dsimId}/${imsi}`,
      );
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("getNftRequestStatus error", error);
    }

    return false;
  }

  static async getSubscribers() {
    try {
      let user = LocalStorageHelper.getUserInfo();
      axios.defaults.headers.common["Token"] = user.accessToken;
      let response = await Http.get("subscriber");
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getDeploymentInfo(contractName) {
    try {
      let response = await Http.get(
        `deployment-info?contractName=${contractName}`,
      );
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("getDeploymentInfo error", error);
    }

    return null;
  }

  static async postMintFtRequest(data) {
    try {
      let response = await Http.post(`execution-request`, data);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("postMintFtRequest error", error);
    }

    return null;
  }

  static async getStripePublicKey() {
    try {
      let response = await Http.get(`stripe/publickey`);

      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** BLOCKS  **************************************
  //**********************************************************************
  static async fetchBlocks() {
    try {
      store.dispatch(blockActions.setBlocks([]));
      let response = await Http.get("block");
      if (response.status === 200 && response.data) {
        console.log("response.data", response.data);
        const blocks = response.data.map(
          (val) =>
            new Block(
              val["blockId"],
              val["blockHash"],
              val["createdDate"],
              val["transactionsCount"],
              val["transactions"],
            ),
        );
        store.dispatch(blockActions.setBlocks(blocks));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchBlockById(id) {
    try {
      let response = await Http.get(`block/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createBlock(blockData) {
    try {
      let response = await Http.post("block", blockData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateBlock(blockData) {
    try {
      let response = await Http.put(`block/${blockData["blockId"]}`, blockData);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }
}

export default ApiHelper;

export const BASE_URL = config.API_URL;

/**
 * Reusable function to perform generic requests with flexible methods
 * @param {string} path Relative path to the endpoint (without base URL)
 * @param {string} method HTTP method (GET, POST, PUT, DELETE, etc.)
 * @param {Object} [body] Request body for methods like POST/PUT
 * @param {Function} [onSuccess] Callback function to handle successful response
 * @param {Object} [params] Additional parameters for the request
 */
export async function customFetch(
  endpoint,
  method = "GET",
  body = null,
  onSuccess = null,
  params = null,
) {
  const user = LocalStorageHelper.getUserInfo();

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br, zstd",
    Token: user?.accessToken || "",
  };

  const config = {
    method,
    headers,
    mode: "cors",
  };

  if (body && ["POST", "PUT", "PATCH"].includes(method)) {
    config.body = JSON.stringify(body);
  }

  const finalUrl = `${BASE_URL}${endpoint}`;
  let json;
  let res;
  try {
    res = await fetch(finalUrl, config);
    if (res.status === 200 || res.status === 201) {
      json = await res.json();
      if (onSuccess) {
        onSuccess(params);
      }
      return json;
    } else if (res.status === 401) {
      // if (!window.sessionStorage.getItem("redirecting")) {
      //   window.sessionStorage.setItem("redirecting", "true");
      toast.error("Session expired, redirecting to login");
      await AuthHelper.logout();
      customHistory.push("/");
      // }
    } else {
      try {
        const errorJson = await res.json();
        toast.error(errorJson.Message);
      } catch (e) {
        const error = res.statusText;
        toast.error(error);
      }
      return false;
    }
  } catch (error) {
    toast.error(error);
    console.log("unexpected error in customFetch", error);
    throw error;
  }
}
