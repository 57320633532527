import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import validator from '@rjsf/validator-ajv8';
import {
  planModalSchema,
  planModalUISchema,
  planModalSuperSchema,
  planModalSuperUISchema,
  planModalSuperEditUISchema
} from '../forms'
class PlanModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isSuper: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    plan: PropTypes.object,
    onModify: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
  };

  schema = this.props.isSuper ?  planModalSuperSchema : planModalSchema;
  uiSchema = this.props.isSuper ?  planModalSuperUISchema : planModalUISchema;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.plan });

      if (this.props.plan) {
        const plan = this.props.plan;

        if (this.props.isSuper) {
          this.uiSchema = planModalSuperEditUISchema;
        }

        let formData = {
          planId: plan['planId'],
          planName: plan['planName'],
          operatorName: this.props.operators.filter((operator) => operator.id === plan['operatorId'])[0]?.name || "",
          operatorId: plan['operatorId'],
          status: plan['status'],
          createdDate: plan["createdDate"],
          modifiedDate: plan["modifiedDate"],
        };

        this.updateFormData(formData).then();
      } else {
        let formData = {
          planId: "",
          planName: "",
        };
        this.uiSchema = planModalSuperUISchema;
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;
    const newData = data.formData;

    if (lastData && lastData.planId === undefined)
      lastData.planId = "";

    if(newData && lastData && lastData.operatorName !== newData.operatorName) {
      const selectedOperator = this.props.operators.filter((operator) => operator.name === newData.operatorName);
      if (selectedOperator.length > 0) {
        newData.operatorId = selectedOperator[0].id;
      } else {
        newData.operatorId = "";
      }
      this.updateFormData(newData).then();
    }
  }

  async updateFormData(newData) {
    await this.setState({
      formData: newData,
    });
  }

  onSubmitClick(result) {
    const formData = result.formData;

    const operatorId = this.props.isSuper ? formData["operatorId"] : this.props.user.operatorId;

    let planData = {
      "planId": formData["planId"],
      "operatorId": operatorId,
      "planName": formData["planName"],
      "status": formData["status"],
    };

    if(this.state.editMode) {
      this.props.onModify(planData);
    } else {
      this.props.onSubmit(planData);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        className={"fields__edit-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.state.editMode ? "Edit Plan" : "New Plan"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.rerenderCounter % 2 === 0 &&
            <Form schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state.formData}
              onChange={this.onChange.bind(this)}
              onSubmit={this.onSubmitClick.bind(this)}
            validator={validator}/>
          }
        </Modal.Body>
      </Modal>
    );

  }
}

const mapStateToProps = state => ({
  operators: state.operator.operators,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(PlanModal));

